import React from "react";
import styled from "styled-components";
import {colors} from "../../../util/Colors";
import {Row} from "../../styles/Grid";
import {spacing} from "../../util/Spacing";
import {fontSizes} from "../../util/Fontsizes";

const ListItem = styled(Row)`
    padding: ${spacing.base}px;
    border-top: 1px solid ${colors.borderLightGrey};
    align-items: center;
`;

const Username = styled.span`
    margin-left: ${spacing.base}px;
`;

const MobileModalEnrollments = ({ users = [], enrollments = [], onChangeClick = (userId, change) => {} }) => {
    return (
        <>
            {users.map((user, i) => (
                <ListItem key={user.id} style={i === 0 ? { borderTop: 'none' } : undefined}>
                    <AmountCounter
                        amount={enrollments.find((x) => x.user.id === user.id)?.amount}
                        onClickMinus={() => onChangeClick(user.id, -1)}
                        onClickPlus={() => onChangeClick(user.id, 1)}
                    />
                    <Username>{user.username}</Username>
                </ListItem>
            ))}
        </>
    );
};

export default MobileModalEnrollments;

const AmountButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: ${props => props.isDisabled ? colors.disabledButton : colors.orange};
    border-radius: 2px;
    width: 28px;
    height: 28px;
    
    font-size: ${fontSizes.semiBig}px;
    color: ${colors.white};
    font-weight: bold;
    
    cursor: pointer;
`;

const AmountText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 20px;
    height: 28px;
`;

export const AmountCounter = ({ amount, onClickMinus = () => {}, onClickPlus = () => {}, isDisabled = false }) => {

    /**
     * Display 0 times enrolled as a cross (or 'not enrolled') and no information as 0 times enrolled (or 'undecided')
     */
    const formatAmount = (amount) => {
        if (amount === undefined) {
            return 0;
        }
        if (amount === 0) {
            return 'x';
        }
        return amount;
    }

    return (
        <>
            <AmountButton
                isDisabled={isDisabled}
                onClick={() => !isDisabled && onClickMinus()}
            >
                -
            </AmountButton>
            <AmountText>{formatAmount(amount)}</AmountText>
            <AmountButton
                isDisabled={isDisabled}
                onClick={() => !isDisabled && onClickPlus()}
            >
                +
            </AmountButton>
        </>
    );
};