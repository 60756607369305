import React, {useContext} from "react";
import SortableTable from "../common/SortableTable";
import {Td, Tr} from "../../styles/Page";
import {StatsContext} from "../../../shared/context/StatsContext";
import CollapsablePart from "./CollapsablePart";
import {sortByIdAsc} from "../../../shared/util/SortUtils";

const DryerCount = () => {
    const { dryerCount, getDryerCount } = useContext(StatsContext);

    const buildHeaders = () => {
        return [
            {name: 'Persoon', sortable: false},
            {name: 'Gedroogd', sortable: true, sortKey: 'amount'},
            {name: 'Meegegeten', sortable: true, sortKey: 'eatAmount'},
            {name: '%', sortable: true, sortKey: 'fraction'},
        ];
    };

    const convertRow = (row) => {
        return (
            <Tr key={row.user.id}>
                <Td>{row.user.username}</Td>
                <Td>{row.amount}</Td>
                <Td>{row.eatAmount}</Td>
                <Td>{(row.fraction * 100).toFixed(2)}%</Td>
            </Tr>
        );
    };

    return (
      <CollapsablePart
        buttonText={"Laat droogbeurten zien"}
        getData={getDryerCount}
      >
          <SortableTable
            headers={buildHeaders()}
            content={dryerCount.sort((a,b) => sortByIdAsc(a.user, b.user))}
            convertRow={convertRow}
            editable={false}
          />
      </CollapsablePart>
    );
};

export default DryerCount;
