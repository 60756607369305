import React, {useContext, useEffect, useMemo, useState} from "react";
import MobilePageWrapper from "../MobilePageWrapper";
import ActionBar from "../ActionBar";
import {routes} from "../../../shared/routing/Routes";
import styled from "styled-components";
import {FCRow} from "../../styles/Grid";
import Select from "../../styles/Select";
import {spacing} from "../../util/Spacing";
import List from "../../styles/List";
import BeerListItem from "./BeerListItem";
import {MeContext} from "../../../shared/context/MeContext";
import {BeerTypeContext} from "../../../shared/context/BeerTypeContext";
import {BeerInputContext} from "../../../shared/context/BeerInputContext";
import {isAdmin} from "../../../util/Helpers";
import ViewBeerInputModal from "../../../desktop/components/beerlist/modal/ViewBeerInputModal";
import UploadFileModal from "../../../desktop/components/common/UploadFileModal";
import {UsersContext} from "../../../shared/context/UsersContext";
import FabStack from "../../styles/FabStack";
import MobileModalBalance from "../modal/MobileModalBalance";
import EditBeerInputModal from "../../../desktop/components/beerlist/modal/EditBeerInputModal";

const Header = styled(FCRow)`
    padding: ${spacing.base}px;
`;

const initialBeerInput = {
    date: null,
    type: null,
    beerType: null,
    note: null,
    enrollments: []
}

const MobileBeerListPage = () => {

    // Need to initialise with empty string, otherwise material select bugs
    const [selectedBeerTypeId, setSelectedBeerTypeId] = useState('');

    const [ selectedBeerInput, setSelectedBeerInput ] = useState(null);

    const [ showCreateModal, setShowCreateModal ] = useState(false);
    const [ showViewModal, setShowViewModal ] = useState(false);
    const [ showUploadModal, setShowUploadModal ] = useState(false);
    const [ showEditModal, setShowEditModal ] = useState(false);
    const [ showBalanceModal, setShowBalanceModal ] = useState(false);

    const { me, loadMe } = useContext(MeContext);
    const { users, loadUsers} = useContext(UsersContext);
    const { beerTypes, getAllBeerTypes } = useContext(BeerTypeContext);
    const { beerInputPageable, beerBalanceOverview, getBeerInputs, createBeerInput, updateBeerInput, uploadBeerInputImage, getBeerInputBalance } = useContext(BeerInputContext);

    const currentBeerType = beerTypes.find(x => x.id === selectedBeerTypeId);

    useEffect(() => {
        loadMe();
        loadUsers();
        getAllBeerTypes();
    }, []);

    // Set beerType when they are loaded
    useEffect(() => {
        if (beerTypes?.length > 0) {
            setSelectedBeerTypeId(beerTypes[0].id);
        }
    }, [beerTypes?.length]);

    // Reload beer input data when beer type changes
    useEffect(() => {
        if (selectedBeerTypeId) {
            getBeerInputs(0, selectedBeerTypeId);
            getBeerInputBalance(true, selectedBeerTypeId);
        }
    }, [selectedBeerTypeId]);

    const handleItemClick = (item) => {
        setSelectedBeerInput(item);
        setShowViewModal(true);
    };

    const handleCreateClick = () => {
        setShowCreateModal(true);
    };

    const handleOverviewClick = () => {
        setShowBalanceModal(true);
    };

    const getNewPage = () => {
        if (!beerInputPageable.last && selectedBeerTypeId) {
            getBeerInputs(beerInputPageable.number + 1, selectedBeerTypeId);
        }
    };

    const uploadImage = (formData, closeModal) => {
        uploadBeerInputImage(selectedBeerInput.id, formData, closeModal);
    };

    const fabItems = useMemo(() => isAdmin(me)
        ?  [
            {
                name: 'Add',
                text: '+',
                onClick: handleCreateClick
            },
            {
                name: 'Overview',
                text: '☰',
                onClick: handleOverviewClick
            }
        ] : [
            {
                name: 'Overview',
                text: '☰',
                onClick: handleOverviewClick
            },
        ]
    , [me])

    const update = (formData, onSuccess) => {
        updateBeerInput(selectedBeerInput.id, formData, onSuccess);
    }

    return (
        <MobilePageWrapper>
            <ActionBar activeRoute={routes.beerList}>
                <Header>
                    <Select
                        title={'BeerType'}
                        data={beerTypes}
                        value={selectedBeerTypeId}
                        onChange={(e) => setSelectedBeerTypeId(e.target.value)}
                        includeNull={false}
                        labelExtractor={(x) => x.name}
                    />
                </Header>
                <List
                    data={beerInputPageable?.content}
                    renderItem={(item) => (
                        <div key={item.id} onClick={() => handleItemClick(item)} style={{ cursor: 'pointer' }}>
                            <BeerListItem
                                date={item.date}
                                type={item.type}
                                note={item.note}
                                createdBy={item.createdBy.username}
                                amountForMe={item.enrollments.find(x => x.user.id === me.id)?.amount}
                            />
                        </div>
                    )}
                    onEndReached={getNewPage}
                />
                <FabStack items={fabItems} />
                {isAdmin(me) && (
                    <>
                        <EditBeerInputModal
                            isOpen={showCreateModal}
                            setIsOpen={setShowCreateModal}
                            modify={createBeerInput}
                            beerInput={initialBeerInput}
                            beerTypes={beerTypes}
                            currentBeerTypeId={currentBeerType?.id}
                            users={users}
                            setSelectedBeerTypeId={setSelectedBeerTypeId}
                            setSelectedBeerInput={setSelectedBeerInput}
                            setShowUploadModal={setShowUploadModal}
                        />
                        <EditBeerInputModal
                            isOpen={showEditModal}
                            setIsOpen={setShowEditModal}
                            modify={update}
                            beerInput={selectedBeerInput || initialBeerInput}
                            beerTypes={beerTypes}
                            currentBeerTypeId={currentBeerType?.id}
                            users={users}
                            setSelectedBeerTypeId={setSelectedBeerTypeId}
                            setSelectedBeerInput={setSelectedBeerInput}
                            setShowUploadModal={setShowUploadModal}
                        />
                        <UploadFileModal
                            title={"Foto uploaden"}
                            isOpen={showUploadModal}
                            setIsOpen={setShowUploadModal}
                            upload={uploadImage}
                            onClose={setSelectedBeerInput}
                            accept={"image*"}
                        />
                    </>
                )}
                <ViewBeerInputModal
                    isOpen={showViewModal}
                    setIsOpen={setShowViewModal}
                    users={users}
                    setIsEdit={setShowEditModal}
                    beerInput={selectedBeerInput}
                    canUpload={me.id === selectedBeerInput?.createdBy?.id}
                    setShowUploadModal={setShowUploadModal}
                    setSelectedBeerInput={setSelectedBeerInput}
                />
                <MobileModalBalance
                    isOpen={showBalanceModal}
                    close={() => setShowBalanceModal(false)}
                    title={currentBeerType ? `${currentBeerType?.name} balance overview` : 'Balance overview'}
                    balanceOverview={beerBalanceOverview}
                />
            </ActionBar>
        </MobilePageWrapper>
    );
};

export default MobileBeerListPage;