import Axios from "axios";
import {constants} from "../../util/Constants";
import {defaultAxiosErrorHandler} from "../util/RequestUtils";

export const getMe = () => Axios({
    method: 'get',
    url: `${constants.apiBaseUrl}/users/me`,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const getActiveUsers = () => Axios({
    method: 'get',
    url: `${constants.apiBaseUrl}/users/active`,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const getAllUsersRequest = () => Axios({
    method: 'get',
    url: `${constants.apiBaseUrl}/users/admin`,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const postUserRequest = async (data) => Axios({
    method: 'post',
    url: `${constants.apiBaseUrl}/users/admin`,
    data,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const putMeRequest = async (data) => Axios({
    method: 'put',
    url: `${constants.apiBaseUrl}/users/me`,
    data,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const putUserRequest = async (id, data) => Axios({
    method: 'put',
    url: `${constants.apiBaseUrl}/users/admin/${id}`,
    data,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const putMeImageRequest = async (image) => {
    const formData = new FormData();

    formData.append('file', image);

    const client = Axios.create({
        baseURL: constants.apiBaseUrl,
    });
    const headers = {
        'Content-Type': 'multipart/form-data',
    };
    return client.put('/users/me/image', formData, headers)
        .then((response) => response.data)
        .catch(defaultAxiosErrorHandler);
};