import styled from "styled-components";

const Amount = styled.p`
    font: 100% Verdana;
    color: white;
    white-space: no-wrap;
    font-size: 2.80em;
    text-align: center;
    padding: 0px;
    margin: 0px;
    margin-top: 4px;
`;

export default Amount;
