import React from "react";
import styled from "styled-components";
import {ListItem, StyledListDate} from "../../../styles/List";
import dayjs from "dayjs";
import {Col} from "../../../styles/Grid";
import {colors} from "../../../../util/Colors";
import {fontSizes} from "../../../util/Fontsizes";
import {spacing} from "../../../util/Spacing";
import {useHistory} from "react-router";
import {routes} from "../../../../shared/routing/Routes";

const ListDateText = styled.span`
    color: ${colors.white};
    font-size: ${fontSizes.semiBig}px;
    font-weight: bold;
`;

const ItemInfoWrapper = styled(Col)`
    justify-content: center;
    flex-grow: 1;
`;

const ItemTitle = styled.div`
    color: ${colors.black};
    font-size: ${fontSizes.base}px;
    font-weight: bold;
`;

const ItemSubtitle = styled.div`
    color: ${colors.black};
    font-size: ${fontSizes.base}px;
    margin-bottom: ${spacing.small}px;
`;

const ItemRecipient = styled.div`
    font-size: ${fontSizes.base}px;
    color: ${(props) => props.isGrey ? colors.textLightGrey : colors.orange};
`;

const Indicator = styled.div`
    width: 5px;
    background-color: ${colors.orange};
`;

const getItemRecipientText = (amountMeEnrolled, autoEnrollAmount, date) => {
    if (amountMeEnrolled === 0) {
        return 'You are un-enrolled';
    }
    if (amountMeEnrolled > 0) {
        return `You are enrolled ${amountMeEnrolled} times`;
    }

    // Do not show an auto enroll preview message if the auto enroll deadline has passed on this day
    // or the date is in the past
    const isPastDate = dayjs(date + ' 12:00').isBefore(dayjs());

    if (autoEnrollAmount != null && !isPastDate) {
        if (autoEnrollAmount === 0) {
            return 'You will be auto un-enrolled';
        }
        if (autoEnrollAmount === 1) {
            return 'You will be auto enrolled';
        }
    }
    return '\u00A0';
}

const CookingListItem = ({ date = '', cook = '', amountEnrolled = 0, amountMeEnrolled, isToday = false, autoEnrollAmount = null }) => {

    const history = useHistory();

    return (
        <ListItem style={{ cursor: 'pointer' }} onClick={() => history.push(`${routes.cookingCalenderDetail}/${date}`)}>
            <StyledListDate>
                <ListDateText>{dayjs(date).format('ddd')}</ListDateText>
            </StyledListDate>
            <ItemInfoWrapper>
                <Col>
                    <ItemTitle>{cook || '-'}</ItemTitle>
                    <ItemSubtitle>Is cooking for {amountEnrolled} people</ItemSubtitle>
                </Col>
                <ItemRecipient isGrey={amountMeEnrolled === undefined}>{getItemRecipientText(amountMeEnrolled, autoEnrollAmount, date)}</ItemRecipient>
            </ItemInfoWrapper>
            {isToday && (
                <Indicator />
            )}
        </ListItem>
    );
};

export default React.memo(CookingListItem);
