import React, {createContext, useState} from 'react';
import {
    createBeerInputRequest,
    getBeerBalanceOverviewRequest,
    getBeerInputsRequest, updateBeerInputRequest, uploadBeerInputImageRequest
} from "../network/BeerInputRequests";
import {genericSortDesc, sortByBalanceDescAndUserIdAsc, sortByDateAndIdDesc} from "../util/SortUtils";
import {mergeArraysById} from "../util/ArrayUtils";

export const BeerInputContext = createContext({});

const initialPage = {
    content: [],
    empty: true,
    first: true,
    last: true,
    number: 0,
    numberOfElements: 0,
    pageable: {
        offset: 0,
        pageSize: 20,
        paged: true,
        sort: {
            empty: false,
            sorted: true,
            unsorted: false,
        },
        unpaged: false,
    },
    size: 0,
    sort: {
        empty: false,
        sorted: true,
        unsorted: false,
    },
    totalElements: 0,
    totalPages: 1,
};

const BeerInputContextProvider = (props) => {

    const [beerBalanceOverview, setBeerBalanceOverview] = useState([]);
    const [beerInputPageable, setBeerInputPageable] = useState(initialPage);

    const getBeerInputBalance = async (activeOnly = true, beerType = -1) => {
        const result = await getBeerBalanceOverviewRequest(activeOnly, beerType);
        if (result) {
            result.sort(sortByBalanceDescAndUserIdAsc);
            setBeerBalanceOverview(result);
        }
    }

    const getBeerInputs = async (page = 0, beerType = -1) => {
        const result = await getBeerInputsRequest(page, beerType);
        if (result) {
            // Check if we are requesting the same beerType that is in the state
            if (beerInputPageable.content[0]?.beerType.id === beerType) {
                setBeerInputPageable({
                    ...result,
                    content: mergeArraysById(beerInputPageable.content, result.content).sort(sortByDateAndIdDesc),
                });
            } else {
                // Replace entire state
                setBeerInputPageable(result);
            }
        }
    }

    const createBeerInput = async (data, onSuccess = () => {}) => {
        const result = await createBeerInputRequest(data);
        if (result) {
            setBeerInputPageable({ ...beerInputPageable, content: [result, ...beerInputPageable.content].sort((a,b) => genericSortDesc(a,b,'id')) });
            // Update the balance when we create the same beerType as in the state
            if (beerInputPageable.content[0]?.beerType.id === result.beerType.id) {
                await getBeerInputBalance(true, result.beerType.id);
            }
            onSuccess(result);
        }
    }

    const updateBeerInput = async (id, data, onSuccess = () => {}) => {
        const result = await updateBeerInputRequest(id, data);
        if (result) {
            setBeerInputPageable({
                ...beerInputPageable,
                content: beerInputPageable.content.map(x => x.id === id ? result : x).sort((a,b) => genericSortDesc(a,b,'id'))
            });
            await getBeerInputBalance(true, result.beerType.id);
            onSuccess(result);
        }
    }

    const uploadBeerInputImage = async (id = -1, formData, onSuccess = () => {}) => {
        const result = await uploadBeerInputImageRequest(id, formData);
        if (result) {
            setBeerInputPageable({
                ...beerInputPageable,
                content: mergeArraysById(beerInputPageable.content, [result]).sort((a,b) => genericSortDesc(a,b,'id')),
            });
            onSuccess();
        }
    }
    
    return (
        <BeerInputContext.Provider value={{
            beerBalanceOverview,
            beerInputPageable,
            getBeerInputBalance,
            getBeerInputs,
            createBeerInput,
            updateBeerInput,
            uploadBeerInputImage
        }}
        >
            {props.children}
        </BeerInputContext.Provider>
    );
}

export default BeerInputContextProvider;