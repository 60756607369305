import {useEffect, useState} from "react";
import {getCookingEventActionsRequest} from "../network/CookingRequests";

function useCookingActions(cookingEventId = -1) {
    const [actions, setActions] = useState([]);

    useEffect(() => {
        if (cookingEventId > 0) {
            getCookingEventActionsRequest(cookingEventId).then(result => setActions(result || []));
        }
    }, [cookingEventId]);

    return { actions };
}

export default useCookingActions;