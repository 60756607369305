import React, {useEffect, useState} from "react";
import CustomModal from "../CustomModal";
import {func} from "../../../util/Helpers";
import {Body, CloseHolder, CustomRow, Footer, Half, Title, TitleHolder} from "../../styles/FileModal";


const UploadFileModal = ({ title = "Bestand uploaden", isOpen = false, setIsOpen = func, upload = func, preview = true, accept = "" }) => {
    const [ file, setFile ] = useState(null);
    const [ previewFile, setPreviewFile ] = useState(null);

    // Reset after close
    useEffect(() => {
        if (!isOpen) {
            setFile(null);
            setPreviewFile(null);
        }
    }, [isOpen]);

    const closeModal = () => {
        setIsOpen(false);
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
        setPreviewFile(URL.createObjectURL(event.target.files[0]));
    };

    const handleSubmit = () => {
        const formData = new FormData();
        formData.append('file', file);
        upload(formData, closeModal);
    };

    return (
        <CustomModal isOpen={isOpen} setIsOpen={setIsOpen}>
            <TitleHolder>
                <Title><b>{title}</b></Title>
                <CloseHolder onClick={() => setIsOpen(false)}>X</CloseHolder>
            </TitleHolder>
            <Body>
                <CustomRow>
                    <Half>Upload</Half>
                    <Half><input type={'file'} onChange={handleFileChange} accept={accept} /></Half>
                </CustomRow>
                { preview && <>
                    <CustomRow>
                        <Half>Preview</Half>
                    </CustomRow>
                    <img src={previewFile} alt={''} /> </> }
            </Body>
            <Footer>
                <button type={'button'} onClick={handleSubmit}>Save</button>
            </Footer>
        </CustomModal>
    );
};

export default UploadFileModal;
