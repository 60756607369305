import {PanelBody, PanelBottom, PanelContainer, PanelTop} from "../../styles/Panel";
import {Title} from "../../styles/Layout";
import React, {useContext} from "react";
import BeerTypeTable from "./BeerTypeTable";
import {BeerTypeContext} from "../../../shared/context/BeerTypeContext";

const BeerTypePanel = () => {

    const { beerTypes, createBeerType, updateBeerType } = useContext(BeerTypeContext);

    return (
        <PanelContainer>
            <PanelTop>
                <Title>Biertypes</Title>
            </PanelTop>
            <PanelBody>
                <BeerTypeTable beerTypes={beerTypes} createBeerType={createBeerType} updateBeerType={updateBeerType} />
            </PanelBody>
            <PanelBottom/>
        </PanelContainer>
    );
};

export default BeerTypePanel;
