import Axios from "axios";
import {constants} from "../../util/Constants";
import {defaultAxiosErrorHandler} from "../util/RequestUtils";

export const getCookingDonationsRequest = (page) => {
    let url = `${constants.apiBaseUrl}/cooking-donations/search?sort=id,desc`;
    if (page) {
        url += `&page=${page}`;
    }

    return Axios({
        method: 'get',
        url,
    }).then((response) => response.data).catch(defaultAxiosErrorHandler);
};

export const createCookingDonationRequest = (data) => Axios({
    method: 'post',
    url: `${constants.apiBaseUrl}/cooking-donations`,
    data,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);