import React from "react";
import PageWrapper from "../PageWrapper";
import LoginPanel from "./LoginPanel";

const LoginPage = () => {
    return (
        <PageWrapper leftPanel={<LoginPanel />} />
    );
};

export default LoginPage;