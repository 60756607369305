import React, {useState} from "react";
import styled from "styled-components";
import MobilePageWrapper from "../MobilePageWrapper";
import ActionBar from "../ActionBar";
import {routes} from "../../../shared/routing/Routes";
import {useContext, useEffect} from "react";
import {MeContext} from "../../../shared/context/MeContext";
import ProfileImage from "./ProfileImage";
import {BottomNavigation, BottomNavigationAction, Paper} from "@material-ui/core";
import ProfileDetailsForm from "./ProfileDetailsForm";
import ProfileAutoEnrollForm from "./ProfileAutoEnrollForm";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const Form = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
`;

const FormContent = styled.div`
    width: 80%;
`;

const MobileProfilePage = () => {

    const [ currentTab, setCurrentTab ] = useState(0);

    const { me, loadMe } = useContext(MeContext);

    useEffect(() => {
        loadMe();
    }, []);

    return (
        <MobilePageWrapper>
            <ActionBar activeRoute={routes.profile}>
                <Wrapper className={'test'}>
                    <ProfileImage username={me.username} imageId={me.image} />
                    <Form className={'form'}>
                        <FormContent>
                            {currentTab === 0 && (
                                <ProfileDetailsForm username={me.username} />
                            )}
                            {currentTab === 1 && (
                                <ProfileAutoEnrollForm username={me.username} autoEnroll={me.autoEnroll} />
                            )}
                        </FormContent>
                    </Form>
                    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={5}>
                        <BottomNavigation
                            showLabels
                            value={currentTab}
                            onChange={(event, newValue) => setCurrentTab(newValue)}
                        >
                            <BottomNavigationAction label="Details" />
                            <BottomNavigationAction label="Auto enroll" />
                        </BottomNavigation>
                    </Paper>
                </Wrapper>
            </ActionBar>
        </MobilePageWrapper>
    );
};

export default MobileProfilePage;