import React, {createContext, useContext, useState} from 'react';
import {getCookingBalanceOverviewRequest, getMyCookingBalanceRequest} from "../network/CookingRequests";
import {MeContext} from "./MeContext";
import {sortByBalanceDescAndUserIdAsc} from "../util/SortUtils";

export const CookingBalanceContext = createContext({});

const CookingBalanceContextProvider = (props) => {
    const { me } = useContext(MeContext);
    const [cookingBalanceOverview, setCookingBalanceOverview] = useState([]);

    const getCookingBalanceOverview = async (activeOnly = true) => {
        const data = await getCookingBalanceOverviewRequest(activeOnly);
        if (data) {
            data.sort(sortByBalanceDescAndUserIdAsc);
            setCookingBalanceOverview(data);
        }
    };

    const getMyCookingBalance = async () => {
        const data = await getMyCookingBalanceRequest();
        if (typeof data === 'number') {
            let newValue = []
            // Check if user is already in the list
            if (cookingBalanceOverview.find(x => x.user.id === me.id)) {
                // Replace its value
                newValue = cookingBalanceOverview.map((x) => (x.user.id !== me.id ? x : { ...x, balance: data }))
            } else {
                // Add value
                newValue.push(...cookingBalanceOverview);
                newValue.push({ user: { id: me.id, username: me.username, image: me.image }, balance: data })
            }
            newValue.sort(sortByBalanceDescAndUserIdAsc);
            setCookingBalanceOverview(newValue);
        }
    };

    return (
        <CookingBalanceContext.Provider value={{
            cookingBalanceOverview,
            getCookingBalanceOverview,
            getMyCookingBalance,
        }}
        >
            {props.children}
        </CookingBalanceContext.Provider>
    );
}

export default CookingBalanceContextProvider;