import React, { createContext, useState } from 'react';
import {getActiveUsers, getAllUsersRequest, postUserRequest, putUserRequest} from "../network/UserRequests";
import {sortByIdAsc} from "../util/SortUtils";
import {mergeArraysById} from "../util/ArrayUtils";

export const UsersContext = createContext({});

const UsersContextProvider = (props) => {
    const [users, setUsers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);

    const loadUsers = async (force = false) => {
        if (users.length === 0 || force) {
            const data = await getActiveUsers();
            if (data) {
                setUsers(data.sort(sortByIdAsc));
            }
        }
    };

    const loadAllUsers = async (onSuccess = () => {}) => {
        await loadUsers();
        const result = await getAllUsersRequest();
        if (result) {
            setAllUsers(result.sort(sortByIdAsc));
            onSuccess();
        }
    }

    const createUser = async (data, onSuccess = () => {}) => {
        const result = await postUserRequest(data);
        if (result) {
            setUsers([result, ...users].sort(sortByIdAsc));
            setAllUsers([result, ...allUsers].sort(sortByIdAsc));
            onSuccess();
        }
    };

    const updateUser = async (id, data, onSuccess = () => {}) => {
        const result = await putUserRequest(id, data);
        if (result) {
            await loadUsers(true);
            setAllUsers(mergeArraysById(allUsers, [result]).sort(sortByIdAsc));
            onSuccess();
        }
    };

    return (
        <UsersContext.Provider value={{
            users,
            allUsers,
            loadUsers,
            loadAllUsers,
            createUser,
            updateUser
        }}
        >
            {props.children}
        </UsersContext.Provider>
    );
};

export default UsersContextProvider;