import {colors} from "../../util/Colors";
import React from "react";
import { Button as MaterialButton } from "@material-ui/core";

const Button = ({ text = '', color = 'primary', textColor = colors.white, style = {}, ...props }) => {
    return (
        <MaterialButton variant="contained" color={color} style={{ color: textColor, ...style }} {...props}>
            <b>{text}</b>
        </MaterialButton>
    )
};

export default Button;