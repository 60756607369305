import React from "react";
import styled from "styled-components";
import useLoginForm from "../../../shared/hooks/LoginFormHook";
import {colors} from "../../../util/Colors";
import MobilePageWrapper from "../MobilePageWrapper";
import {Button, createTheme, ThemeProvider} from "@material-ui/core";
import {spacing} from "../../util/Spacing";
import Logo from "../../images/dirty_a_logo_transparant.svg";
import {fontSizes} from "../../util/Fontsizes";

const ContainerHolder = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    background-color: ${colors.orange};
    
    height: 100%;
    width: 100%;
`;

const Container = styled.div`
    width: 75%;
`;

const Input = styled.input`
    border: none;
    border-bottom: 1px solid white;
    width: 100%;
    background-color: ${colors.orange};
    color: ${colors.white};
    padding-bottom: ${spacing.small}px;
    font-size: ${fontSizes.input}px;
    margin-bottom: ${spacing.base}px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    
    margin-top: ${spacing.small}px;
`;

// Override the main theme
const theme = createTheme({
    palette: {
        secondary: {
            main: colors.darkOrange,
        },
    },
});

const MobileLoginPage = () => {

    const { setUsername, setPassword, handleSubmit } = useLoginForm();

    const submit = (event) => {
        event.preventDefault();
        handleSubmit();
    }

    return (
        <ThemeProvider theme={theme}>
        <MobilePageWrapper>
            <ContainerHolder>
                <Container>
                    <img src={Logo} alt={''} style={{ width: '100%', marginBottom: spacing.base }} />
                    <form onSubmit={submit}>
                        <Input required="required" placeholder="Username" onChange={e => setUsername(e.target.value)} />
                        <Input required="required" placeholder="Password" type="password"  onChange={e => setPassword(e.target.value)} />
                        <ButtonWrapper>
                            <Button type="submit" variant="contained" color="secondary">
                                <b>Login</b>
                            </Button>
                        </ButtonWrapper>
                    </form>
                </Container>
            </ContainerHolder>
        </MobilePageWrapper>
        </ThemeProvider>
    );
};

export default MobileLoginPage;