import React, {useContext, useEffect, useState} from "react";
import styled from 'styled-components';
import {Row} from "../../styles/Layout";
import {SettingsContext} from "../../../shared/context/SettingsContext";
import {MeContext} from "../../../shared/context/MeContext";
import {capitalizeFirstChar} from "../../../util/Helpers";
import {areEqual} from "../../../mobile/util/Utils";

const Form = styled.form`
    // width: 490px;
    padding: 10px;
    font-size: 16px;
    font-family: Verdana;
`;

const CustomRow = styled(Row)`
    padding-top: 3px;
    padding-bottom: 3px;
`

const Label = styled.div`
    width: 45%;
`;

const Value = styled.div`
    width: 55%;
`;

const Spacer = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #C0C0C0;
`;

function translateDays(input = '') {
    switch (input) {
        case 'monday': return 'maandag';
        case 'tuesday': return 'dinsdag';
        case 'wednesday': return 'woensdag';
        case 'thursday': return 'donderdag';
        case 'friday': return 'vrijdag';
        case 'saturday': return 'zaterdag';
        case 'sunday': return 'zondag';
        default: return '-';
    }
}

const ProfileForm = ({ username, autoEnroll }) => {

    const { settings, setSettings } = useContext(SettingsContext);
    const { updateMe } = useContext(MeContext);
    const [saveDisabled, setSaveDisabled] = useState(true);

    const initialValues = {
        username: username,
        oldPassword: "",
        password: "",
        passwordRepeat: "",
        autoEnroll: autoEnroll,
    }
    const [formValues, setFormValues] = useState(initialValues);

    useEffect(() => {
        setSaveDisabled(areEqual(formValues, initialValues));
    }, [initialValues, formValues, setSaveDisabled])

    const setUsername = (input) => {
        setFormValues({ ...formValues, username: input });
    };

    const setOldPassword = (input) => {
        setFormValues({ ...formValues, oldPassword: input });
    };

    const setPassword = (input) => {
        setFormValues({ ...formValues, password: input });
    };

    const setPasswordRepeat = (input) => {
        setFormValues({ ...formValues, passwordRepeat: input });
    };

    useEffect(() => {
        if (username === formValues.username && areEqual(autoEnroll, formValues.autoEnroll))
            return

        setFormValues({
            ...formValues,
            username: username,
            autoEnroll: autoEnroll,
        });
    }, [username, autoEnroll]);

    const handleSubmit = (onSuccess) => {
        if (formValues.oldPassword || formValues.password || formValues.passwordRepeat) {
            if (!formValues.oldPassword) {
                alert('Vul je oude wachtwoord in');
                return;
            }
            if (!formValues.password) {
                alert('Vul je nieuwe wachtwoord in');
                return;
            }
            if (!formValues.passwordRepeat) {
                alert('Herhaal je nieuwe wachtwoord');
                return;
            }
        }

        updateMe({
            ...formValues,
            oldPassword: formValues.oldPassword || null,
            password: formValues.password || null,
            passwordRepeat: formValues.passwordRepeat || null,
        }, onSuccess);
    };

    const onSuccess = () => {
        setFormValues({
            ...formValues,
            oldPassword: "",
            password: "",
            passwordRepeat: "",
        })
        setSaveDisabled(true);
    }

    const submit = (event) => {
        event.preventDefault();
        handleSubmit(onSuccess);
    }

    return (
        <Form onSubmit={submit}>
            <CustomRow>
                <Label>Gebruikersnaam:</Label>
                <Value>
                    <input
                        type={'text'}
                        value={formValues.username || ''}
                        onChange={event => setUsername(event.target.value)}
                    />
                </Value>
            </CustomRow>
            <Spacer />
            <CustomRow>
                <Label>Wachtwoord:</Label>
                <Value>
                    <input
                        type={'password'}
                        value={formValues.oldPassword || ''}
                        onChange={event => setOldPassword(event.target.value)}
                    />
                </Value>
            </CustomRow>
            <CustomRow>
                <Label>Nieuw wachtwoord:</Label>
                <Value>
                    <input
                        type={'password'}
                        value={formValues.password || ''}
                        onChange={event => setPassword(event.target.value)}
                    />
                </Value>
            </CustomRow>
            <CustomRow>
                <Label>Bevestig wachtwoord:</Label>
                <Value>
                    <input
                        type={'password'}
                        value={formValues.passwordRepeat}
                        onChange={event => setPasswordRepeat(event.target.value)}
                    />
                </Value>
            </CustomRow>
            <Spacer />
            <CustomRow>
                <table style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th style={{ fontWeight: 'initial', textAlign: 'left' }}>Automatisch inschrijven</th>
                            <th style={{ fontWeight: 'initial', textAlign: 'left', width: '15%' }}>Uit</th>
                            <th style={{ fontWeight: 'initial', textAlign: 'left', width: '15%' }}>Niet</th>
                            <th style={{ fontWeight: 'initial', textAlign: 'left', width: '15%' }}>In</th>
                        </tr>
                    </thead>
                    <tbody>
                    {Object.keys(formValues.autoEnroll).map(day => (
                        <tr key={day}>
                            <td>{capitalizeFirstChar(translateDays(day))}</td>
                            <td>
                                <input
                                    type={'checkbox'}
                                    checked={formValues.autoEnroll[day] === false}
                                    onChange={() => setFormValues({ ...formValues, autoEnroll: { ...formValues.autoEnroll, [day]: false } })}
                                />
                            </td>
                            <td>
                                <input
                                    type={'checkbox'}
                                    checked={formValues.autoEnroll[day] === null}
                                    onChange={() => setFormValues({ ...formValues, autoEnroll: { ...formValues.autoEnroll, [day]: null } })}
                                />
                            </td>
                            <td>
                                <input
                                    type={'checkbox'}
                                    checked={formValues.autoEnroll[day] === true}
                                    onChange={() => setFormValues({ ...formValues, autoEnroll: { ...formValues.autoEnroll, [day]: true } })}
                                />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </CustomRow>
            <Spacer />
            <CustomRow>
                <Label>Mobiele website:</Label>
                <Value>
                    <input
                        type={'checkbox'}
                        checked={settings.showMobileView}
                        onChange={() => setSettings({ ...settings, showMobileView: !settings.showMobileView })}
                    />
                </Value>
            </CustomRow>
            <Spacer />
            <CustomRow>
                <button type={"submit"} disabled={saveDisabled}>Opslaan</button>
            </CustomRow>
        </Form>
    );
};

export default ProfileForm;