import styled from "styled-components";
import {colors} from "../../../../util/Colors";
import React from "react";
import CustomModal from "../../CustomModal";
import {Row} from "../../../styles/Layout";

const TitleHolder = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    padding-bottom: 20px;
    padding-top: 20px;

    border-style: solid;
    border-width: 0px 0px 1px 0px;
    border-color: ${colors.borderLightGrey};

    height: 26px;
`;

const Title = styled.div`
    font-size: 18px;
`;

const CloseHolder = styled.div`
    position: absolute;
    right: 0;
    cursor: pointer;
    margin-right: 20px;
    font-size: 22px;
`;

const Body = styled.div`
    display: flex;
    padding: 20px;
    flex-direction: column;
`;

const Footer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex: 1;

    padding: 20px;

    border-style: solid;
    border-width: 1px 0px 0px 0px;
    border-color: ${colors.borderLightGrey};
`;

const CustomRow = styled(Row)`
    margin-bottom: 5px;
`;

const Half = styled.div`
    width: 50%;
`;

const Spacer = styled.div`
    height: 20px;
`;


const BeerInputModal = ({
    title = '',
    isOpen = false,
    setIsOpen = () => {},
    isEdit = true,
    setIsEdit = () => {},
    handleSubmit = () => {},
    inputTypes = null,
    beerTypes = null,
    users = [],
    userEntry = () => {},
    note = null,
    image = null,
    canUpload = false,
    showMultiplyButton = false,
    multiplyAmount = 1,
    onMultiply = () => {},
}) => {

    const imageText = image ? 'Vervang' : 'Upload';
    const description = 'Aantal biertjes';

    const editButtons = (
        <>
            { showMultiplyButton && <button type={'button'} onClick={onMultiply}>Keer { multiplyAmount }</button>}
            <button type={'button'} onClick={handleSubmit}>Save</button>
        </>
    );
    const nonEditButtons = (
        <>
            <button type={'button'} onClick={() => setIsEdit(true)}>Edit</button>
            <button type={'button'} onClick={() => setIsOpen(false)}>Close</button>
        </>
    );

    return (
        <CustomModal isOpen={isOpen} setIsOpen={setIsOpen}>
            <TitleHolder>
                <Title><b>{title}</b></Title>
                <CloseHolder onClick={() => setIsOpen(false)}>X</CloseHolder>
            </TitleHolder>
            <Body>
                <CustomRow>
                    <Half>Invoer type</Half>
                    <Half>
                        {inputTypes}
                    </Half>
                </CustomRow>
                <CustomRow>
                    <Half>Bier soort</Half>
                    <Half>
                        {beerTypes}
                    </Half>
                </CustomRow>
                <Spacer />
                <CustomRow>
                    <Half><b>Naam</b></Half><Half><b>{description}</b></Half>
                </CustomRow>
                {users.map(x => (
                    <CustomRow key={x.id}>
                        <Half>{x.username}</Half>
                        <Half>{userEntry(x)}</Half>
                    </CustomRow>
                ))}
                <CustomRow>
                    <Half>Notitie</Half>
                    <Half>{note}</Half>
                </CustomRow>
                {!isEdit && (
                    <>
                        <CustomRow>
                            <Half>Image</Half>
                            <Half>
                                {canUpload &&
                                    <button type={'button'} onClick={handleSubmit}>{imageText}</button>
                                }
                            </Half>
                        </CustomRow>
                        {image && (
                            <CustomRow>
                                <CustomRow>
                                    <img src={image} alt={''} style={{maxWidth: '100%'}} />
                                </CustomRow>
                            </CustomRow>
                        )}
                    </>
                )}
            </Body>
            <Footer>
                {isEdit ? editButtons : nonEditButtons}
            </Footer>
        </CustomModal>
    );
};

export default BeerInputModal;
