import React, {useEffect, useState} from "react";
import ButtonWrapper from "../../styles/ButtonWrapper";
import Button from "../../styles/Button";
import styled from "styled-components";

const Container = styled.div`
    min-width: 100%;
`;

const CollapsablePart = ({ buttonText, getData, children}) => {
    const [show, setShow] = useState(false);

    const toggleShow = () => {
        setShow(!show);
    };

    useEffect(() => {
        if (show)
            getData();
    }, [show]);

    return (
        <Container>
            <ButtonWrapper>
                <Button onClick={toggleShow}>{buttonText}</Button>
            </ButtonWrapper>
            {show && children}
        </Container>
    )
};

export default CollapsablePart;
