import React from "react";
import styled from "styled-components";
import PageWrapper from "../PageWrapper";
import {Page, Td, Tr} from "../../styles/Page";
import ImgsViewer from "react-images-viewer";
import {capitalizeFirstChar} from "../../../util/Helpers";
import useAlbumDetail from "../../../shared/hooks/AlbumDetailHook";
import DefaultTable from "../common/DefaultTable";

const Title = styled.div`
    margin-bottom: 10px;
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const headers = [
    {
        name: 'ID',
    },
    {
        name: 'Datum',
    },
    {
        name: 'Eigenaar',
    },
    {
        name: '',
        width: 10
    },
];

const produceRow = (image, index, handleClick, me, handleDelete) => {
    return (
        <Tr key={image.id} onClick={() => handleClick(image.id, index)} style={{ cursor: 'pointer' }}>
            <Td>
                {image.id}
            </Td>
            <Td>
                {image.createdOn}
            </Td>
            <Td>
                {image.createdBy?.username}
            </Td>
            {image.createdBy?.id === me.id
                ? (<Td onClick={(e) => handleDelete(e, image.id)} title={'Verwijder'}>X</Td>)
                : (<Td />)
            }
        </Tr>
    )
};

// This page cannot use a sortable table, since the image urls wont be in the correct order after sorting
const AlbumDetailPage = () => {

    const {
        imageInputRef,
        showImageViewer,
        setShowImageViewer,
        currentImage,
        setCurrentImage,
        me,
        album,
        handleItemClick,
        deleteItem,
        handleFileChange,
        imageUrls
    } = useAlbumDetail();

    return (
        <PageWrapper>
            <Page>
                <input type='file' id='file' ref={imageInputRef} style={{display: 'none'}} onChange={handleFileChange}/>
                <Title>
                    <span>{capitalizeFirstChar(album?.name)}</span>
                    {album?.editable && (<button type={'button'} onClick={() => imageInputRef.current.click()}>Upload</button>)}
                </Title>
                <DefaultTable
                    headers={headers}
                    content={album?.images}
                    convertRow={(item, index) => produceRow(item, index, handleItemClick, me, deleteItem)}
                />
                <ImgsViewer
                    imgs={imageUrls}
                    currImg={currentImage}
                    isOpen={showImageViewer}
                    onClickPrev={() => setCurrentImage(currentImage - 1)}
                    onClickNext={() => setCurrentImage(currentImage + 1)}
                    onClose={() => {
                        setShowImageViewer(false);
                        setCurrentImage(null);
                    }}
                    preventScroll={false}
                />
            </Page>
        </PageWrapper>
    );
};

export default AlbumDetailPage;