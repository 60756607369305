import React, {useContext, useEffect} from "react";
import PageWrapper from "../PageWrapper";
import TransactionTableHeader from "./TransactionTableHeader";
import styled from "styled-components";
import MyCookingPointsPanel from "../panel/MyCookingPointsPanel";
import MyBalancePanel from "../panel/MyBalancePanel";
import {MeContext} from "../../../shared/context/MeContext";
import {UsersContext} from "../../../shared/context/UsersContext";
import {CookingBalanceContext} from "../../../shared/context/CookingBalanceContext";
import TransactionTableBody from "./TransactionTableBody";
import {TransactionContext} from "../../../shared/context/TransactionContext";
import {TransactionBalanceContext} from "../../../shared/context/TransactionBalanceContext";
import {useParams} from "react-router";
import TransactionEnrollmentForm from "./enrollment/TransactionEnrollmentForm";
import {useHistory} from "react-router-dom";
import TransactionTableFooter from "./TransactionTableFooter";
import TransactionEnrollmentPriceForm from "./enrollment/TransactionEnrollmentPriceForm";
import TransactionDetails from "./TransactionDetails";
import {getMyTransactionBalanceFromOverview} from "../../../shared/util/TransactionUtils";

const Page = styled.div`
    padding: 10px;
`;

const Table = styled.table`
    display: block;
    width: 470px;
`;

const createEmptyTransaction = (me) => {
    return {
        id: -1,
        enrollments: [],
        owner: {
            id: me.id,
            username: me.username
        },
        description: 'nieuw',
        amount: 0.00
    }
}

const TransactionPage = () => {

    const history = useHistory();
    const { id } = useParams();
    const { me, loadMe } = useContext(MeContext);
    const { users, loadUsers} = useContext(UsersContext);
    const { cookingBalanceOverview, getMyCookingBalance } = useContext(CookingBalanceContext);
    const { transactionBalanceOverview, getTransactionBalanceOverview } = useContext(TransactionBalanceContext);
    const { transactions, getTransactions, createTransaction, editTransaction } = useContext(TransactionContext);

    useEffect(() => {
        loadMe();
        loadUsers();
        getTransactions();
        getTransactionBalanceOverview();
    }, []);

    useEffect(() => {
        if (me.id !== -1) {
            getMyCookingBalance();
        }
    }, [me.id]);

    const currentTransaction = transactions.content.find(x => x.id === parseInt(id)) || createEmptyTransaction(me);

    const enroll = (enrollments = []) => {
        if (currentTransaction.id === -1) {
            create(enrollments);
            return;
        }

        const data = {
            description: currentTransaction.description,
            price: currentTransaction.amount,
            enrolled: enrollments
        }
        editTransaction(currentTransaction.id, data, !!currentTransaction.cookingEventId);
    }

    const customEnroll = (userId = -1, amount = -1) => {
        if (currentTransaction.id === -1) { // Check if we are using the create new line
            customCreate(userId, amount);
            return;
        }
        const currentEnrollment = currentTransaction.enrollments.find((x) => x.user.id === userId);
        const data = {
            description: currentTransaction.description,
            price: currentTransaction.amount,
            enrolled: []
        }
        if (currentEnrollment) {
            // Update current enrollment
            data.enrolled = currentTransaction.enrollments.map((x) => x.user.id === userId ? ({
                user: x.user.id,
                amount: amount
            }) : ({
                user: x.user.id,
                amount: x.amount,
            }));
        } else { // Create new enrollment
            const enrollments = currentTransaction.enrollments.map((x) => ({
                user: x.user.id,
                amount: x.amount,
            }));
            enrollments.push({
                user: userId,
                amount: amount,
            });
            data.enrolled = enrollments;
        }
        enroll(data.enrolled);
    };

    const customCreate = (userId = -1, amount = -1) => {
        const enrolled = [{user: userId, amount: amount}];
        create(enrolled);
    };

    const create = (enrolled = []) => {
        const data = {
            description: "nieuw",
            price: 0.00,
            enrolled: enrolled
        };
        const onSuccess = (newId) => {
            history.push(`/geldlijst/${newId}`);
        }
        createTransaction(data, onSuccess);
    }

    return (
        <PageWrapper rightPanels={[
            <MyCookingPointsPanel cookingPoints={cookingBalanceOverview.find((x) => x.user.id === me.id)?.balance} />,
            <MyBalancePanel balance={getMyTransactionBalanceFromOverview(transactionBalanceOverview, me.id)} />
        ]}>
            <Page>
                <Table>
                    <TransactionTableHeader headers={users.map(x => x.username)}/>
                    <TransactionTableBody
                        users={users}
                        transactions={transactions.content}
                        currentTransactionId={currentTransaction.id}
                        getTransactions={getTransactions}
                        page={transactions.number}
                        last={transactions.last}
                    />
                    <TransactionTableFooter users={users} balanceOverview={transactionBalanceOverview} toFixed={true} />
                </Table>
                <TransactionEnrollmentForm
                    me={me}
                    users={[...users, {id: -1, username: 'Iedereen'}]}
                    transaction={currentTransaction}
                    enroll={customEnroll}
                    allUsers={users}
                    multipleEnroll={enroll}
                />
                {currentTransaction.owner.id === me.id ? (
                    <TransactionEnrollmentPriceForm
                        transaction={currentTransaction}
                        editTransaction={editTransaction}
                    />
                ) : (
                  <TransactionDetails
                      transaction={currentTransaction}
                  />
                )}
            </Page>
        </PageWrapper>
    );
};

export default TransactionPage;