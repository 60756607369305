import ActionBar from "../../ActionBar";
import {routes} from "../../../../shared/routing/Routes";
import MobilePageWrapper from "../../MobilePageWrapper";
import React from "react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography} from "@material-ui/core";
import useAlbumDetail from "../../../../shared/hooks/AlbumDetailHook";
import ImgsViewer from "react-images-viewer";
import Fab from "../../../styles/Fab";
import {colors} from "../../../../util/Colors";
import {capitalizeFirstChar} from "../../../../util/Helpers";

const MobileAlbumDetailPage = () => {

    const {
        imageInputRef,
        showImageViewer,
        setShowImageViewer,
        currentImage,
        setCurrentImage,
        me,
        album,
        handleItemClick,
        deleteItem,
        handleFileChange,
        imageUrls
    } = useAlbumDetail();

    return (
        <MobilePageWrapper>
            <ActionBar activeRoute={routes.albums}>
                <input type='file' id='file' ref={imageInputRef} style={{display: 'none'}} onChange={handleFileChange}/>
                <Toolbar style={{ backgroundColor: colors.lightOrange, color: colors.white }}>
                    <Typography variant="h6" id="tableTitle" component="div">
                        {capitalizeFirstChar(album?.name)}
                    </Typography>
                </Toolbar>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Owner</TableCell>
                                <TableCell style={{ width: 10 }} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {album?.images?.map((image, index) => (
                                <TableRow key={image.id} hover tabIndex={-1} onClick={() => handleItemClick(image.id, index)}>
                                    <TableCell>{image.id}</TableCell>
                                    <TableCell>{image.createdOn}</TableCell>
                                    <TableCell>{image.createdBy?.username}</TableCell>
                                    <TableCell>
                                        {image.createdBy?.id === me.id && (
                                            <span
                                                onClick={(e) => deleteItem(e, image.id)}
                                                title={'Delete'}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                X
                                            </span>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                {album?.editable && (
                    <Fab text={'+'} onClick={() => imageInputRef.current.click()} />
                )}

                <ImgsViewer
                    imgs={imageUrls}
                    currImg={currentImage}
                    isOpen={showImageViewer}
                    onClickPrev={() => setCurrentImage(currentImage - 1)}
                    onClickNext={() => setCurrentImage(currentImage + 1)}
                    onClose={() => {
                        setShowImageViewer(false);
                        setCurrentImage(null);
                    }}
                    preventScroll={false}
                />
            </ActionBar>
        </MobilePageWrapper>
    );
};

export default MobileAlbumDetailPage