import {constants} from "./Constants";

export const capitalizeFirstChar = (str) => {
    if (typeof str !== 'string') return '';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const formatTimestamp = (timestamp = '') => {
    const parts = timestamp.split('T');
    const date = parts[0];
    const time = parts[1].substr(0,5);
    return date + ' ' + time;
};

export const getKeyFromIds = (array = []) => {
    let result = '';
    array.forEach((x) => result += `${x.id}`);
    return result;
};

export const preventNegative = (input = 0) => {
    return input < 0 ? 0 : input;
};

export const minimum = (input = 0, minimum = 0) => {
    return input < minimum ? minimum : input;
};

export const isAdmin = (user) => {
    return hasRole(user, constants.roles.admin);
};

export const isFeut = (user) => {
    return hasRole(user, constants.roles.feut);
};

export const hasRole = (user, role) => {
    return !!user.roles.find(x => x === role);
}

export const uniqueUsers = (a) => {
    const seen = new Set();
    return a.filter(u => {
        if (seen.has(u.id))
            return false;
        seen.add(u.id);
        return true;
    })
};

// Empty function for props
export const func = () => {};