import React from "react";
import styled from "styled-components";
import {ListDate, ListItem} from "../../styles/List";
import {colors} from "../../../util/Colors";
import {fontSizes} from "../../util/Fontsizes";
import {Col} from "../../styles/Grid";

const ItemInfoWrapper = styled(Col)`
    justify-content: center;
    flex-grow: 1;
`;

const ItemTitle = styled.div`
    color: ${colors.black};
    font-size: ${fontSizes.base}px;
    font-weight: bold;
`;

const ItemSubtitle = styled.div`
    color: ${colors.black};
    font-size: ${fontSizes.base}px;
`;

const ItemRecipient = styled.div`
    font-size: ${fontSizes.base}px;
    color: ${colors.orange};
`;

const DonationListItem = ({ createdOn = '', createdBy = '', amount = 0, recipient = '' }) => {
    return (
        <ListItem>
            <ListDate date={createdOn} />
            <ItemInfoWrapper>
                <ItemTitle>{createdBy}</ItemTitle>
                <ItemSubtitle>{`Donated ${amount} cooking point${amount === 1 ? '' : 's'} to`}</ItemSubtitle>
                <ItemRecipient>{recipient}</ItemRecipient>
            </ItemInfoWrapper>
        </ListItem>
    );
};

export default React.memo(DonationListItem);