import React from "react";
import {FlexCenterContainer} from "../../styles/Layout";
import styled from 'styled-components';
import headerImage from '../../images/header.png';

const HeaderImage = styled.img`
    margin-bottom: 10px;
`;

const Header = () => {

    return (
        <FlexCenterContainer style={{paddingTop: 20}}>
            <HeaderImage src={headerImage} alt={''} />
        </FlexCenterContainer>
    )
}

export default Header;