import React from "react";
import {Fab as MaterialFab} from "@material-ui/core";
import {spacing} from "../util/Spacing";
import {colors} from "../../util/Colors";
import {fontSizes} from "../util/Fontsizes";

const Fab = ({ text = '', onClick = () => {} }) => {
    return (
        <MaterialFab
            color="primary"
            aria-label="add"
            style={{
                color: colors.white,
                fontSize: fontSizes.big,
                position: 'absolute',
                bottom: spacing.base,
                right: spacing.base
            }}
            onClick={onClick}>
            {text}
        </MaterialFab>
    );
};

export default Fab;