import React from 'react';
import styled from "styled-components";
import {spacing} from "../../util/Spacing";
import {fontSizes} from "../../util/Fontsizes";
import {colors} from "../../../util/Colors";
import {Row} from "../../styles/Grid";
import RoundImage from "../RoundImage";

const StyledRow = styled(Row)`
    flex-wrap: nowrap;
    width: 100%;
    align-items: center;
    padding-top: ${spacing.small}px;
`;

const RowText = styled.div`
    padding-left: ${spacing.base}px;
    font-size: ${fontSizes.base}px;
    color: ${colors.black};
    font-weight: bold;
`;

const CookingNextItem = ({ imageId, username = '', balance = 0 }) => {
    return (
        <StyledRow>
            <RoundImage imageId={imageId} width={'30px'} borderColor={colors.white} />
            <RowText>{username} ({balance})</RowText>
        </StyledRow>
    );
};

export default React.memo(CookingNextItem);