import React, {createContext, useState} from "react";
import {
    getRoomSolicitingDayForDateRequest,
    getRoomSolicitingDayForDatesRequest
} from "../../network/roomsoliciting/day/RoomSolicitingDayRequests";
import {genericSortAsc} from "../../util/SortUtils";
import {mergeArraysById} from "../../util/ArrayUtils";

export const RoomSolicitingDayContext = createContext({});

const RoomSolicitingDayContextProvider = (props) => {

    const [days, setDays] = useState([]);

    const getDay = async (date) => {
        const data = await getRoomSolicitingDayForDateRequest(date);
        if (data) {
            setDays(mergeArraysById(days, [data]).sort((a,b) => genericSortAsc(a,b,'date')));
        }
    }

    const getDays = async (from, to) => {
        const data = await getRoomSolicitingDayForDatesRequest(from, to);
        if (data) {
            setDays(mergeArraysById(days, data).sort((a,b) => genericSortAsc(a,b,'date')))
        }
    }

    return (
        <RoomSolicitingDayContext.Provider value={{ days, getDay, getDays }}>
            {props.children}
        </RoomSolicitingDayContext.Provider>
    );
};

export default RoomSolicitingDayContextProvider;
