import React from "react";
import {Snackbar} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import {func} from "../../util/Helpers";

const Toast = ({ isOpen = false, close = func, text = '', severity = 'success', snackbarProps = {}, ...props }) => {

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        close();
    };

    return (
        <Snackbar open={isOpen} autoHideDuration={5000} onClose={handleClose} {...snackbarProps}>
            <MuiAlert elevation={6} variant='filled' severity={severity} {...props}>
                {text}
            </MuiAlert>
        </Snackbar>
    );
};

export default Toast;