import React from "react";
import CustomModal from "../CustomModal";
import styled from "styled-components";
import {colors} from "../../../util/Colors";
import {Row} from "../../styles/Layout";
import useCookingActions from "../../../shared/hooks/CookingActionsHook";
import {formatTimestamp} from "../../../util/Helpers";

const TitleHolder = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    
    padding-bottom: 20px;
    padding-top: 20px;
    
    border-style: solid;
    border-width: 0px 0px 1px 0px;
    border-color: ${colors.borderLightGrey};
    
    height: 26px;
`;

const Title = styled.div`
    font-size: 18px;
`;

const CloseHolder = styled.div`
    position: absolute;
    right: 0;
    cursor: pointer;
    margin-right: 20px;
    font-size: 22px;
`;

const Body = styled.div`
    display: flex;
    padding: 20px;
    flex-direction: column;
`;

const Footer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    
    padding: 20px;
    
    border-style: solid;
    border-width: 1px 0px 0px 0px;
    border-color: ${colors.borderLightGrey};
`;

const CookingActionsModal = ({ isOpen = false, setIsOpen = (value) => {}, cookingEventId = -1 }) => {

    const { actions } = useCookingActions(cookingEventId);

    return (
        <CustomModal isOpen={isOpen} setIsOpen={setIsOpen}>
            <TitleHolder>
                <Title><b>Acties</b></Title>
                <CloseHolder onClick={() => setIsOpen(false)}>X</CloseHolder>
            </TitleHolder>
            <Body>
                {actions.map(x => (
                    <Row key={x.id} style={{ marginBottom: 5 }}>
                        {formatTimestamp(x.timestamp)} - {x.message}
                    </Row>
                ))}
            </Body>
            <Footer>
                <button type={'button'} onClick={() => setIsOpen(false)}>Close</button>
            </Footer>
        </CustomModal>
    );
};

export default CookingActionsModal;