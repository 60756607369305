import React, {useContext, useEffect, useState} from "react";
import dayjs from "dayjs";
import week from "dayjs/plugin/weekOfYear";
import {RoomSolicitingDayContext} from "../../../../shared/context/roomsoliciting/RoomSolicitingDayContext";
import DateSliderItem from "./DateSliderItem";
import styled from "styled-components";
import {Row} from "../../../styles/Layout";
import CookingTableHeader from "../../cooking/table/CookingTableHeader";
import useJwt from "../../../../shared/hooks/JwtHook";
dayjs.extend(week);

const ItemsContainer = styled(Row)`
    justify-content: center;
`;

const ButtonsContainer = styled(Row)`
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    justify-content: space-between;
`;

const getFirstDayOfWeek = (date) => {
    const datedayjs = dayjs(date);
    const dayNumber = datedayjs.day();
    if (dayNumber === 0) {
        return datedayjs.subtract(6, 'days').format('YYYY-MM-DD');
    } else {
        return datedayjs.subtract(dayNumber - 1, 'days').format('YYYY-MM-DD');
    }
}

export const getDaysToDisplay = (days, firstDayString, lastDayString) => {
    const result = [];

    if (!days || days.length === 0) return result;

    let newId = -1;

    let day = dayjs(firstDayString);
    const lastDay = dayjs(lastDayString);
    while (day.isBefore(dayjs(lastDay))) {
        const currentDate = day.format('YYYY-MM-DD');
        const event = days.find((x) => x.date === currentDate);

        result.push(event || { id: newId--, date: currentDate, availabilities: [], appointments: [] });

        day = day.add(1, 'day');
    }

    return result;
};

const DateSlider = ({ selectedDate }) => {

    // Explicitly call useJwt hook, since otherwise it somehow does not get called in time
    useJwt()

    const { days, getDays } = useContext(RoomSolicitingDayContext);

    const [ startDate, setStartDate ] = useState(getFirstDayOfWeek(dayjs()));

    const endDate = dayjs(startDate).add(7, 'days').format('YYYY-MM-DD');

    const daysToDisplay = getDaysToDisplay(days, startDate, endDate);

    useEffect(() => {
        if (startDate && endDate) {
            getDays(startDate, endDate);
        }
    }, [ startDate, endDate ]);

    const handlePreviousClick = () => {
        setStartDate(dayjs(startDate).subtract(7, 'days').format('YYYY-MM-DD'));
    }

    const handleNextClick = () => {
        setStartDate(dayjs(endDate).format('YYYY-MM-DD'));
    }

    return (
        <>
            <ButtonsContainer>
                <button type={'button'} onClick={handlePreviousClick}>{'<'}</button>
                <span>Week {dayjs(daysToDisplay[0]?.date).week()}</span>
                <button type={'button'} onClick={handleNextClick}>{'>'}</button>
            </ButtonsContainer>
            <div style={{ marginLeft: 7, width: 480 }}>
                <CookingTableHeader />
            </div>
            <ItemsContainer>
                {daysToDisplay.map(x => (
                    <DateSliderItem key={x.id} day={x} selectedDate={selectedDate} />
                ))}
            </ItemsContainer>
        </>
    );
};

export default DateSlider;
