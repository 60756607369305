import React from "react";
import {PanelBody, PanelBottom, PanelContainer, PanelTop} from "../../styles/Panel";
import {Title} from "../../styles/Layout";
import Amount from "../../styles/Amount";

const shouldNotUpdate = (prevProps, nextProps) => {
    return prevProps.balance === nextProps.balance;
}

const formatBalance = (balance = 0) => {
    if (isNaN(balance)) {
        balance = 0;
    }
    return balance <= 0 ? balance : `+${balance}`;
};

const MyBeerBalancePanel = ({ balance }) => {
    return (
        <PanelContainer>
            <PanelTop>
                <Title>Biertegoed</Title>
            </PanelTop>
            <PanelBody>
                <Amount>{formatBalance(balance)}</Amount>
            </PanelBody>
            <PanelBottom/>
        </PanelContainer>
    );
}

export default React.memo(MyBeerBalancePanel, shouldNotUpdate);
