import styled from "styled-components";
import {colors} from "../../util/Colors";
import {Row} from "./Layout";

export const TitleHolder = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    padding-bottom: 20px;
    padding-top: 20px;

    border-style: solid;
    border-width: 0px 0px 1px 0px;
    border-color: ${colors.borderLightGrey};

    height: 26px;
`;

export const Title = styled.div`
    font-size: 18px;
`;

export const CloseHolder = styled.div`
    position: absolute;
    right: 0;
    cursor: pointer;
    margin-right: 20px;
    font-size: 22px;
`;

export const Body = styled.div`
    display: flex;
    padding: 20px;
    flex-direction: column;
`;

export const Footer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex: 1;

    padding: 20px;

    border-style: solid;
    border-width: 1px 0px 0px 0px;
    border-color: ${colors.borderLightGrey};
`;

export const CustomRow = styled(Row)`
    margin-bottom: 5px;
`;

export const Half = styled.div`
    width: 50%;
`;
