export const formatBalance = (input) => {
    if (!input) input = 0;
    let result = '€';
    result += input.toFixed(2);
    return result;
};

export const formatCookingPoints = (input) => {
    let result = '';
    if (input >= 0) {
        result += '+';
    }
    result += input;
    return result;
};

export const priceRegex = /^[-]?((\d+[.]\d{2})|(\d+))$/;

export const areEqual = (one, other) => {
    return JSON.stringify(one) === JSON.stringify(other);
}
