import React, {useContext} from "react";
import {StatsContext} from "../../../shared/context/StatsContext";
import CollapsablePart from "./CollapsablePart";
import AveragePricesPerMonthChart from "../../../shared/stats/AveragePricesPerMonthChart";


const AveragePricesPerMonth = () => {
    const { averageCostsPerMonth, getAverageCostsPerMonth } = useContext(StatsContext);

    return (
        <CollapsablePart
            buttonText={"Laat gemiddelde prijzen per maand zien"}
            getData={getAverageCostsPerMonth}
        >
            <AveragePricesPerMonthChart
                averageCostsPerMonth={averageCostsPerMonth}
            />
        </CollapsablePart>
    );
};

export default AveragePricesPerMonth;
