import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router";
import PageWrapper from "../PageWrapper";
import styled from 'styled-components';
import CookingTableHeader from "./table/CookingTableHeader";
import {CookingEventContext} from "../../../shared/context/CookingEventContext";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import CookingTableBody from "./table/CookingTableBody";
import {MeContext} from "../../../shared/context/MeContext";
import CookingEnrollmentForm from "./enrollment/CookingEnrollementForm";
import {UsersContext} from "../../../shared/context/UsersContext";
import MyCookingPointsPanel from "../panel/MyCookingPointsPanel";
import MyBalancePanel from "../panel/MyBalancePanel";
import CookingPointsOverviewPanel from "../panel/CookingPointsOverviewPanel";
import {CookingBalanceContext} from "../../../shared/context/CookingBalanceContext";
import {TransactionBalanceContext} from "../../../shared/context/TransactionBalanceContext";
import {TransactionContext} from "../../../shared/context/TransactionContext";
import CookingPriceForm from "./CookingPriceForm";
import CookingDryers from "./CookingDryers";
import UpdateDryersModal from "./UpdateDryersModal";
import CookingActionsModal from "./CookingActionsModal";
import {getMyTransactionBalanceFromOverview} from "../../../shared/util/TransactionUtils";
import {createEmptyEvent, getActiveEvents, parseDateString} from "../../../shared/util/CookingEventUtils";
import {isAdmin} from "../../../util/Helpers";
dayjs.extend(weekday)

const Page = styled.div`
    padding: 10px;
`;

const getFirstDateVisible = (dateString) => {
    let date = parseDateString(dateString)
    let weekday = date.weekday();
    // Correct sunday being 0
    if (weekday === 0) {
        weekday += 7;
    }
    return date.subtract(13+weekday, 'days').format('YYYY-MM-DD');
};

const getLastDateVisible = (dateString) => {
    let date = parseDateString(dateString)
    let weekday = date.weekday();
    // Correct sunday being 0
    if (weekday === 0) {
        weekday += 7;
    }
    return date.add(22-weekday, 'days').format('YYYY-MM-DD');
};

const CookingPage = () => {

    const { date } = useParams();
    const {cookingEvents, getCookingEvents, setMeAsCook, unSetMeAsCook, enroll, updateCookingEvent, updateCookingEventDryers} = useContext(CookingEventContext);
    const { me, loadMe } = useContext(MeContext);
    const { users, loadUsers} = useContext(UsersContext);
    const { cookingBalanceOverview, getCookingBalanceOverview } = useContext(CookingBalanceContext);
    const { transactionBalanceOverview, getMyTransactionBalance } = useContext(TransactionBalanceContext);
    const { createTransactionForCookingEvent, editTransactionForCookingEvent } = useContext(TransactionContext);

    const [ showUpdateDryersModal, setShowUpdateDryersModal ] = useState(false);
    const [ showActionsModal, setShowActionsModal ] = useState(false);

    const currentDate = date || dayjs().format('YYYY-MM-DD');

    const firstDate = getFirstDateVisible(currentDate);
    const lastDate = getLastDateVisible(currentDate);

    useEffect(() => {
        loadMe();
        loadUsers();
        getCookingBalanceOverview();
    }, []);

    useEffect(() => {
        if (me.id !== -1) {
            getMyTransactionBalance();
        }
    }, [me.id]);

    useEffect(() => {
        getCookingEvents(firstDate, lastDate);
    }, [firstDate, lastDate]);

    const activeEvents = getActiveEvents(cookingEvents, firstDate, lastDate);
    const currentEvent = activeEvents.find((x) => x.date === currentDate) || createEmptyEvent(-1, currentDate);

    const meIsCook = currentEvent.cook?.id === me.id;
    const meIsAdmin = isAdmin(me);

    const customEnroll = (userId = -1, amount = -1) => {
        if (currentEvent.cook?.id === me.id) {
            const currentEnrollment = currentEvent.enrolled.find((x) => x.user.id === userId);
            const data = {
                enrollments: []
            }
            if (currentEnrollment) {
                // Update enrollment
                data.enrollments = currentEvent.enrolled.map((x) => x.user.id === userId ? ({
                    user: x.user.id,
                    amount: amount
                }) : ({
                    user: x.user.id,
                    amount: x.amount,
                }));
            } else {
                // Create new enrollment
                const enrollments = currentEvent.enrolled.map((x) => ({
                    user: x.user.id,
                    amount: x.amount,
                }));
                enrollments.push({
                    user: userId,
                    amount: amount,
                });
                data.enrollments = enrollments;
            }
            updateCookingEvent(currentEvent.id, data);
        } else {
            if (userId === me.id) {
                // Enroll me
                enroll(currentEvent?.id, amount);
            }
        }
    }

    return (
        <PageWrapper rightPanels={[
            <MyCookingPointsPanel cookingPoints={cookingBalanceOverview.find((x) => x.user.id === me.id)?.balance} />,
            <MyBalancePanel balance={getMyTransactionBalanceFromOverview(transactionBalanceOverview, me.id)} />,
            <CookingPointsOverviewPanel balanceOverview={cookingBalanceOverview} />
        ]}>
            <Page>
                <CookingTableHeader />
                <CookingTableBody
                    me={me}
                    cookingEvents={activeEvents}
                    currentDate={currentDate}
                    setMeAsCook={setMeAsCook}
                    unSetMeAsCook={unSetMeAsCook}
                    enroll={enroll}
                />
                <CookingEnrollmentForm
                    users={users}
                    event={currentEvent}
                    myId={me.id}
                    enroll={customEnroll}
                />
                <CookingDryers
                    canEdit={meIsCook || meIsAdmin}
                    event={currentEvent}
                    openUpdateModal={() => setShowUpdateDryersModal(true)}
                />
                {meIsCook && (
                    <CookingPriceForm
                        cookingEventId={currentEvent?.id}
                        amount={currentEvent?.transaction?.amount}
                        shouldCreate={!currentEvent?.transaction}
                        createTransaction={createTransactionForCookingEvent}
                        editTransaction={editTransactionForCookingEvent}
                        openActionsModal={() => setShowActionsModal(true)}
                    />
                )}
            </Page>
            <UpdateDryersModal
                isOpen={showUpdateDryersModal}
                setIsOpen={setShowUpdateDryersModal}
                event={currentEvent}
                update={updateCookingEventDryers}
            />
            <CookingActionsModal
                isOpen={showActionsModal}
                setIsOpen={setShowActionsModal}
                cookingEventId={currentEvent.id}
            />
        </PageWrapper>
    );
};

export default CookingPage;
