import React, {useContext, useEffect, useState} from "react";
import PageWrapper from "../PageWrapper";
import styled from "styled-components";
import {MeContext} from "../../../shared/context/MeContext";
import ShuttleDrunkTestScorePanel from "../panel/ShuttleDrunkTestScorePanel";
import {UsersContext} from "../../../shared/context/UsersContext";
import ShuttleDrunkTestOverviewPanel from "../panel/ShuttleDrunkTestOverviewPanel";
import dayjs from "dayjs";
import {Row} from "../../styles/Layout";
import {isAdmin} from "../../../util/Helpers";
import {ShuttleDrunkTestContext} from "../../../shared/context/ShuttleDrunkTestContext";
import SortableTable from "../common/SortableTable";
import {Page, Td, Tr} from "../../styles/Page";

const StyledRow = styled(Row)`
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
`;

const Input = styled.input`
    width: 115px;
`;

const Select = styled.select`
    width: 115px;
`;

const getMyHighScore = (myId = -1, scores = []) => {
    return scores.find(x => x.user.id === myId)?.score || 0;
};

/* renames the score property to 'balance' */
const getHighScoreOverview = (scores = []) => {
    return scores.map(x => ({
        user: x.user,
        balance: x.score
    }));
};

const ShuttleDrunkTestPage = () => {

    const { me, loadMe } = useContext(MeContext);
    const { users, loadUsers} = useContext(UsersContext);
    const {
        shuttleDrunkTestResultPage,
        shuttleDrunkTestOverview,
        getShuttleDrunkTestResults,
        getShuttleDrunkTestOverview,
        createShuttleDrunkTestResult,
        deleteShuttleDrunkTestResult
    } = useContext(ShuttleDrunkTestContext);

    const [formValues, setFormValues] = useState({
        date: dayjs().format('YYYY-MM-DD'),
        user: "",
        score: "",
    });

    useEffect(() => {
        loadMe();
        loadUsers();
        getShuttleDrunkTestOverview(false);
        getShuttleDrunkTestResults();
    }, []);

    const handleDateChange = (e) => {
        setFormValues({
            ...formValues,
            date: e.target.value
        });
    };

    const handleUserChange = (e) => {
        setFormValues({
            ...formValues,
            user: e.target.value
        });
    };

    const handleScoreChange = (e) => {
        setFormValues({
            ...formValues,
            score: e.target.value
        });
    };

    const handleSubmit = () => {
        if (!formValues.user) {
            alert('Selecteer een gebruiker');
            return;
        }
        if (!formValues.score || isNaN(formValues.score) || formValues.score < 0) {
            alert('Vul een geldig getal in als score');
            return;
        }

        createShuttleDrunkTestResult(formValues);
    };

    const buildHeaders = () => {
        return [
            {name: 'Datum', sortable: true, sortKey: 'date'},
            {name: 'Gebruiker', sortable: false},
            {name: 'Score', sortable: true, sortKey: 'score'},
        ];
    };

    const convertRow = (row) => {
        return (
            <Tr key={row.id}>
                <Td>{row.date}</Td>
                <Td>{row.user.username}</Td>
                <Td>{row.score}</Td>
                {isAdmin(me) && (
                    <Td
                        style={{fontWeight: 'bold', cursor: 'pointer'}}
                        onClick={() => deleteShuttleDrunkTestResult(row.id)}
                    >
                        X
                    </Td>
                )}
            </Tr>
        );
    };

    return (
        <PageWrapper rightPanels={[
            <ShuttleDrunkTestScorePanel score={getMyHighScore(me.id, shuttleDrunkTestOverview)} />,
            <ShuttleDrunkTestOverviewPanel overview={getHighScoreOverview(shuttleDrunkTestOverview)} />
        ]}>
            <Page>
                <SortableTable
                    headers={buildHeaders()}
                    content={shuttleDrunkTestResultPage?.content}
                    convertRow={convertRow}
                    editable={true}
                />
                {isAdmin(me) && (
                    <StyledRow>
                        <Input
                            type={'date'}
                            value={dayjs(formValues.date).format('YYYY-MM-DD')}
                            onChange={handleDateChange}
                        />
                        <Select
                            id={'userSelect'}
                            onChange={handleUserChange}
                            value={formValues.user}
                        >
                            {users?.map(x => (
                                <option key={x.id} value={x.id}>{x.username}</option>
                            ))}
                        </Select>
                        <Input
                            placeholder={'Score'}
                            type={'text'}
                            onChange={handleScoreChange}
                            value={formValues.score}
                        />
                        <button type={'button'} onClick={handleSubmit}>Nieuw</button>
                    </StyledRow>
                )}
            </Page>
        </PageWrapper>
    );
};

export default ShuttleDrunkTestPage;
