import {constants} from "../../util/Constants";
import {useCallback, useEffect} from "react";
import Axios from "axios";

function useJwt() {
    const jwt = window.localStorage.getItem(constants.localstorageJwtKey);

    const checkJwtIsValid = useCallback(() => {
        if (!jwt) return false;

        const decoded = JSON.parse(atob(jwt.split(".")[1]));
        const currentTime = Math.floor(new Date().getTime() / 1000);
        return currentTime < decoded.exp;
    }, [jwt]);

    // Set default axios header
    useEffect(() => {
        if (jwt) {
            Axios.defaults.headers.common['Authorization'] = `Bearer ${jwt}`;
        }
    }, [jwt]);

    return { jwt, checkJwtIsValid };
}

export default useJwt;