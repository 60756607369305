import React from "react";
import styled from "styled-components";
import {mobileConstants} from "../util/Constants";
import {colors} from "../../util/Colors";
import {spacing} from "../util/Spacing";
import Logo from "../images/dirty_a_logo_transparant.svg";
import {routes} from "../../shared/routing/Routes";
import {Divider, List, ListItem, ListItemText} from "@material-ui/core";
import {func} from "../../util/Helpers";
import {useHistory} from "react-router";

const Drawer = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    
    height: 100%;
    width: ${mobileConstants.drawerWidth}px;
    
    display: flex;
    flex-direction: column;
    
    transition: 0.2s ease-out;
    transform: ${props => props.open ? `translateX(0)` : `translateX(-100%)`};
    
    background-color: ${colors.white};
    
    z-index: 99;
`;

const DrawerHeader = styled.div`
    background-color: ${colors.orange};
    margin-bottom: ${spacing.small}px;
    padding: ${spacing.base}px;
`;

const NavigationDrawer = ({ isOpen, close = func, drawerRef }) => {

    const history = useHistory();

    const navigateToRoute = (route = '') => {
        close();
        history.push(route);
    };

    return (
        <Drawer open={isOpen} ref={drawerRef}>
            <DrawerHeader>
                <img src={Logo} alt={''} style={{ width: '100%' }} />
            </DrawerHeader>

            <div style={{ overflowY: 'scroll' }}>
                <List component="nav" aria-label="main mailbox folders">
                    <ListItem button onClick={() => navigateToRoute(routes.home)} style={{  }}>
                        <ListItemText primary="Home" />
                    </ListItem>
                    <ListItem button onClick={() => navigateToRoute(routes.cookingCalender)}>
                        <ListItemText primary="Cook List" />
                    </ListItem>
                    <ListItem button onClick={() => navigateToRoute(routes.moneyList)}>
                        <ListItemText primary="Money List" />
                    </ListItem>
                    <ListItem button onClick={() => navigateToRoute(routes.leds)}>
                        <ListItemText primary="LEDs" />
                    </ListItem>
                    <ListItem button onClick={() => navigateToRoute(routes.stats)}>
                        <ListItemText primary="Statistics" />
                    </ListItem>
                    <ListItem button onClick={() => navigateToRoute(routes.donations)}>
                        <ListItemText primary="Donations" />
                    </ListItem>
                    <ListItem button onClick={() => navigateToRoute(routes.beerList)}>
                        <ListItemText primary="Beer List" />
                    </ListItem>
                    <ListItem button onClick={() => navigateToRoute(routes.shuttleDrunkTest)}>
                        <ListItemText primary="Shuttle Drunk Test" />
                    </ListItem>
                    <ListItem button onClick={() => navigateToRoute(routes.albums)}>
                        <ListItemText primary="Albums" />
                    </ListItem>
                    <ListItem button onClick={() => navigateToRoute(routes.profile)}>
                        <ListItemText primary="Profile" />
                    </ListItem>
                </List>

                <Divider />

                <List component="nav" aria-label="main mailbox folders">
                    <ListItem button onClick={() => navigateToRoute(routes.logout)}>
                        <ListItemText primary="Log out" />
                    </ListItem>
                </List>
            </div>

        </Drawer>
    );
};

export default React.memo(NavigationDrawer);