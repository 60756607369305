import {useContext, useEffect, useState} from "react";
import {getCookingEventDryerSuggestionsRequest} from "../network/CookingRequests";
import {CookingEventContext} from "../context/CookingEventContext";

const initialFormValues = {
    dryer1: null,
    dryer2: null
};

function useCookingDryers(isOpen, close, cookingEvent) {

    const [formValues, setFormValues] = useState(initialFormValues);
    const [dryerSuggestions, setDryerSuggestions] = useState([]);

    const { updateCookingEventDryers } = useContext(CookingEventContext);

    useEffect(() => {
        if (isOpen) {
            setFormValues({
                dryer1: cookingEvent?.dryer1?.id,
                dryer2: cookingEvent?.dryer2?.id
            });
            getCookingEventDryerSuggestionsRequest(cookingEvent?.id).then(x => setDryerSuggestions(x))
        }
    }, [isOpen])

    const handleDryer1Change = (event) => {
        setFormValues({
            ...formValues,
            dryer1: event.target.value
        });
    };

    const handleDryer2Change = (event) => {
        setFormValues({
            ...formValues,
            dryer2: event.target.value
        });
    };

    const applySuggestions = () => {
        const suggestedFormValues = {
            dryer1: dryerSuggestions[0]?.id || "",
            dryer2: dryerSuggestions[1]?.id || "",
        };
        submit(suggestedFormValues);
    };

    const handleSubmit = () => {
        submit(formValues);
    };

    const submit = (dryers) => {
        updateCookingEventDryers(cookingEvent.id, dryers, close());
    };

    return {
        formValues,
        dryerSuggestions,
        handleDryer1Change,
        handleDryer2Change,
        applySuggestions,
        handleSubmit
    };

}

export default useCookingDryers;