import {func} from "../../util/Helpers";

export const genericSortDesc = (a, b, key) => {
    if (a[key] > b[key]) return -1;
    if (a[key] < b[key]) return 1;
    return 0;
}

export const genericSortAsc = (a, b, key) => {
    if (a[key] > b[key]) return 1;
    if (a[key] < b[key]) return -1;
    return 0;
}

export const customSortAsc = (a, b, keyExtractor = func) => {
    if (keyExtractor(a) > keyExtractor(b)) return 1;
    if (keyExtractor(a) < keyExtractor(b)) return -1;
    return 0;
}

export const sortByIdAsc = (a, b) => {
    return genericSortAsc(a, b, 'id');
};

export const sortByBalanceDescAndUserIdAsc = (a, b) => {
    if (a.balance > b.balance) return -1;
    if (a.balance < b.balance) return 1;

    if (a.user.id > b.user.id) return 1;
    if (a.user.id < b.user.id) return -1;

    return 0;
};

export const sortByDateAsc = (a, b) => {
    return new Date(a.date) - new Date(b.date);
};

export const sortByDateDesc = (a, b) => {
    return new Date(a.date) - new Date(b.date);
};

export const sortByDateAndIdDesc = (a, b) => {
    const aDate = new Date(a.date);
    const bDate = new Date(b.date);
    if (aDate.getTime() === bDate.getTime())
        return genericSortDesc(a, b, 'id');

    return new Date(b.date) - new Date(a.date);
};

export const sortByDateAndIdAsc = (a, b) => {
    const aDate = new Date(a.date);
    const bDate = new Date(b.date);
    if (aDate.getTime() === bDate.getTime())
        return genericSortAsc(a, b, 'id');

    return new Date(a.date) - new Date(b.date);
};