import React from "react";
import {PanelBody, PanelBottom, PanelContainer, PanelTop} from "../../styles/Panel";
import {Title} from "../../styles/Layout";
import Amount from "../../styles/Amount";


const shouldNotUpdate = (prevProps, nextProps) => {
    return prevProps.score === nextProps.score;
}

const ShuttleDrunkTestScorePanel = ({ score }) => {
    return (
        <PanelContainer>
            <PanelTop>
                <Title>Score</Title>
            </PanelTop>
            <PanelBody>
                <Amount>{score}</Amount>
            </PanelBody>
            <PanelBottom />
        </PanelContainer>
    );

}

export default React.memo(ShuttleDrunkTestScorePanel, shouldNotUpdate);