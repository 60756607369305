import React from "react";
import styled from "styled-components";
import ReactModal from 'react-modal';
import {colors} from "../../../util/Colors";
import {spacing} from "../../util/Spacing";
import {Row} from "../../styles/Grid";

const overlay = {
    display: 'flex',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(51, 51, 51, 0.75)'
};

const content = {
    position: 'static',

    width: '80%',
    marginLeft: '10%',
    marginRight: '10%',

    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',

    background: colors.white,
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    outline: 'none',
    padding: 0,
    fontFamily: 'Roboto',
};

const Title = styled(Row)`
    padding-left: ${spacing.base}px;
    padding-right: ${spacing.base}px;
    padding-top: ${spacing.big}px;
    padding-bottom: ${spacing.big}px;
    
    border-bottom: 1px solid ${colors.borderLightGrey};
    
    font-weight: bold;
    
    justify-content: space-between;
`;

export const MobileModalContent = styled.div`
    overflow-y: auto;
    max-height: 60vh;
`;

export const MobileModalBottom = styled.div`
    background-color: ${colors.lightgrey};
    padding: ${spacing.base}px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
`;

export const MobileModalButtonRow = styled(Row)`
    justify-content: flex-end;
`;

const MobileModal = ({ isOpen, close, title = '', children }) => {
    return (
        <ReactModal
            style={{ overlay: overlay, content: content }}
            isOpen={isOpen}
            onRequestClose={close}
            appElement={document.getElementById('root')}
            // Prevent scrolling on page when modal is open
            onAfterOpen={() => {
                //TODO: reset to previous x and y (for mobile view)
                document.body.style.overflow = 'hidden';
                document.body.setAttribute('style', 'position: fixed');
                // Needed to fix chrome bug on mobile where the viewport height and width are only
                // correct after re-render
                // setCount(count + 1);
            }}
            onAfterClose={() => document.body.removeAttribute('style')}
        >
            <>
                <Title>
                    <span>{title}</span>
                    {/*<span onClick={close} style={{ cursor: 'pointer' }}>X</span>*/}
                </Title>
                {children}
            </>
        </ReactModal>
    );
};

export default MobileModal;