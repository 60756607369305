import React, {useContext} from 'react';
import {StatsContext} from "../../../shared/context/StatsContext";
import CollapsablePart from "./CollapsablePart";
import {Table, Td, Th, THead, Tr} from "../../styles/Page";
import {Link} from "react-router-dom";
import {routes} from "../../../shared/routing/Routes";

const Forgotten = () => {
    const { forgottenTransactions, getForgottenTransactions } = useContext(StatsContext);

    const compareDates = (a, b) => {
        return a > b ? 1 : a < b ? -1 : 0;
    }

    return (
      <CollapsablePart
        buttonText={"Laat mogelijk vergeten kook-transacties zien"}
        getData={getForgottenTransactions}
      >
          <Table>
              <THead>
                  <Tr>
                      <Th>Persoon</Th>
                      <Th>Datum</Th>
                  </Tr>
              </THead>
              <tbody>
              {forgottenTransactions.sort((a, b) => compareDates(a.date, b.date)).map(t =>
                <Tr key={t.date}>
                    <Td>{t.cook.username}</Td>
                    <Td><Link to={`${routes.cookingCalender}/${t.date}`}>{t.date}</Link></Td>
                </Tr>
              )}
              </tbody>
          </Table>
      </CollapsablePart>
    );
};

export default Forgotten;