import React from 'react';
import styled from "styled-components";
import {spacing} from "../../util/Spacing";
import {colors} from "../../../util/Colors";
import {fontSizes} from "../../util/Fontsizes";
import {Button} from "@material-ui/core";
import {useHistory} from "react-router";
import {routes} from "../../../shared/routing/Routes";
import {FWCol} from "../../styles/Grid";
import CookingNextItem from "./CookingNextItem";

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex: 1;
    padding: ${spacing.base}px;
    background-color: ${colors.lightOrange};
`;

const Title = styled.div`
    color: ${colors.black};
    font-size: ${fontSizes.semiBig}px;
    font-weight: bold;
    margin-bottom: ${spacing.small}px;
`;

const ButtonWrapper = styled.div`
    margin-top: ${spacing.small}px;
`;

const compareBalance = (a, b) => {
    if (a.balance > b.balance) return -1;
    if (a.balance < b.balance) return 1;
    return 0;
};

const CookingNext = ({ overview = [] }) => {
    const history = useHistory();

    return (
        <Wrapper>
            <FWCol>
                <Title>Who should plan to cook next?</Title>
                {overview.sort(compareBalance).slice(0).slice(-3).map(x => (
                    <CookingNextItem
                        key={x.user.id}
                        imageId={x.user.image}
                        username={x.user.username}
                        balance={x.balance}
                    />
                ))}
                <ButtonWrapper>
                    <Button variant="contained" color="primary" style={{ color: colors.white, width: '100%' }} onClick={() => history.push(routes.cookingCalender)}>
                        <b>Cooking Calendar</b>
                    </Button>
                </ButtonWrapper>
            </FWCol>
        </Wrapper>
    );
};

export default CookingNext;