import React from "react";
import ModifyUserModal from "./ModifyUserModal";

const CreateUserModal = ({isOpen = false, closeAction = () => {}, createAction = () => {}}) => {
    const title = "Create User";

    const modifyAction = (formValues) => {
        createAction(formValues, closeAction);
    };

    const validateFormValues = (formValues) => {
        return formValues.username && formValues.password;
    };

    return (
        <ModifyUserModal
            title={title}
            isOpen={isOpen}
            closeAction={closeAction}
            modifyAction={modifyAction}
            validateFormValues={validateFormValues}
        />
    );
};

export default CreateUserModal;