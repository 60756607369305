import React from "react";
import {func} from "../../util/Helpers";
import {Backdrop, CircularProgress} from "@material-ui/core";

const Loading = ({ isOpen = false, close = func }) => {
    return (
        <Backdrop open={isOpen} onClick={close}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

export default React.memo(Loading);