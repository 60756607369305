import React from "react";
import {Select as MaterialSelect ,FormControl, InputLabel, MenuItem} from "@material-ui/core";
import {func} from "../../util/Helpers";

const defaultKeyExtractor = (x) => {
    return x['id'];
};

const defaultLabelExtractor = (x) => {
    return x['label'];
};

const Select = ({ title = '', value = null, onChange = func, data = [], keyExtractor = defaultKeyExtractor, labelExtractor = defaultLabelExtractor, includeNull = true }) => {
    return (
        <FormControl style={{ width: '100%' }}>
            <InputLabel id={`${title}-label`}>{title}</InputLabel>
            <MaterialSelect
                labelId={`${title}-label`}
                id={title}
                value={value}
                onChange={onChange}
            >
                {includeNull && (
                    <MenuItem value={null}>None</MenuItem>
                )}
                {data.map(x => (
                    <MenuItem key={keyExtractor(x)} value={keyExtractor(x)}>{labelExtractor(x)}</MenuItem>
                ))}
            </MaterialSelect>
        </FormControl>
    );
};

export default Select;