import {useParams} from "react-router-dom";

/**
 * Custom hook that returns an id from the url as a number
 * @returns {number}
 */
function useParamsId() {
    const {id} = useParams();

    return parseInt(id);
}

export default useParamsId;