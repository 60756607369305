import React, { createContext, useState, useContext } from 'react';
import {
    enrollForCooking, enrollForEating,
    getCookingEventForDate,
    getCookingEventForDates,
    unEnrollForCooking, updateCookingEventDryersRequest, updateCookingEventRequest
} from "../network/CookingRequests";
import {CookingBalanceContext} from "./CookingBalanceContext";
import {TransactionBalanceContext} from "./TransactionBalanceContext";
import {mergeArraysById} from "../util/ArrayUtils";
import {sortByDateAsc} from "../util/SortUtils";
import {dayjsYearFixer} from "../util/CookingEventUtils";

export const CookingEventContext = createContext({});

const CookingEventContextProvider = (props) => {
    const { getCookingBalanceOverview } = useContext(CookingBalanceContext);
    const { getMyTransactionBalance } = useContext(TransactionBalanceContext);
    const [cookingEvents, setCookingEvents] = useState([]);

    const getCookingEvent = async (date, onSuccess = () => {}) => {
        date = dayjsYearFixer(date);
        const data = await getCookingEventForDate(date);
        if (data) {
            setCookingEvents(mergeArraysById(cookingEvents, [data]).sort(sortByDateAsc));
            onSuccess(data);
        }
    };

    const getCookingEvents = async (from, to) => {
        from = dayjsYearFixer(from);
        to = dayjsYearFixer(to);
        const data = await getCookingEventForDates(from, to);
        if (data) {
            setCookingEvents(mergeArraysById(cookingEvents, data).sort(sortByDateAsc));
        }

        return data;
    };

    const setMeAsCook = async (cookingEventId) => {
        const data = await enrollForCooking(cookingEventId);
        if (data) {
            setCookingEvents(mergeArraysById(cookingEvents, [data]).sort(sortByDateAsc));
            getCookingBalanceOverview();
        }
    };

    const unSetMeAsCook = async (cookingEventId) => {
        const data = await unEnrollForCooking(cookingEventId);
        if (data) {
            setCookingEvents(mergeArraysById(cookingEvents, [data]).sort(sortByDateAsc));
            getCookingBalanceOverview();
            getMyTransactionBalance();
        }
    };

    const enroll = async (cookingEventId, amount) => {
        const result = await enrollForEating(cookingEventId, amount);
        if (result) {
            setCookingEvents(mergeArraysById(cookingEvents, [result]).sort(sortByDateAsc));
            getCookingBalanceOverview();
            // Update my balance if the cooking event has a transaction
            if (!!result.transaction) {
                getMyTransactionBalance();
            }
        }
    };

    const updateCookingEvent = async (cookingEventId, data) => {
        const result = await updateCookingEventRequest(cookingEventId, data);
        if (result) {
            setCookingEvents(mergeArraysById(cookingEvents, [result]).sort(sortByDateAsc));
            getCookingBalanceOverview();
            // Update my balance if the cooking event has a transaction
            if (!!result.transaction) {
                getMyTransactionBalance();
            }
        }
    }

    const updateCookingEventDryers = async (cookingEventId, data, onSuccess = () => {}) => {
        const result = await updateCookingEventDryersRequest(cookingEventId, data);
        if (result) {
            setCookingEvents(mergeArraysById(cookingEvents, [result]).sort(sortByDateAsc));
            onSuccess();
        }
    }

    return (
        <CookingEventContext.Provider value={{
            cookingEvents,
            getCookingEvent,
            getCookingEvents,
            setMeAsCook,
            unSetMeAsCook,
            enroll,
            updateCookingEvent,
            updateCookingEventDryers,
        }}
        >
            {props.children}
        </CookingEventContext.Provider>
    );
};

export default CookingEventContextProvider;
