import React, { createContext, useContext, useState } from 'react';
import {
    getTransactionsRequest,
    postCookingEventTransactionRequest,
    postTransactionRequest, putCookingEventTransactionRequest,
    putTransactionRequest
} from '../network/TransactionRequests';
import { TransactionBalanceContext } from './TransactionBalanceContext';
import {CookingBalanceContext} from "./CookingBalanceContext";
import {mergeArraysById} from "../util/ArrayUtils";
import {genericSortDesc, sortByDateAndIdDesc} from "../util/SortUtils";

export const TransactionContext = createContext({});

const initialPage = {
    content: [],
    empty: true,
    first: true,
    last: true,
    number: 0,
    numberOfElements: 0,
    pageable: {
        offset: 0,
        pageSize: 20,
        paged: true,
        sort: {
            empty: false,
            sorted: true,
            unsorted: false,
        },
        unpaged: false,
    },
    size: 0,
    sort: {
        empty: false,
        sorted: true,
        unsorted: false,
    },
    totalElements: 0,
    totalPages: 1,
};

const TransactionContextProvider = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [transactions, setTransactions] = useState(initialPage);

    const { getTransactionBalanceOverview } = useContext(TransactionBalanceContext);
    const { getMyCookingBalance } = useContext(CookingBalanceContext);

    const getTransactions = async (page = 0) => {
        setIsLoading(true);
        const result = await getTransactionsRequest(page);
        if (result) {
            setTransactions({
                ...result,
                content: mergeArraysById(transactions.content, result.content).sort(sortByDateAndIdDesc),
            });
        }
        setIsLoading(false);
    };

    const createTransaction = async (data, onSuccess = (newId) => {}) => {
        setIsLoading(true);
        const result = await postTransactionRequest(data);
        if (result) {
            setTransactions({ ...transactions, content: [result, ...transactions.content].sort((a,b) => genericSortDesc(a,b,'id')) });
            getTransactionBalanceOverview(true);
            onSuccess(result.id);
        }
        setIsLoading(false);
    };

    const createTransactionForCookingEvent = async (cookingEventId, price, onSuccess = (newId) => {}) => {
        setIsLoading(true);
        const result = await postCookingEventTransactionRequest(cookingEventId, price);
        if (result) {
            setTransactions({ ...transactions, content: [result, ...transactions.content].sort((a,b) => genericSortDesc(a,b,'id')) });
            getTransactionBalanceOverview(true);
            onSuccess(result.id);
        }
        setIsLoading(false);
    };

    const editTransaction = async (id, data, recalculateCookingPoints = false, onSuccess = () => {}) => {
        setIsLoading(true);
        const result = await putTransactionRequest(id, data);
        if (result) {
            setTransactions({
                ...transactions,
                content: mergeArraysById(transactions.content, [result]).sort((a,b) => genericSortDesc(a,b,'id')),
            });
            getTransactionBalanceOverview(true);
            if (recalculateCookingPoints) {
                getMyCookingBalance();
            }
            onSuccess();
        }
        setIsLoading(false);
    };

    const editTransactionForCookingEvent = async (cookingEventId, price, onSuccess = (newId) => {}) => {
        setIsLoading(true);
        const result = await putCookingEventTransactionRequest(cookingEventId, price);
        if (result) {
            setTransactions({
                ...transactions,
                content: mergeArraysById(transactions.content, [result]).sort((a,b) => genericSortDesc(a,b,'id')),
            });
            getTransactionBalanceOverview(true);
            onSuccess(result.id);
        }
        setIsLoading(false);
    };

    return (
        <TransactionContext.Provider value={{
            isLoading,
            transactions,
            getTransactions,
            createTransaction,
            createTransactionForCookingEvent,
            editTransaction,
            editTransactionForCookingEvent
        }}>
            {props.children}
        </TransactionContext.Provider>
    );
};

export default TransactionContextProvider;
