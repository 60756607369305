import React from "react";
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import MainBodyImage from '../images/main/main_body.png';
import MainTopImage from '../images/main/main_top.png';
import MainBottomImage from '../images/main/main_bottom.png';
import {Title} from "../styles/Layout";
import {capitalizeFirstChar} from "../../util/Helpers";

const MainContainer = styled.div`
    width:504px;
    margin-left: 16px;
    margin-right: 16px;
`;

const MainTop = styled.div`
    height: 26px;
    background-image: url(${MainTopImage});
`;

const MainBody = styled.div`
    min-height: 291px;
    padding: 7px;
    padding-top: 0px;
    padding-bottom: 3px;
    background-image: url(${MainBodyImage});
`;

const MainBottom = styled.div`
    height: 4px;
    background-image: url(${MainBottomImage});
`;

const MainPanelWrapper = (props) => {
    const location = useLocation();

    const constructLabelFromUrl = () => {
        let pathName = location.pathname;
        let parts = pathName.split('/');
        return parts[1] ? capitalizeFirstChar(parts[1]).replaceAll('-', ' ') : 'Startpagina'
    };

    return (
        <MainContainer>
            <MainTop>
                <Title>
                    {constructLabelFromUrl()}
                </Title>
            </MainTop>
            <MainBody>
                {props.children}
            </MainBody>
            <MainBottom />
        </MainContainer>
    );
};

export default MainPanelWrapper;