import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {Col, Row} from "../../../styles/Grid";
import {spacing} from "../../../util/Spacing";
import MobileModal, {MobileModalBottom, MobileModalButtonRow, MobileModalContent} from "../../modal/MobileModal";
import MaterialInput from "../../../styles/MarterialInput";
import Button from "../../../styles/Button";
import {func} from "../../../../util/Helpers";
import {AlbumContext} from "../../../../shared/context/AlbumsContext";

const CustomRow = styled(Row)`
    padding: ${spacing.base}px;
`;

const initialState = {
    name: '',
};

const CreateAlbumModal = ({ isOpen = false, close = func, title = '' }) => {

    const [ formValues, setFormValues ] = useState(initialState);

    const { createAlbum } = useContext(AlbumContext);

    const setName = (input) => {
        setFormValues({
            ...formValues,
            name: input,
        });
    };

    const handleSubmit = () => {
        createAlbum(formValues, () => close());
    };

    useEffect(() => {
        if (!isOpen) {
            setFormValues(initialState);
        }
    }, [isOpen]);

    return (
        <MobileModal
            isOpen={isOpen}
            close={close}
            title={title}
        >
            <MobileModalContent>
                <Col>
                    <CustomRow>
                        <MaterialInput title={'Name'} value={formValues.name} onChange={e => setName(e.target.value)} />
                    </CustomRow>
                </Col>
            </MobileModalContent>
            <MobileModalBottom>
                <MobileModalButtonRow>
                    <Button text={'Cancel'} color={'secondary'} onClick={close} style={{ marginRight: spacing.base }} />
                    <Button text={'Save'} onClick={handleSubmit} />
                </MobileModalButtonRow>
            </MobileModalBottom>
        </MobileModal>
    );
};

export default React.memo(CreateAlbumModal);