import React from "react";
import styled from 'styled-components';
import TableHeader from '../../../images/cookingSchedule/table_header.png'

const Header = styled.div`
    background-image: url(${TableHeader});
    background-repeat: no-repeat;
    width: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    display: flex;
    align-items: center;
    height: 18px;
`;

const Day = styled.div`
    font: 100% Verdana;
    white-space: nowrap;
    font-weight: bold;
    color: white;
    font-size: 0.60em;
    width: 14.28%;
    text-align: center;
`;

const CookingTableHeader = () => {
    return (
        <Header>
            <Day>Maandag</Day>
            <Day>Dinsdag</Day>
            <Day>Woensdag</Day>
            <Day>Donderdag</Day>
            <Day>Vrijdag</Day>
            <Day>Zaterdag</Day>
            <Day>Zondag</Day>
        </Header>
    );
};

export default CookingTableHeader;