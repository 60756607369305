export const colors = {
    white: '#FFFFFF',
    black: '#222222',

    lightgrey: '#efefef',

    borderLightGrey: '#ebebeb',

    textDarkGrey: '#666666',
    textLightGrey: '#dddddd',

    orange: '#f8941d',
    darkOrange: '#d97a06',
    lightOrange: '#faaf56',

    disabledButton: '#807f7f',
}