import React from "react";
import {Arrow, ArrowsWrapper, CheckBox, CheckBoxWrapper, Name} from "../../../styles/TransactionForm";


const TransactionEnrollmentCell = ({ userId, username, amountEnrolled, canEdit, enroll }) => {

    const getNameLabel = () => {
        if (amountEnrolled > 1) {
            return `${username}(${amountEnrolled})`;
        }
        return username;
    };

    const onArrowDownClick = () => {
        if (canEdit && amountEnrolled - 1 > -1) {
            enroll(userId, amountEnrolled - 1);
        }
    };

    const onArrowUpClick = () => {
        canEdit && enroll(userId, amountEnrolled + 1);
    };

    const onCheckboxClick = () => {
        canEdit && enroll(userId, amountEnrolled > 0 ? 0 : 1);
    };

    return (
        <React.Fragment>
            {canEdit && <ArrowsWrapper>
                <React.Fragment>
                    <Arrow up={false} onClick={onArrowDownClick}/>
                    <Arrow up={true} onClick={onArrowUpClick}/>
                </React.Fragment>
            </ArrowsWrapper>}
            <CheckBoxWrapper>
                <CheckBox
                    checked={amountEnrolled > 0}
                    onClick={onCheckboxClick}
                    disabled={!canEdit}
                />
            </CheckBoxWrapper>
            <Name>
                {getNameLabel()}
            </Name>
        </React.Fragment>
    );
};

export default TransactionEnrollmentCell;