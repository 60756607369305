import React, {useContext, useEffect, useState} from "react";
import MobilePageWrapper from "../MobilePageWrapper";
import {routes} from "../../../shared/routing/Routes";
import ActionBar from "../ActionBar";
import {MeContext} from "../../../shared/context/MeContext";
import {BottomNavigation, BottomNavigationAction, Paper} from "@material-ui/core";
import MobileForgottenTransactionsTable from "./MobileForgottenTransactionsTable";
import Loading from "../../styles/Loading";
import DryerCountTable from "./DryerCountTable";
import MobileAveragePrices from "./MobileAveragePrices";

const MobileStatsPage = () => {

    const [ currentTab, setCurrentTab ] = useState(0);

    const { me, loadMe } = useContext(MeContext);

    const isLoading = me.id === -1;

    useEffect(() => {
        loadMe();
    }, []);

    return (
        <MobilePageWrapper>
            <ActionBar activeRoute={routes.stats}>

                <Loading isOpen={isLoading} />

                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={1}>
                    <BottomNavigation
                        showLabels
                        value={currentTab}
                        onChange={(event, newValue) => setCurrentTab(newValue)}
                    >
                        <BottomNavigationAction label="Forgotten Transactions" />
                        <BottomNavigationAction label="Average Prices" />
                        <BottomNavigationAction label="Dryer Count" />
                    </BottomNavigation>
                </Paper>

                {!isLoading && currentTab === 0 && (
                    <MobileForgottenTransactionsTable />
                )}

                {!isLoading && currentTab === 1 && (
                    <MobileAveragePrices />
                )}
                {!isLoading && currentTab === 2 && (
                    <DryerCountTable />
                )}

            </ActionBar>
        </MobilePageWrapper>
    );
};

export default MobileStatsPage;