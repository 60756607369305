import {constants} from "../../util/Constants";
import {cacheValue} from "../util/CachingUtils";
import Axios from "axios";
import {defaultAxiosErrorHandler} from "../util/RequestUtils";

const controllerPath = 'images';

export const getImageRequest = (id) => {
    // Use fetch so we can cache the value
    return fetch(new Request(`${constants.apiBaseUrl}/${controllerPath}/${id}`), {
        headers: {
            'Authorization': 'Bearer ' + window.localStorage.getItem(constants.localstorageJwtKey)
        },
    })
        .then(res => {
            cacheValue(constants.imageCacheName, res.url, res.clone()).then()
            return res.blob();
        })
        .catch(e => alert(`error: ${e}, message: ${e.response.data.message}`))
};

export const postImageRequest = (image, albumId) => {
    const formData = new FormData();

    formData.append('file', image);

    const client = Axios.create({
        baseURL: constants.apiBaseUrl,
    });
    const headers = {
        'Content-Type': 'multipart/form-data',
    };
    return client.post(`/${controllerPath}?album=${albumId}`, formData, headers)
        .then((response) => response.data)
        .catch(defaultAxiosErrorHandler);
};

export const deleteImageRequest = (id) => Axios({
    method: 'delete',
    url: `${constants.apiBaseUrl}/${controllerPath}/${id}`,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);