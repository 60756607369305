import Axios from "axios";
import {constants} from "../../../../util/Constants";
import {defaultAxiosErrorHandler} from "../../../util/RequestUtils";

const controllerPath = 'room-solicitors';

export const getRoomSolicitorsRequest = (page, size = 20) => {
    let url = `${constants.apiBaseUrl}/${controllerPath}?sort=id,desc&size=${size}`;
    if (page) {
        url += `&page=${page}`;
    }

    return Axios({
        method: 'get',
        url,
    }).then((response) => response.data).catch(defaultAxiosErrorHandler);
};

export const getRoomSolicitorByIdRequest = (id) => Axios({
    method: 'get',
    url: `${constants.apiBaseUrl}/${controllerPath}/${id}`,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const postRoomSolicitorRequest = (data) => Axios({
    method: 'post',
    url: `${constants.apiBaseUrl}/${controllerPath}`,
    data,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const putRoomSolicitorRequest = (id, data) => Axios({
    method: 'put',
    url: `${constants.apiBaseUrl}/${controllerPath}/${id}`,
    data,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const deleteRoomSolicitorRequest = (id) => Axios({
    method: 'delete',
    url: `${constants.apiBaseUrl}/${controllerPath}/${id}`,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);
