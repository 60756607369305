import React, {useEffect, useRef, useState} from "react";
import {useHistory} from "react-router";
import {CentsInput, CustomRow, DescriptionInput, EurosInput, Wrapper} from "../../../styles/TransactionForm";

const TransactionEnrollmentPriceForm = ({ transaction, editTransaction = () => {} }) => {

    const centsRef = useRef();
    const history = useHistory();
    const { id, description, amount } = transaction;

    const [formValues, setFormValues] = useState({
        description: 'nieuw',
        euros: '0',
        cents: '00'
    });

    useEffect(() => {
        const parts = amount.toFixed(2).split('.');
        setFormValues({
            description: description,
            euros: parts[0],
            cents: !parts[1] ? '00' : parts[1]
        });
    }, [description, amount]);

    const handleDescriptionChange = (event) => {
        setFormValues({ ...formValues, description: event.target.value});
    };

    const handleEurosChange = (event) => {
        setFormValues({...formValues, euros: event.target.value})
    };

    const handleCentsChange = (event) => {
        if (event.target.value.length < 3) {
            setFormValues({...formValues, cents: event.target.value})
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        } else if (event.key === '.' || event.key === ',') {
            event.preventDefault();  // This is so we don't have a '.' or ',' in the input
            centsRef.current.select();
        }
    };

    const handleSubmit = () => {
        const price = parseFloat(`${formValues.euros}.${formValues.cents}`);

        if (!id || id === -1) {
            alert('Please select at least one user');
            return;
        }
        if (isNaN(price)) {
            alert('Please enter a valid price');
            return;
        }

        const data = {
            description: formValues.description,
            price: price,
            enrolled: transaction.enrollments.map((x) => ({
                user: x.user.id,
                amount: x.amount,
            }))
        };

        editTransaction(id, data, goToNewTransaction);
    };

    const goToNewTransaction = (newId) => {
        history.push(`/geldlijst/${newId}`);
    };

    return (
        <Wrapper>
            <CustomRow>
                <DescriptionInput
                    type={'text'}
                    value={formValues.description}
                    onChange={handleDescriptionChange}
                    onKeyDown={handleKeyDown}
                />
                &euro;
                <EurosInput
                    type={'text'}
                    value={formValues.euros}
                    onChange={handleEurosChange}
                    onKeyDown={handleKeyDown}
                />
                ,
                <CentsInput
                    type={'text'}
                    value={formValues.cents}
                    onChange={handleCentsChange}
                    onKeyDown={handleKeyDown}
                    ref={centsRef}
                />
                <button
                    type={'button'}
                    onClick={handleSubmit}
                >
                    Ok
                </button>
            </CustomRow>
        </Wrapper>
    );
};

export default TransactionEnrollmentPriceForm;