import React, {useContext, useEffect} from "react";
import {StatsContext} from "../../../shared/context/StatsContext";
import {TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {Table} from "../../../desktop/styles/Page";
import {Link} from "react-router-dom";
import {routes} from "../../../shared/routing/Routes";

const MobileForgottenTransactionsTable = () => {

    const { forgottenTransactions, getForgottenTransactions } = useContext(StatsContext);

    useEffect(() => {
        if (forgottenTransactions.length === 0) {
            getForgottenTransactions();
        }
    }, []);

    return (
        <>
            <TableContainer component={'div'}>
                <Table aria-label="forgotten transactions table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>User</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {forgottenTransactions.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={2}>No data available</TableCell>
                            </TableRow>
                        )}
                        {forgottenTransactions.map((x) => (
                            <TableRow key={x.date} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell>{x.cook.username}</TableCell>
                                <TableCell>
                                    <Link to={`${routes.cookingCalenderDetail}/${x.date}`}>{x.date}</Link>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default MobileForgottenTransactionsTable;