import React from "react";
import MobileModal, {MobileModalBottom, MobileModalButtonRow, MobileModalContent} from "../modal/MobileModal";
import {func} from "../../../util/Helpers";
import Button from "../../styles/Button";
import {colors} from "../../../util/Colors";
import styled from "styled-components";
import {F1, F2, Row} from "../../styles/Grid";
import {spacing} from "../../util/Spacing";
import {fontSizes} from "../../util/Fontsizes";
import {getEnrolledAmountByUserId} from "../../../shared/util/CookingEventUtils";
import {formatBalance} from "../../util/Utils";

const StyledRow = styled(Row)`
    padding: ${spacing.base}px;
    padding-left: ${2 * spacing.base}px;
    
    font-size: ${fontSizes.base}px;
    
    border-bottom: 1px solid ${colors.borderLightGrey};
`;

const InfoRow = styled(Row)`
    font-size: ${fontSizes.base}px;
    margin-bottom: ${spacing.base}px;
`;

const InfoLabel = styled(F1)`
    font-weight: bold;
`;

const TransactionViewModal = ({ isOpen = false, close = func, currentTransaction, users = [] }) => {

    if (!currentTransaction) {
        return <></>;
    }

    return (
        <MobileModal
            isOpen={isOpen}
            close={close}
            title={'View transaction'}
        >
            <MobileModalContent>
                {users.map(user => (
                    <StyledRow key={user.id}>
                        {user.username} ({getEnrolledAmountByUserId(currentTransaction.enrollments, user.id)})
                    </StyledRow>
                ))}
            </MobileModalContent>
            <MobileModalBottom>
                <InfoRow>
                    <InfoLabel>Owner:</InfoLabel>
                    <F2>{currentTransaction.owner.username}</F2>
                </InfoRow>
                <InfoRow>
                    <InfoLabel>Description:</InfoLabel>
                    <F2>{currentTransaction.description}</F2>
                </InfoRow>
                <InfoRow>
                    <InfoLabel>Price:</InfoLabel>
                    <F2>{formatBalance(currentTransaction.amount)}</F2>
                </InfoRow>
                <MobileModalButtonRow>
                    <Button text={'Close'} onClick={close} />
                </MobileModalButtonRow>
            </MobileModalBottom>
        </MobileModal>
    );
};

export default TransactionViewModal;