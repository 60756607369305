import React, {useContext, useEffect, useState} from "react";
import MobileModal, {MobileModalBottom, MobileModalButtonRow, MobileModalContent} from "../../modal/MobileModal";
import Button from "../../../styles/Button";
import styled from "styled-components";
import {Col, Row} from "../../../styles/Grid";
import {spacing} from "../../../util/Spacing";
import {TransactionContext} from "../../../../shared/context/TransactionContext";
import {useHistory} from "react-router";
import {func} from "../../../../util/Helpers";
import MaterialInput from "../../../styles/MarterialInput";

const CustomRow = styled(Row)`
    padding: ${spacing.base}px;
`;

const initialState = {
    price: '',
};

const CookingTransactionModal = ({ isOpen = false, close = func, title = '', eventId = -1, price = 0 }) => {

    const history = useHistory();

    const [ formValues, setFormValues ] = useState(initialState);

    const { createTransactionForCookingEvent, editTransactionForCookingEvent } = useContext(TransactionContext);

    // If the price is 0 or null then we should create a new transaction
    const createMode = !price;

    const setPrice = (input) => {
        setFormValues({
            ...formValues,
            price: input.replace(',', '.')
        });
    };

    useEffect(() => {
        if (price > 0) {
            setFormValues({
                ...formValues,
                price: price.toFixed(2).toString()
            });
        }
    }, [price]);

    const handleSubmit = () => {
        const number = parseFloat(formValues.price);
        if (number > 0) {
            createMode
                ? createTransactionForCookingEvent(eventId, number, goToNewTransaction)
                : editTransactionForCookingEvent(eventId, number, goToNewTransaction);
        }
    };

    const goToNewTransaction = (newId) => {
        history.push(`/geldlijst/${newId}`);
    };

    if (!isOpen) {
        return <></>;
    }

    return (
        <MobileModal
            isOpen={isOpen}
            close={close}
            title={title}
        >
            <MobileModalContent>
                <Col>
                    <CustomRow>
                        <MaterialInput title={'Price'} value={formValues.price} onChange={e => setPrice(e.target.value)} />
                    </CustomRow>
                </Col>
            </MobileModalContent>
            <MobileModalBottom>
                <MobileModalButtonRow>
                    <Button text={'Cancel'} color={'secondary'} onClick={close} style={{ marginRight: spacing.base }} />
                    <Button text={'Save'} onClick={handleSubmit} />
                </MobileModalButtonRow>
            </MobileModalBottom>
        </MobileModal>
    );
};

export default React.memo(CookingTransactionModal);