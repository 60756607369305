import React, {createContext, useState} from "react";
import {createAlbumRequest, deleteAlbumRequest, getAlbumRequest, getAllAlbumsRequest} from "../network/AlbumsRequests";
import {mergeArraysById} from "../util/ArrayUtils";
import {func} from "../../util/Helpers";
import {deleteImageRequest, postImageRequest} from "../network/ImageRequests";

export const AlbumContext = createContext({});

const AlbumContextProvider = (props) => {

    const [albums, setAlbums] = useState([]);

    const loadAlbums = async () => {
        const result = await getAllAlbumsRequest();
        if (result) {
            setAlbums(result);
        }
    };

    const loadAlbum = async (id) => {
        const result = await getAlbumRequest(id);
        if (result) {
            setAlbums(mergeArraysById(albums, [result]));
        }
    };

    const createAlbum = async (data, onSuccess = func) => {
        const result = await createAlbumRequest(data);
        if (result) {
            setAlbums(mergeArraysById(albums, [result]));
            onSuccess();
        }
    };

    const uploadImage = async (image, albumId, onSuccess = func) => {
        const result = await postImageRequest(image, albumId);
        if (result) {
            setAlbums(albums.map(album => album.id !== albumId
                ? album
                : { ...album, images: mergeArraysById(album.images, [result])}
            ));
            onSuccess();
        }
    };

    const deleteImage = async (albumId, imageId, onSuccess = func) => {
        const result = await deleteImageRequest(imageId);
        // A no content successful request returns an empty string
        if (result === '') {
            setAlbums(albums.map(album => album.id !== albumId
                ? album
                : { ...album, images: album.images.filter(image => image.id !== imageId)}
            ));
            onSuccess();
        }
    };

    const deleteAlbum = async (id, onSuccess = func) => {
        const result = await deleteAlbumRequest(id);
        // A no content successful request returns an empty string
        if (result === '') {
            setAlbums(albums.filter(x => x.id !== id));
            onSuccess();
        }
    }

    return (
        <AlbumContext.Provider value={{ albums, loadAlbums, loadAlbum, createAlbum, uploadImage, deleteImage, deleteAlbum }}>
            {props.children}
        </AlbumContext.Provider>
    );
};

export default AlbumContextProvider;

