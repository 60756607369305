import React from "react";
import {PanelBody, PanelBottom, PanelContainer, PanelTop, Half} from "../../styles/Panel";
import {Title} from "../../styles/Layout";

const CookingPointsOverviewPanel = ({ balanceOverview = [] }) => {
    return (
        <PanelContainer>
            <PanelTop>
                <Title>Overzicht</Title>
            </PanelTop>
            <PanelBody>
                {balanceOverview.map((x) => (
                    <React.Fragment key={x.user.id}>
                        <Half>{x.user.username}</Half>
                        <Half>{x.balance}</Half>
                    </React.Fragment>
                ))}
            </PanelBody>
            <PanelBottom />
        </PanelContainer>
    );
}

export default CookingPointsOverviewPanel;