import Axios from "axios";
import {constants} from "../../util/Constants";
import {defaultAxiosErrorHandler} from "../util/RequestUtils";

export const getTransactionBalanceOverviewRequest = (activeOnly) => Axios({
    method: 'get',
    url: `${constants.apiBaseUrl}/transactions/balance?activeOnly=${activeOnly}`,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const getMyTransactionBalanceRequest = () => Axios({
    method: 'get',
    url: `${constants.apiBaseUrl}/transactions/balance/me`,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const getTransactionsRequest = (page) => {
    let url = `${constants.apiBaseUrl}/transactions?sort=date,desc&sort=id,desc`;
    if (page) {
        url += `&page=${page}`;
    }

    return Axios({
        method: 'get',
        url,
    }).then((response) => response.data).catch(defaultAxiosErrorHandler);
};

export const postTransactionRequest = (data) => Axios({
    method: 'post',
    url: `${constants.apiBaseUrl}/transactions`,
    data,
}).then((response) => response.data).catch((error) => {
    alert(`error: ${error}, message: ${error.response ? error.response.data.message : 'cannot get response from server'}`);
});

export const postCookingEventTransactionRequest = (cookingEventId, price) => Axios({
    method: 'post',
    url: `${constants.apiBaseUrl}/transactions/cooking-event/${cookingEventId}`,
    data: {
        price: price
    }
}).then((response) => response.data).catch((error) => {
    alert(`error: ${error}, message: ${error.response ? error.response.data.message : 'cannot get response from server'}`);
});

export const putTransactionRequest = (id, data) => Axios({
    method: 'put',
    url: `${constants.apiBaseUrl}/transactions/${id}`,
    data,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const putCookingEventTransactionRequest = (cookingEventId, price) => Axios({
    method: 'put',
    url: `${constants.apiBaseUrl}/transactions/cooking-event/${cookingEventId}`,
    data: {
        price: price
    }
}).then((response) => response.data).catch((error) => {
    alert(`error: ${error}, message: ${error.response ? error.response.data.message : 'cannot get response from server'}`);
});