import React from 'react';
import styled from "styled-components";
import {Row, W50} from "../../../../styles/Layout";

const CustomRow = styled(Row)`
    margin-bottom: 5px;
`;

export const INITIAL_SOLICITOR_FORM_STATE = {
    name: '',
    email: '',
    studentYear: '',
    age: '',
    study: '',
    remarks: '',
};

const RoomSolicitorForm = ({ formState, setFormState }) => {
    return (
        <>
            <CustomRow>
                <W50>Naam</W50>
                <W50><input value={formState.name} onChange={(e) => setFormState({ ...formState, name: e.target.value })} /></W50>
            </CustomRow>
            <CustomRow>
                <W50>Leeftijd</W50>
                <W50><input value={formState.age} onChange={(e) => setFormState({ ...formState, age: e.target.value })} /></W50>
            </CustomRow>
            <CustomRow>
                <W50>StudieJaar</W50>
                <W50><input value={formState.studentYear} onChange={(e) => setFormState({ ...formState, studentYear: e.target.value })} /></W50>
            </CustomRow>
            <CustomRow>
                <W50>Studie</W50>
                <W50><input value={formState.study} onChange={(e) => setFormState({ ...formState, study: e.target.value })} /></W50>
            </CustomRow>
            <CustomRow>
                <W50>Email</W50>
                <W50><input value={formState.email} onChange={(e) => setFormState({ ...formState, email: e.target.value })} /></W50>
            </CustomRow>
            <CustomRow>
                <W50>Opmerkingen</W50>
                <W50>
                    <textarea
                        rows={3}
                        placeholder={"Optioneel"}
                        value={formState.remarks}
                        onChange={(e) => setFormState({ ...formState, remarks: e.target.value })}
                    />
                </W50>
            </CustomRow>
        </>
    );
};

export default RoomSolicitorForm;
