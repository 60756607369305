import React, {useContext, useEffect} from "react";
import PageWrapper from "../PageWrapper";
import {MeContext} from "../../../shared/context/MeContext";

const HomePage = () => {
    const { loadMe } = useContext(MeContext);

    useEffect(() => {
        loadMe();
    }, [])

    return (
        <PageWrapper />
    );
};

export default HomePage;