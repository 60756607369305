import React from "react";
import {PanelBody, PanelBottom, PanelContainer, PanelTop} from "../../styles/Panel";
import {Title} from "../../styles/Layout";
import Amount from "../../styles/Amount";


const shouldNotUpdate = (prevProps, nextProps) => {
    return prevProps.cookingPoints === nextProps.cookingPoints;
}

const formatCookingPoints = (points = 0) => {
    if (isNaN(points)) {
        points = 0;
    }
    return points <= 0 ? points : `+${points}`;
};

const MyCookingPointsPanel = ({cookingPoints = 0}) => {
    return (
        <PanelContainer>
            <PanelTop>
                <Title>Kookpunten</Title>
            </PanelTop>
            <PanelBody>
                <Amount>{formatCookingPoints(cookingPoints)}</Amount>
            </PanelBody>
            <PanelBottom />
        </PanelContainer>
    );

}

export default React.memo(MyCookingPointsPanel, shouldNotUpdate);