import React, {useContext, useEffect, useState} from "react";
import PageWrapper from "../PageWrapper";
import styled from "styled-components";
import {AlbumContext} from "../../../shared/context/AlbumsContext";
import {MeContext} from "../../../shared/context/MeContext";
import SortableTable from "../common/SortableTable";
import {Td, Tr} from "../../styles/Page";
import {func, isAdmin} from "../../../util/Helpers";
import CreateAlbumModal from "./modal/CreateAlbumModal";
import {routes} from "../../../shared/routing/Routes";
import {Link} from "react-router-dom";

const Page = styled.div`
    padding: 10px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 10px;
`;

const headers = [
    {
        name: 'Laatst gewijzigd',
        sortable: true,
        sortKey: 'lastEdited',
    },
    {
        name: 'Naam',
        sortable: true,
        sortKey: 'name',
    },
];

const produceRow = (album = {}, isAdmin = false, deleteAlbum = func) => {
    const showEmptyActionTd = isAdmin && !album.editable;
    const showActionTd = isAdmin && album.editable;
    return (
        <Tr key={album.id}>
            <Td>
                {album.lastEdited}
            </Td>
            <Td>
                <Link to={`${routes.albums}/${album.id}`}>{album.name}</Link>
            </Td>
            {showEmptyActionTd && (<Td />)}
            {showActionTd && (<Td><span onClick={() => deleteAlbum(album.id)} style={{ cursor: 'pointer' }} title={'Verwijder'}>X</span></Td>)}
        </Tr>
    );
};

const AlbumsPage = () => {

    const [ showCreateModal, setShowCreateModal ] = useState(false);

    const { me, loadMe } = useContext(MeContext);
    const { albums, loadAlbums, deleteAlbum } = useContext(AlbumContext);

    useEffect(() => {
        loadMe();
        loadAlbums();
    }, []);

    const meIsAdmin = isAdmin(me);

    return (
        <PageWrapper>
            <Page>
                <SortableTable
                    headers={headers}
                    content={albums}
                    convertRow={item => produceRow(item, meIsAdmin, deleteAlbum)}
                    editable={meIsAdmin}
                />
                {meIsAdmin && (
                    <ButtonWrapper>
                        <button type={'button'} onClick={() => setShowCreateModal(true)}>Nieuw</button>
                    </ButtonWrapper>
                )}

                <CreateAlbumModal
                    isOpen={showCreateModal}
                    setIsOpen={setShowCreateModal}
                />

            </Page>
        </PageWrapper>
    );
};

export default AlbumsPage;