import React, {createContext, useState} from 'react';
import {createBeerTypeRequest, getAllBeerTypesRequest, updateBeerTypeRequest} from "../network/BeerTypeRequests";
import {genericSortAsc} from "../util/SortUtils";

export const BeerTypeContext = createContext({});

const BeerTypeContextProvider = (props) => {
    const [beerTypes, setBeerTypes] = useState([]);

    const getAllBeerTypes = async (onSuccess = () => {}) => {
        const result = await getAllBeerTypesRequest();
        if (result) {
            result.sort((a,b) => genericSortAsc(a,b,'id'));
            setBeerTypes(result);
            onSuccess(result[0]?.id);
        }
    };

    const createBeerType = async (data) => {
        const result = await createBeerTypeRequest(data);
        if (result) {
            setBeerTypes([...beerTypes, result])
        }
    };

    const updateBeerType = async (id, data) => {
        const result = await updateBeerTypeRequest(id, data);
        if (result) {
            await getAllBeerTypes();
        }
    }

    return (
        <BeerTypeContext.Provider value={{
            beerTypes,
            getAllBeerTypes,
            createBeerType,
            updateBeerType,
        }}
        >
            {props.children}
        </BeerTypeContext.Provider>
    );
}

export default BeerTypeContextProvider;