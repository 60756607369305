import React from "react";
import useTransactionForm from "../../hooks/TransactionFormHook";
import MobileModal, {MobileModalBottom, MobileModalButtonRow, MobileModalContent} from "../modal/MobileModal";
import {func} from "../../../util/Helpers";
import Button from "../../styles/Button";
import MobileModalEnrollments from "../modal/MobileModalEnrollments";
import {spacing} from "../../util/Spacing";
import MaterialInput from "../../styles/MarterialInput";

const TransactionActionModal = ({ isOpen = false, close = func, currentTransaction, users }) => {

    const { values, changeAmountForUser, changeDescription, changePrice, handleSubmit } = useTransactionForm(currentTransaction);

    return (
        <MobileModal
            isOpen={isOpen}
            close={close}
            title={`${!currentTransaction ? 'Add' : 'Edit'} transaction`}
        >
            <MobileModalContent>
                <MobileModalEnrollments
                    users={users}
                    enrollments={values.enrolled}
                    onChangeClick={(userId, change) => changeAmountForUser(userId, change)}
                />
            </MobileModalContent>
            <MobileModalBottom>
                <MaterialInput title={'Description'} value={values.description} onChange={(e) => changeDescription(e.target.value)} />
                <MaterialInput title={'Price'} value={values.price} onChange={(e) => changePrice(e.target.value)} />
                <MobileModalButtonRow style={{ marginTop: spacing.base }}>
                    <Button text={'Cancel'} color={'secondary'} onClick={close} style={{ marginRight: spacing.base }} />
                    <Button text={'Save'} onClick={() => handleSubmit(close)} />
                </MobileModalButtonRow>
            </MobileModalBottom>
        </MobileModal>
    )

};

export default React.memo(TransactionActionModal);