import useParamsId from "./UseParamsId";
import {useContext, useEffect, useMemo, useRef, useState} from "react";
import {MeContext} from "../context/MeContext";
import {AlbumContext} from "../context/AlbumsContext";
import {constants} from "../../util/Constants";

function useAlbumDetail() {

    const id = useParamsId()
    const imageInputRef = useRef(null);

    const [ showImageViewer, setShowImageViewer ] = useState(false);
    const [ currentImage, setCurrentImage ] = useState(null);

    const { me, loadMe } = useContext(MeContext);
    const { albums, loadAlbum, uploadImage, deleteImage } = useContext(AlbumContext);

    const album = useMemo(() => albums.find(x => x.id === id), [albums, id]);

    // Load initial data
    useEffect(() => {
        loadMe();
        loadAlbum(id);
    }, []);

    const handleItemClick = (id, index) => {
        setCurrentImage(index);
        setShowImageViewer(true);
    };

    const deleteItem = (event, imageId) => {
        event.stopPropagation();
        deleteImage(album?.id, imageId);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            uploadImage(file, album?.id);
        }
    };

    const imageUrls = useMemo(() => {
        const list = album?.images?.map(x => ({
            src: `${constants.apiBaseUrl}/images/${x.id}?token=${localStorage.getItem(constants.localstorageJwtKey)}`,
            caption: x.id
        }));
        return list || [];
    }, [album?.images?.length]);

    return {
        id,
        imageInputRef,
        showImageViewer,
        setShowImageViewer,
        currentImage,
        setCurrentImage,
        me,
        album,
        handleItemClick,
        deleteItem,
        handleFileChange,
        imageUrls
    };

}

export default useAlbumDetail;
