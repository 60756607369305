import React, {useEffect, useState} from "react";
import Modal from 'react-modal';
import styled from "styled-components";
import {Row} from "../../../styles/Layout";
import {func} from "../../../../util/Helpers";

Modal.setAppElement('#root');

const Page = styled.div`
    width: 300px;
`;

const Title = styled.div`
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid black;
`;

const Content = styled.div`
    margin-top: 10px;
`;

const CustomRow = styled(Row)`
    margin-bottom: 5px;
    justify-content: space-between;
`;

const ButtonWrapper = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};

const initialFormValues = {
    username: "",
    password: "0000",
    roles: ["USER", "FEUT"],
    active: true
};

const possibleRoles = [
    "USER", "ADMIN", "BEER_SCALE_ADMIN", "FEUT"
];

const ModifyUserModal = ({isOpen = false, closeAction = func, user = null, modifyAction = func, title = "", validateFormValues = func}) => {

    const [formValues, setFormValues] = useState(initialFormValues);

    useEffect(() => {
        if (user?.id) {
            setFormValues({
                username: user.username,
                roles: [...user.roles],
                active: user.active,
                password: "",
            });
        }
    }, [user?.id]);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (validateFormValues(formValues)) {
            modifyAction(formValues);
        }
    };

    const toggleRole = (role) => {
        if (formValues.roles) {
            let newRoles = formValues.roles;
            if (newRoles.includes(role))
                newRoles.splice(formValues.roles.indexOf(role), 1);
            else
                newRoles.push(role);

            setFormValues({...formValues, roles: newRoles})
        }
    };

    const buildRoleInputs = () => {
        return (
            <CustomRow>
                {possibleRoles.map((role, i) => (
                    <span key={i}>
                        {role.toLowerCase()}
                        <input
                            type={'checkbox'}
                            checked={formValues.roles && formValues.roles.includes(role)}
                            onChange={() => toggleRole(role)}
                        />
                    </span>
                ))}
            </CustomRow>
        )
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeAction}
            style={customStyles}
            contentLabel={title}
            overlayClassName="Overlay"
        >
            <Page>
                <Title>
                    {title}
                </Title>
                <Content>
                    <form onSubmit={handleSubmit}>
                        <CustomRow>
                            <label>Username</label>
                            <input
                                id={'username'}
                                type={'text'}
                                value={formValues.username}
                                onChange={(event => setFormValues({...formValues, username: event.target.value}))}
                            />
                        </CustomRow>
                        {buildRoleInputs()}
                        <CustomRow>
                            Active
                            <input
                                type={'checkbox'}
                                checked={formValues.active}
                                onChange={(() => setFormValues({...formValues, active: !formValues.active}))}
                            />
                        </CustomRow>
                        <CustomRow>
                            New Password
                            <input
                                type={'text'}
                                value={formValues.password}
                                onChange={(event => setFormValues({...formValues, password: event.target.value}))}
                            />
                        </CustomRow>
                        <ButtonWrapper>
                            <button
                                type={'button'}
                                onClick={closeAction}
                                >
                                Cancel
                            </button>
                            <input
                                type={'submit'}
                                value={'Save'}
                                disabled={!validateFormValues(formValues)}
                            />
                        </ButtonWrapper>
                    </form>
                </Content>
            </Page>
        </Modal>
    );
};

export default ModifyUserModal;
