import React from "react";
import styled from "styled-components";
import {colors} from "../../util/Colors";
import {spacing} from "../util/Spacing";
import {fontSizes} from "../util/Fontsizes";
import MenuIcon from "../images/menu.svg";
import Logo from "../images/dirty_a_logo_transparant.svg";
import {formatBalance, formatCookingPoints} from "../util/Utils";
import {mobileConstants} from "../util/Constants";

const Bar = styled.div`
    background-color: ${colors.darkOrange};
    height: ${mobileConstants.actionBarHeight}px;
    
    padding-left: ${spacing.base};
    padding-right: ${spacing.base};
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1;
`;

const MenuIconWrapper = styled.div`
    width: 33%;
    height: 100%;
`;

const LogoWrapper = styled.div`
    width: 34%;
    height: 100%;
    display: flex;
    justify-content: center;
`;

const BalanceTextWrapper = styled.div`
    width: 33%;
    display: flex;
    justify-content: flex-end;
`;

const BalanceText = styled.span`
    color: ${colors.lightOrange};
    font-size: ${fontSizes.base}px;
    font-weight: bold;
    margin-right: ${spacing.small}px;
`;

const getBalanceText = (transactionBalance = 0, cookingBalance = 0) => {
    return `${formatBalance(transactionBalance)} | ${formatCookingPoints(cookingBalance)}`
};

const ActionBarBar = ({ transactionBalance = 0, cookingBalance = 0, balanceText = '', onMenuClick = () => {} }) => {
    return (
        <Bar>
            <MenuIconWrapper>
                <img src={MenuIcon} alt={''} style={{ height: '100%', cursor: 'pointer' }} onClick={onMenuClick} />
            </MenuIconWrapper>
            <LogoWrapper>
                <img src={Logo} alt={''} style={{ height: 'calc(100% - 6px)', paddingBottom: '3px', paddingTop: '3px' }} />
            </LogoWrapper>
            <BalanceTextWrapper>
                <BalanceText>
                    {balanceText || getBalanceText(transactionBalance, cookingBalance)}
                </BalanceText>
            </BalanceTextWrapper>
        </Bar>
    );
};

export default React.memo(ActionBarBar);