import React from "react";
import MobileModal, {MobileModalBottom, MobileModalButtonRow, MobileModalContent} from "../../modal/MobileModal";
import Button from "../../../styles/Button";
import useCookingActions from "../../../../shared/hooks/CookingActionsHook";
import {formatTimestamp, func} from "../../../../util/Helpers";
import {fontSizes} from "../../../util/Fontsizes";
import styled from "styled-components";
import {Row} from "../../../styles/Grid";
import {spacing} from "../../../util/Spacing";

const StyledRow = styled(Row)`
    padding: ${spacing.small}px;
    padding-top: ${spacing.base}px;
    font-size: ${fontSizes.base}px;
`;

const ActionsModal = ({ isOpen = false, close = func, title = '', eventId = -1 }) => {

    const { actions } = useCookingActions(eventId);

    if (!isOpen) {
        return <></>;
    }

    return (
        <MobileModal
            isOpen={isOpen}
            close={close}
            title={title}
        >
            <MobileModalContent style={{ minHeight: '50vh' }}>
                {actions.map(x => (
                    <StyledRow key={x.id}>
                        {formatTimestamp(x.timestamp).substr(5)} - {x.message}
                    </StyledRow>
                ))}
            </MobileModalContent>
            <MobileModalBottom>
                <MobileModalButtonRow>
                    <Button text={'Close'} onClick={close} />
                </MobileModalButtonRow>
            </MobileModalBottom>
        </MobileModal>
    );
};

export default React.memo(ActionsModal);