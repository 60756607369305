import React from "react";
import styled from "styled-components";
import {colors} from "../../util/Colors";
import {FWCol} from "./Grid";
import {fontSizes} from "../util/Fontsizes";
import {spacing} from "../util/Spacing";

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex: 1;
    background-color: ${colors.white};
`;

export const SmallListTitle = styled.div`
    color: ${colors.black};
    font-size: ${fontSizes.semiBig}px;
    font-weight: bold;
    margin-top: ${spacing.base}px;
    margin-bottom: ${spacing.small}px;
    padding-left: ${spacing.base}px;
    padding-right: ${spacing.base}px;
`;

/**
 * Component that can be used to create a list with title that is not meant to fill the entire page
 */
const SmallList = ({ title = '', data = [], renderItem = () => {}, wrapperStyle = {}, titleStyle = {}, children }) => {
    return (
        <Wrapper style={wrapperStyle}>
            <FWCol>
                <SmallListTitle style={titleStyle}>{title}</SmallListTitle>
                {data.map((x, i) => renderItem(x, i))}
                {children}
            </FWCol>
        </Wrapper>
    )
};

export default SmallList;