import Axios from "axios";
import {constants} from "../../util/Constants";
import {defaultAxiosErrorHandler} from "../util/RequestUtils";

export const getCookingBalanceOverviewRequest = (activeOnly) => Axios({
    method: 'get',
    url: `${constants.apiBaseUrl}/cooking-events/balance?activeOnly=${activeOnly}`,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const getMyCookingBalanceRequest = () => Axios({
    method: 'get',
    url: `${constants.apiBaseUrl}/cooking-events/balance/me`,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const getCookingEventActionsRequest = (id = -1) => Axios({
    method: 'get',
    url: `${constants.apiBaseUrl}/cooking-events/${id}/actions`,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const getCookingEventDryerSuggestionsRequest = (id = -1) => Axios({
    method: 'get',
    url: `${constants.apiBaseUrl}/cooking-events/${id}/dryers/suggestion`,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const enrollForCooking = (cookingEventId) => Axios({
    method: 'put',
    url: `${constants.apiBaseUrl}/cooking-events/${cookingEventId}/enroll/cook`,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const unEnrollForCooking = (cookingEventId) => Axios({
    method: 'put',
    url: `${constants.apiBaseUrl}/cooking-events/${cookingEventId}/un-enroll/cook`,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const enrollForEating = (cookingEventId, amount) => Axios({
    method: 'put',
    url: `${constants.apiBaseUrl}/cooking-events/${cookingEventId}/enroll?amount=${amount}`,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const getCookingEventForDate = (date) => Axios({
    method: 'get',
    url: `${constants.apiBaseUrl}/cooking-events/get-or-create?date=${date}`,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const getCookingEventForDates = (from, to) => Axios({
    method: 'get',
    url: `${constants.apiBaseUrl}/cooking-events/get-or-create/range?from=${from}&to=${to}`,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const updateCookingEventRequest = (cookingEventId, data) => Axios({
    method: 'put',
    url: `${constants.apiBaseUrl}/cooking-events/${cookingEventId}`,
    data: data,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const updateCookingEventDryersRequest = (cookingEventId, data) => Axios({
    method: 'put',
    url: `${constants.apiBaseUrl}/cooking-events/${cookingEventId}/dryers`,
    data: data,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);