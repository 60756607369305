import React, {useContext, useEffect, useMemo, useState} from 'react';
import {RoomSolicitorReviewContext} from "../../../../../shared/context/roomsoliciting/RoomSolicitorReviewContext";
import {Td, Tr} from "../../../../styles/Page";
import DefaultTable from "../../../common/DefaultTable";
import {Row} from "../../../../styles/Layout";
import {MeContext} from "../../../../../shared/context/MeContext";

const INITIAL_FORM_STATE = {
    review: '',
    score: '',
};

const headers = [
    {
        name: 'User',
    },
    {
        name: 'Review',
    },
    {
        name: 'Score',
    },
];

const RoomSolicitorReviews = ({ solicitorId }) => {

    const [ formState, setFormState ] = useState(INITIAL_FORM_STATE);

    const { me } = useContext(MeContext);
    const { reviews, getReviews, createReview, updateReview } = useContext(RoomSolicitorReviewContext);

    useEffect(() => {
        if (solicitorId) {
            getReviews(solicitorId);
        }
    }, [ solicitorId ]);

    const myReview = useMemo(() => reviews.find(x => x.user.id === me?.id), [ me?.id, reviews ]);

    useEffect(() => {
        if (myReview) {
            setFormState({
                review: myReview.review,
                score: myReview.score,
            });
        }
    }, [ myReview ]);

    const produceRow = (x) => {
        return (
            <Tr key={x.id}>
                <Td>{x.user.username}</Td>
                <Td>{x.review}</Td>
                <Td>{x.score}</Td>
            </Tr>
        );
    };

    const handleSubmit = () => {
        const data = {
            solicitor: solicitorId,
            review: formState.review,
            score: formState.score,
        };
        if (myReview === undefined) {
            createReview(data);
        } else {
            updateReview(myReview.id, data);
        }
    };

    return (
        <>
            <Row style={{ marginBottom: 5 }}>
                <input
                    placeholder={'Review'}
                    style={{ width: '100%' }}
                    value={formState.review}
                    onChange={(e) => setFormState({ ...formState, review: e.target.value })}
                />
                <input
                    type={'number'}
                    placeholder={'Score'}
                    style={{ width: 50 }}
                    value={formState.score}
                    onChange={(e) => setFormState({ ...formState, score: e.target.value })}
                />
                <button type={'button'} onClick={handleSubmit}>{myReview === undefined ? 'Create' : 'Update'}</button>
            </Row>
            <DefaultTable
                headers={headers}
                convertRow={produceRow}
                content={reviews}
                wrapperProps={{ height: 'auto' }}
            />
        </>
    );
};

export default RoomSolicitorReviews;
