import Axios from "axios";
import {constants} from "../../util/Constants";
import {defaultAxiosErrorHandler} from "../util/RequestUtils";

const controllerPath = 'albums';

export const getAllAlbumsRequest = () => Axios({
    method: 'get',
    url: `${constants.apiBaseUrl}/${controllerPath}`,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const getAlbumRequest = (id) => Axios({
    method: 'get',
    url: `${constants.apiBaseUrl}/${controllerPath}/${id}`,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const createAlbumRequest = (data) => Axios({
    method: 'post',
    url: `${constants.apiBaseUrl}/${controllerPath}`,
    data: data
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const deleteAlbumRequest = (id) => Axios({
    method: 'delete',
    url: `${constants.apiBaseUrl}/${controllerPath}/${id}`,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);