import React from "react";
import styled from "styled-components";
import {getKeyFromIds} from "../../../../util/Helpers";
import TransactionEnrollmentRow from "./TransactionEnrollmentRow";

const FormWrapper = styled.div`
    margin-top: 10px;
`;

const nrOfColumns = 3;

const TransactionEnrollmentForm = ({me, users, transaction, enroll, allUsers, multipleEnroll}) => {

    const nrOfRows = Math.ceil(users.length / nrOfColumns);

    const getRowsArray = () => {
        // Initialise arrays
        const userRows = [];
        const enrollmentRows = [];
        for (let i=0; i<nrOfRows; i++) {
            userRows.push([]);
            enrollmentRows.push([]);
        }
        // Set base grid values
        let currentCol = 0;
        let currentRow = 0;
        // Loop through all users
        for (let i=0; i<users.length && currentCol < nrOfColumns; i++) {
            // Get user and its enrollment
            const user = users[i];
            const enrollment = transaction.enrollments.find((x) => x.user.id === user.id);
            // Set data in grid
            userRows[currentRow].push(users[i]);
            enrollmentRows[currentRow].push(enrollment);
            // Go to next row and maybe next column
            if (currentRow + 1 === nrOfRows) {
                currentCol += 1;
            }
            currentRow = ((currentRow + 1) % nrOfRows);
        }

        const result = [];
        for (let i=0; i<userRows.length; i++) {
            const users = userRows[i];
            const enrollments = enrollmentRows[i];
            result.push(
                <TransactionEnrollmentRow
                    key={getKeyFromIds(users)}
                    users={users}
                    me={me}
                    enrolledArray={enrollments}
                    transaction={transaction}
                    enroll={enroll}
                    allUsers={allUsers}
                    multipleEnroll={multipleEnroll}
                />
            );
        }
        return result;
    }

    return (
        <React.Fragment>
            <FormWrapper>
                {getRowsArray()}
            </FormWrapper>
        </React.Fragment>
    );
};

export default TransactionEnrollmentForm;