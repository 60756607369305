import React from "react";
import styled from "styled-components";
import {ListDate, ListItem} from "../../styles/List";
import {Col} from "../../styles/Grid";
import {formatBalance} from "../../util/Utils";
import {fontSizes} from "../../util/Fontsizes";
import {colors} from "../../../util/Colors";

const ItemInfoWrapper = styled(Col)`
    justify-content: space-between;
    flex-grow: 1;
`;

const ItemTitle = styled.div`
    font-size: ${fontSizes.semiBig}px;
    font-weight: bold;
    
    max-width: 50vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const ItemAmount = styled.div`
    font-size: ${fontSizes.base}px;
`;

const ItemOwner = styled.div`
    font-size: ${fontSizes.base}px;
    color: ${colors.textLightGrey};
`;

const ItemAmountForMe = styled(Col)`
    justify-content: center;
    align-items: center;
`;

const TransactionListItem = ({ date = '', description = '', amount = 0, owner = '', priceForMe = 0 }) => {
    return (
        <ListItem>
            <ListDate date={date} />
            <ItemInfoWrapper>
                <Col>
                    <ItemTitle>{description}</ItemTitle>
                    <ItemAmount>{formatBalance(amount)}</ItemAmount>
                </Col>
                <ItemOwner>{owner}</ItemOwner>
            </ItemInfoWrapper>
            <ItemAmountForMe>
                {formatBalance(priceForMe)}
            </ItemAmountForMe>
        </ListItem>
    );
};

export default React.memo(TransactionListItem);