import React, { useContext } from "react";
import styled from "styled-components";
import {UsersContext} from "../../../../shared/context/UsersContext";
import {RoomSolicitingDayContext} from "../../../../shared/context/roomsoliciting/RoomSolicitingDayContext";
import {AVAILABILITY_TYPE} from "../../../../shared/util/enum/AvailabilityType";

const Container = styled.div`
    margin: 5px;
`;

const getAvailabilityForUser = (day, userId) => {
    const availability = day?.availabilities.find(x => x.user.id === userId);

    if (!availability || availability.availabilityType === AVAILABILITY_TYPE.NOT_AVAILABLE) {
        return 'Nee';
    }

    return 'Ja';
};

const getRemarkForUser = (day, userId) => {
    const availability = day?.availabilities.find(x => x.user.id === userId);

    if (!availability) {
        return '';
    }

    return availability.remarks;
}

const RoomSolicitingDayAvailability = ({ selectedDate }) => {

    const { users } = useContext(UsersContext);
    const { days } = useContext(RoomSolicitingDayContext);

    const day = days.find(x => x.date === selectedDate);

    return (
        <Container>
            <table>
                <tbody>
                    {users.map(x => (
                        <tr key={x.id}>
                            <td>
                                {x.username}
                            </td>
                            <td style={{ paddingLeft: 10 }}>
                                {getAvailabilityForUser(day, x.id)}
                            </td>
                            <td style={{ paddingLeft: 10 }}>
                                {getRemarkForUser(day, x.id)}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Container>
    );
};

export default RoomSolicitingDayAvailability;