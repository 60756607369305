import React, {useContext, useEffect, useState} from "react";
import {Line} from "react-chartjs-2";
import {uniqueUsers} from "../../util/Helpers";
import styled from "styled-components";import {Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js';
import {StatsContext} from "../context/StatsContext";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

const Options = styled.div`
    display: flex;
    justify-content: space-evenly;
`

const colors = [
    '#ef0029',
    '#377eb8',
    '#66a61e',
    '#984ea3',
    '#00d2d5',
    '#ff7f00',
    '#af8d00',
    '#7f80cd',
    '#b3e900',
    '#c42e60',
    '#a65628',
    '#f781bf',
    '#8dd3c7',
    '#fb8072',
];

const defaultData = {
    labels: [],
    datasets: [],
};

const defaultOptions = {
    responsive: true,
    scales: {
        y: {
            beginAtZero: true,
        },
    },
    plugins: {
        legend: {
            position: 'top',
        },
        tooltip: {
            enabled: true,
            interaction: {
                mode: 'index',
            },
            xAlign: 'right',
        },
    },
};

const customOptions = {
    plugins: {
        tooltip: {
            enabled: true,
            interaction: {
                mode: 'index',
            },
            intersect: true,
        },
    },
};

const AveragePricesPerMonthChart = () => {

    const { averageCostsPerMonth, getAverageCostsPerMonth } = useContext(StatsContext);

    const [data, setData] = useState(defaultData);

    const [allMonths, setAllMonths] = useState([]);
    const [visibleMonths, setVisibleMonths] = useState([]);

    const [startMonth, setStartMonth] = useState("");
    const [endMonth, setEndMonth] = useState("");

    const options = {
        ...defaultOptions,
        ...customOptions,
    };

    useEffect(() => {
        getAverageCostsPerMonth();
    }, []);

    useEffect(() => {
        const months = [...new Set(averageCostsPerMonth.map(x => x.month))].sort();
        setAllMonths(months);
        setStartMonth(months[0]);
        setEndMonth(months[months.length - 1]);
    }, [averageCostsPerMonth]);

    useEffect(() => {
        let months = allMonths;
        if (startMonth)
            months = months.filter(month => month >= startMonth);
        if (endMonth)
            months = months.filter(month => month <= endMonth);

        setVisibleMonths(months);
    }, [startMonth, endMonth, allMonths]);

    useEffect(() => {
        const users = uniqueUsers(averageCostsPerMonth.filter(x => visibleMonths.includes(x.month)).map(x => x.user))
        const getDataOfUser = (user, color) => {
            const data = visibleMonths.map(month => {
                const dataPoint = averageCostsPerMonth.find(x => x.user.id === user.id && x.month === month);
                return dataPoint ? dataPoint.price : null;
            });

            return {label: user.username, data, backgroundColor: color}
        }

        const datasets = users.map((u, i) => getDataOfUser(u, colors[i]));

        setData({labels: visibleMonths, datasets});
    }, [visibleMonths, averageCostsPerMonth]);

    const handleStart = (event) => {
        setStartMonth(event.target.value);
    };

    const handleEnd = (event) => {
        setEndMonth(event.target.value);
    };

    return (
        <>
            <Line
                options={options}
                data={data}
                type={'line'}
            />
            <Options>
                <label>
                    Start
                    &nbsp;
                    <select value={startMonth} onChange={(e) => handleStart(e)}>
                        {allMonths.map(month => (<option key={month} value={month}>{month}</option>))}
                    </select>
                </label>
                <label>
                    End
                    &nbsp;
                    <select value={endMonth} onChange={(e) => handleEnd(e)}>
                        {allMonths.map(month => (<option key={month} value={month}>{month}</option>))}
                    </select>
                </label>
            </Options>
        </>
    )
}

export default AveragePricesPerMonthChart;
