import React from "react";
import dayjs from "dayjs";
import TransactionTableBodyCell from "./TransactionTableBodyCell";
import styled from "styled-components";

const Row = styled.tr`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    
    background-color: #c7dbee;
    :nth-child(even) {
        background-color: #a1c5de;
    }
`;

const TransactionTableInputRow = ({active = false, amountOfUsers = 0}) => {
    const width = (470 - (39 + 64 + (amountOfUsers * 3) + 17)) / amountOfUsers;

    const getEmptyCells = () => {
        const result = [];
        for (let i=0; i<amountOfUsers; i++) {
            result.push(<TransactionTableBodyCell key={i} content={''} width={width} />)
        }
        return result;
    }

    return (
        <Row>
            <TransactionTableBodyCell width={37} type={'date'} content={dayjs().toISOString().split('T')[0]} active={active} />
            <TransactionTableBodyCell width={61} type={'description'} content={'[nieuw]'} active={active}/>
            {getEmptyCells()}
        </Row>
    );
};

export default TransactionTableInputRow;
