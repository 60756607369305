import React from "react";
import {Redirect} from "react-router-dom";
import {constants} from "../../util/Constants";

const LogoutHandler = () => {
    window.localStorage.removeItem(constants.localstorageJwtKey)
    return (
        <Redirect to="/login" />
    );
};

export default LogoutHandler;