import React, {useCallback, useContext, useEffect, useState} from "react";
import styled from "styled-components";
import MobilePageWrapper from "../MobilePageWrapper";
import ActionBar from "../ActionBar";
import {routes} from "../../../shared/routing/Routes";
import List from "../../styles/List";
import {TransactionContext} from "../../../shared/context/TransactionContext";
import {MeContext} from "../../../shared/context/MeContext";
import {UsersContext} from "../../../shared/context/UsersContext";
import {CookingBalanceContext} from "../../../shared/context/CookingBalanceContext";
import {TransactionBalanceContext} from "../../../shared/context/TransactionBalanceContext";
import {Col} from "../../styles/Grid";
import TransactionListItem from "./TransactionListItem";
import Fab from "../../styles/Fab";
import TransactionActionModal from "./TransactionActionModal";
import TransactionViewModal from "./TransactionViewModal";
import Loading from "../../styles/Loading";

const Wrapper = styled(Col)`
    height: 100%;
`;

const calculatePriceForMe = (transaction, me) => {
    let price = 0;
    if (transaction.owner.id === me.id) {
        price += transaction.amount;
    }
    const enrollment = transaction.enrollments.find((x) => x.user.id === me.id);
    if (enrollment) {
        price += enrollment.price;
    }
    return price;
};

const MobileTransactionPage = () => {

    const [ currentTransaction, setCurrentTransaction ] = useState(null);
    const [ showViewModal, setShowViewModal ] = useState(false);
    const [ showActionModal, setShowActionModal ] = useState(false);

    const { me, loadMe } = useContext(MeContext);
    const { users, loadUsers } = useContext(UsersContext);

    const { getMyCookingBalance } = useContext(CookingBalanceContext);
    const { getMyTransactionBalance } = useContext(TransactionBalanceContext);

    const { isLoading, transactions, getTransactions } = useContext(TransactionContext);

    useEffect(() => {
        loadMe();
        loadUsers();
        getTransactions();
    }, []);

    useEffect(() => {
        if (me.id !== -1) {
            getMyCookingBalance();
            getMyTransactionBalance();
        }
    }, [me.id]);

    const getNewPage = () => {
        if (!transactions.last) {
            getTransactions(transactions.number + 1);
        }
    };

    const handleItemClick = (transaction) => {
        setCurrentTransaction(transaction);
        if (transaction.owner.id === me.id) {
            setShowActionModal(true);
        } else {
            setShowViewModal(true)
        }
    };

    const handleCreateClick = () => {
        setCurrentTransaction(null);
        setShowActionModal(true);
    };

    const closeActionModal = useCallback(() => {
        setShowActionModal(false);
    }, []);

    const closeViewModal = useCallback(() => {
        setShowViewModal(false);
    }, []);

    return (
        <MobilePageWrapper>
            <ActionBar activeRoute={routes.moneyList}>
                <Wrapper>
                    <List
                        data={transactions?.content}
                        renderItem={(item) => (
                            <div key={item.id} onClick={() => handleItemClick(item)} style={{ cursor: 'pointer' }}>
                                <TransactionListItem
                                    date={item.date}
                                    description={item.description}
                                    amount={item.amount}
                                    owner={item.owner.username}
                                    priceForMe={calculatePriceForMe(item, me)}
                                />
                            </div>
                        )}
                        onEndReached={getNewPage}
                    />
                    <Fab text={'+'} onClick={handleCreateClick} />
                    <TransactionActionModal
                        isOpen={showActionModal}
                        close={closeActionModal}
                        currentTransaction={currentTransaction}
                        users={users}
                    />
                    <TransactionViewModal
                        isOpen={showViewModal}
                        close={closeViewModal}
                        currentTransaction={currentTransaction}
                        users={users}
                    />
                    <Loading isOpen={isLoading} />
                </Wrapper>
            </ActionBar>
        </MobilePageWrapper>
    );
};

export default MobileTransactionPage;