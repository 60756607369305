import styled from "styled-components";

const ButtonWrapper = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid white;
    outline: 1px solid #F8941D;
    background-color: #F8941D;
`;

export default ButtonWrapper;
