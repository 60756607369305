import React, {useContext, useEffect, useState} from "react";
import {Row} from "../../../styles/Layout";
import styled from "styled-components";
import {RoomSolicitingDayContext} from "../../../../shared/context/roomsoliciting/RoomSolicitingDayContext";
import {MeContext} from "../../../../shared/context/MeContext";
import {
    postRoomSolicitingAvailabilityRequest,
    putRoomSolicitingAvailabilityRequest
} from "../../../../shared/network/roomsoliciting/availability/RoomSolicitingAvailabilityRequests";
import {AVAILABILITY_TYPE} from "../../../../shared/util/enum/AvailabilityType";

const Container = styled.div`
    margin: 5px;
`;

const RoomSolicitingDayAvailabilityForm = ({ selectedDate }) => {

    const [ availabilityValue, setAvailabilityValue ] = useState(AVAILABILITY_TYPE.FULLY_AVAILABLE);
    const [ remarksValue, setRemarksValue ] = useState('');

    const { days, getDay } = useContext(RoomSolicitingDayContext);
    const { me } = useContext(MeContext);

    const day = days.find(x => x.date === selectedDate);

    const availability = day?.availabilities.find(x => x.user.id === me.id);

    useEffect(() => {
        if (availability) {
            setAvailabilityValue(availability.availabilityType);
            setRemarksValue(availability.remarks);
        } else {
            setAvailabilityValue(AVAILABILITY_TYPE.FULLY_AVAILABLE);
            setRemarksValue('');
        }
    }, [ availability ])

    const handleAvailabilityChange = (e) => {
        setAvailabilityValue(e.target.value);
    }

    const handleRemarksChange = (e) => {
        setRemarksValue(e.target.value);
    }

    const handleSubmit = () => {
        const data = {
            day: day?.id,
            availabilityType: availabilityValue,
            remarks: remarksValue
        };
        if (!availability) {
            postRoomSolicitingAvailabilityRequest(data).then(() => getDay(selectedDate));
        } else {
            putRoomSolicitingAvailabilityRequest(availability.id, data).then(() => getDay(selectedDate));
        }
    }

    return (
        <Container>
            <Row>
                <select value={availabilityValue} onChange={handleAvailabilityChange}>
                    <option value={AVAILABILITY_TYPE.FULLY_AVAILABLE}>Beschikbaar</option>
                    <option value={AVAILABILITY_TYPE.NOT_AVAILABLE}>Niet Beschikbaar</option>
                </select>
                <input type={'text'} placeholder={'Opmerkingen'} style={{ width: '100%' }} value={remarksValue} onChange={handleRemarksChange} />
                <button type={'button'} onClick={handleSubmit}>Ok</button>
            </Row>
        </Container>
    );
};

export default RoomSolicitingDayAvailabilityForm;
