import React from "react";
import styled from 'styled-components';
import BeerListTableBodyCell from "./BeerListTableBodyCell";

const Row = styled.tr`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    
    background-color: #c7dbee;
    :nth-child(even) {
        background-color: #a1c5de;
    }
`;

const getBeerInputTypeText = (beerInputType) => {
    switch (beerInputType) {
        case 'LIST':
            return 'Bierlijst';
        case 'RECEIPT':
            return 'Bonnetje';
        default:
            return 'Error';
    }
}

const getBeerInputNote = (note) => {
    let result = '';
    if (note) {
        result += ` - ${note}`;
    }
    return result;
}

const BeerListTableBodyRow = ({users = [], beerInput, setShowViewModal = () => {}, setSelectedBeerInput = () => {}}) => {

    const click = (beerInput) => {
        setShowViewModal(true);
        setSelectedBeerInput(beerInput);
    }

    const getCells = () => {
        const width = (470 - (39 + 64 + (users.length * 3) + 17)) / users.length;

        let amounts = [];
        users.forEach((user, i) => {
            let enrollment = beerInput.enrollments.find(x => x.user.id === user.id);
            let amount = '';
            if (enrollment) {
                amount = enrollment.amount;
                if (beerInput.type === 'LIST') {
                    amount = amount * -1;
                }
            }
            amounts.push(<BeerListTableBodyCell width={width} type={'amount'} key={i} content={amount} />);
        });

        return (
            <React.Fragment>
                <BeerListTableBodyCell width={37} type={'date'} content={beerInput.date}/>
                <BeerListTableBodyCell width={61} type={'description'} content={`${getBeerInputTypeText(beerInput.type)}${getBeerInputNote(beerInput.note)}`} handleOnClick={() => click(beerInput)} />
                {amounts}
            </React.Fragment>
        );
    };

    return beerInput ? (
        <Row>
            {getCells()}
        </Row>
    ) : <></>;
};

export default BeerListTableBodyRow;
