import React from "react";
import dayjs from "dayjs";
import styled from 'styled-components';

const Base = styled.td`
    height: 11px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 1px;
    padding-right: 1px;
    white-space: nowrap;
    border-left: 1px solid white;
    font-family: Verdana;
    font-size: 0.55em;
    ${props => props.active && 'font-weight: bold;'}
    overflow: hidden;
    
    width: ${props => props.width}px;
`;

const Date = styled(Base)`
    text-align: center;
    border: none;
`;

const Description = styled(Base)`
    text-align: left;
    cursor: pointer;
`;

const Amount = styled(Base)`
    color: ${props => props.amount >= 0 ? 'darkgreen' : 'red'};
    text-align: right;
`;

const formatPrice = (price) => {
    const number = parseFloat(price);
    return isNaN(number) ? '' : number;
};

const BeerListTableBodyCell = ({content, type, width = 0, handleOnClick = () => {}}) => {

    const getCellContent = () => {
        switch (type) {
            case 'date':
                const formattedDate = dayjs(content).format('DD/MM');
                return <Date title={content} width={width}>{formattedDate}</Date>;
            case 'description':
                return <Description title={content} width={width} onClick={handleOnClick}>{content}</Description>;
            default:
                return (
                    <Amount width={width} amount={content}>
                        {formatPrice(content)}
                    </Amount>
                );
        }
    };

    return content !== undefined ? getCellContent() : <React.Fragment />;
};

export default BeerListTableBodyCell;
