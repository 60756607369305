import React, {useContext} from "react";
import SortableTable from "../common/SortableTable";
import {Td, Tr} from "../../styles/Page";
import {StatsContext} from "../../../shared/context/StatsContext";
import CollapsablePart from "./CollapsablePart";

const AveragePrices = () => {
    const { averageCosts, getAverageCosts } = useContext(StatsContext);

    const buildHeaders = () => {
        return [
            {name: 'Persoon', sortable: false},
            {name: 'Gemiddelde kookprijs', sortable: true, sortKey: 'price'},
            {name: 'σ', sortable: true, sortKey: 'stdDev'},
            {name: 'Aantal kookbeurten', sortable: true, sortKey: 'amount'},
        ];
    };

    const convertRow = (row) => {
        return (
            <Tr key={row.user.id}>
                <Td>{row.user.username}</Td>
                <Td>€{row.price.toFixed(2)}</Td>
                <Td>{row.stdDev.toFixed(2)}</Td>
                <Td>{row.amount}</Td>
            </Tr>
        );
    };

    return (
      <CollapsablePart
        buttonText={"Laat gemiddelde prijzen zien"}
        getData={getAverageCosts}
      >
          <SortableTable
            headers={buildHeaders()}
            content={averageCosts}
            convertRow={convertRow}
            editable={false}
          />
      </CollapsablePart>
    );
};

export default AveragePrices;
