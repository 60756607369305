import React from "react";
import styled from "styled-components";
import useJwt from "../../shared/hooks/JwtHook";
import useShowNotifications from "../../shared/hooks/ShowNotificationsHook";

const Page = styled.div`
    height: 100vh;
    width: 100vw;
    
    font-family: Roboto;
`;

const MobilePageWrapper = ({ children }) => {
    const { jwt } = useJwt();

    // Load notifications if a jwt is found
    useShowNotifications(jwt);

    return (
        <Page>
            {children}
        </Page>
    );
};

export default MobilePageWrapper;