import React from "react";
import styled from "styled-components";
import CookingEnrollmentCell from "./CookingEnrollmentCell";

const RowWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

const Col = styled.div`
    display: flex;
    flex-direction: row;
    width: 33%;
`;

const getAmountEnrolled = (enrollments, userId) => {
    return enrollments.find((x) => x?.user.id === userId)?.amount;
}

const CookingEnrollmentRow = ({ users, enrollments, myId, meIsCook, isPast, enroll }) => {
    return (
        <RowWrapper>
            {users?.map((x) => (
                <Col key={x.id}>
                    <CookingEnrollmentCell
                        amountEnrolled={getAmountEnrolled(enrollments, x.id)}
                        userId={x.id}
                        username={x.username}
                        isMyEnrollment={x.id === myId}
                        meIsCook={meIsCook}
                        isPast={isPast}
                        enroll={enroll}
                    />
                </Col>
            ))}
        </RowWrapper>
    );
}

export default CookingEnrollmentRow;