import React from "react";
import styled from "styled-components";
import RoundImage from "./RoundImage";

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

const RoundImageRow = ({ imageIds = [], amounts = [], imageWidth, imageBorderColor }) => {
    return (
        <Wrapper>
            {imageIds.map((x, i) => (
                <div key={i} style={{ marginRight: -imageWidth / 4 }}>
                    <RoundImage imageId={x} amount={amounts[i]} width={imageWidth} borderColor={imageBorderColor} />
                </div>
            ))}
        </Wrapper>
    );
};

export default RoundImageRow;