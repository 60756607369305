import React, {useContext, useEffect} from "react";
import PageWrapper from "../PageWrapper";
import styled from "styled-components";
import {MeContext} from "../../../shared/context/MeContext";
import {CookingBalanceContext} from "../../../shared/context/CookingBalanceContext";
import {TransactionBalanceContext} from "../../../shared/context/TransactionBalanceContext";
import MyCookingPointsPanel from "../panel/MyCookingPointsPanel";
import MyBalancePanel from "../panel/MyBalancePanel";
import CookingPointsOverviewPanel from "../panel/CookingPointsOverviewPanel";
import {UsersContext} from "../../../shared/context/UsersContext";
import {CookingDonationContext} from "../../../shared/context/CookingDonationContext";
import {getMyTransactionBalanceFromOverview} from "../../../shared/util/TransactionUtils";
import useCookingDonationForm from "../../../shared/hooks/CookingDonationFormHook";
import DefaultTable from "../common/DefaultTable";
import {Page, Td, Tr} from "../../styles/Page";


const ButtonWrapper = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

const Input = styled.input`
    margin-left: 5px;
    margin-right: 3px;
`;

const AmountInput = styled(Input)`
    width: 100px;
`;

const Select = styled.select`
    margin-left: 5px;
    margin-right: 3px;
`;

const RecipientSelect = styled(Select)`
    width: 100px;
`;

const headers = [
    {
        name: 'Datum',
        width: 80,
    },
    {
        name: 'Van',
    },
    {
        name: 'Naar',
    },
    {
        name: 'Hoeveelheid',
        width: 80,
    },
];

const produceRow = (x) => {
    return (
        <Tr key={x.id}>
            <Td>{x.createdOn}</Td>
            <Td>{x.createdBy.username}</Td>
            <Td>{x.recipient.username}</Td>
            <Td>{x.amount}</Td>
        </Tr>
    );
};

const initialFormValues = {
    recipient: "",
    amount: 1
};

const CookingDonationPage = () => {

    const { me, loadMe } = useContext(MeContext);
    const { users, loadUsers} = useContext(UsersContext);
    const { cookingBalanceOverview, getCookingBalanceOverview } = useContext(CookingBalanceContext);
    const { transactionBalanceOverview, getMyTransactionBalance } = useContext(TransactionBalanceContext);
    const { cookingDonationsPage, getCookingDonations } = useContext(CookingDonationContext);

    const { formValues, setFormValues, handleRecipientChange, handleAmountChange, handleSubmit } = useCookingDonationForm();

    useEffect(() => {
        loadMe();
        loadUsers();
        getCookingBalanceOverview();
        getCookingDonations();
    }, []);

    useEffect(() => {
        if (me.id !== -1) {
            getMyTransactionBalance();
        }
    }, [me.id]);

    useEffect(() => {
        initialFormValues["recipient"] = users[0] && (users[0].id === me.id ? users[1] && users[1].id : users[0].id);
        setFormValues(initialFormValues);
    }, [me.id, users])

    const resetFormValues = () => {
        setFormValues(initialFormValues)
        document.getElementById('recipientSelect').value = initialFormValues["recipient"];
        document.getElementById('amountInput').value = initialFormValues["amount"];
    };

    const recipientOptions = users.filter(x => x.id !== me.id);

    return (
        <PageWrapper rightPanels={[
            <MyCookingPointsPanel cookingPoints={cookingBalanceOverview.find((x) => x.user.id === me.id)?.balance} />,
            <MyBalancePanel balance={getMyTransactionBalanceFromOverview(transactionBalanceOverview, me.id)} />,
            <CookingPointsOverviewPanel balanceOverview={cookingBalanceOverview} />
        ]}>
            <Page>
                <DefaultTable
                    headers={headers}
                    convertRow={produceRow}
                    content={cookingDonationsPage?.content}
                />
                <ButtonWrapper>
                    Naar:
                    <RecipientSelect
                        id={'recipientSelect'}
                        onChange={handleRecipientChange}
                        value={formValues.recipient}
                    >
                        {recipientOptions.map(x => (
                            <option key={x.id} value={x.id}>{x.username}</option>
                        ))}
                    </RecipientSelect>
                    Hoeveelheid:
                    <AmountInput
                        id={'amountInput'}
                        onChange={e => handleAmountChange(e.target.value)}
                        value={formValues.amount}
                        type={'number'}
                        min={1}
                    />
                    <button
                        type={'button'}
                        onClick={() => handleSubmit(resetFormValues)}
                    >
                        Nieuw
                    </button>
                </ButtonWrapper>
            </Page>
        </PageWrapper>
    );

};

export default CookingDonationPage;