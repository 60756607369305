import React, { createContext, useState } from 'react';
import {
    createShuttleDrunkTestResultRequest, deleteShuttleDrunkTestResultRequest, getShuttleDrunkTestOverviewRequest,
    getShuttleDrunkTestResultsRequest
} from "../network/ShuttleDrunkTestRequests";
import {mergeArraysById} from "../util/ArrayUtils";
import {genericSortDesc} from "../util/SortUtils";

const initialPage = {
    content: [],
    empty: true,
    first: true,
    last: true,
    number: 0,
    numberOfElements: 0,
    pageable: {
        offset: 0,
        pageSize: 20,
        paged: true,
        sort: {
            empty: false,
            sorted: true,
            unsorted: false,
        },
        unpaged: false,
    },
    size: 0,
    sort: {
        empty: false,
        sorted: true,
        unsorted: false,
    },
    totalElements: 0,
    totalPages: 1,
};

export const ShuttleDrunkTestContext = createContext({});

const ShuttleDrunkTestContextProvider = (props) => {
    const [shuttleDrunkTestResultPage, setShuttleDrunkTestResultPage] = useState(initialPage);
    const [shuttleDrunkTestOverview, setShuttleDrunkTestOverview] = useState([]);

    const getShuttleDrunkTestResults = async (page = 0, onSuccess = () => {}) => {
        const result = await getShuttleDrunkTestResultsRequest(page);
        if (result) {
            setShuttleDrunkTestResultPage({
                ...result,
                content: mergeArraysById(shuttleDrunkTestResultPage.content, result.content).sort((a,b) => genericSortDesc(a,b,'id')),
            });
            onSuccess();
        }
    };

    const getShuttleDrunkTestOverview = async (activeOnly = true) => {
        const result = await getShuttleDrunkTestOverviewRequest(activeOnly);
        if (result) {
            setShuttleDrunkTestOverview(result);
        }
    };

    const createShuttleDrunkTestResult = async (data, onSuccess = () => {}) => {
        const result = await createShuttleDrunkTestResultRequest(data);
        if (result) {
            setShuttleDrunkTestResultPage({
                ...shuttleDrunkTestResultPage,
                content: [result, ...shuttleDrunkTestResultPage.content].sort((a,b) => genericSortDesc(a,b,'id')),
            });
            onSuccess();
        }
    };

    const deleteShuttleDrunkTestResult = async (id, onSuccess = () => {}) => {
        const result = await deleteShuttleDrunkTestResultRequest(id);
        if (result === 204) {
            setShuttleDrunkTestResultPage({
                ...shuttleDrunkTestResultPage,
                content: shuttleDrunkTestResultPage.content.filter(x => x.id !== id)
            });
            onSuccess();
        }
    };

    return (
        <ShuttleDrunkTestContext.Provider value={{
            shuttleDrunkTestResultPage,
            shuttleDrunkTestOverview,
            getShuttleDrunkTestResults,
            getShuttleDrunkTestOverview,
            createShuttleDrunkTestResult,
            deleteShuttleDrunkTestResult,
        }}
        >
            {props.children}
        </ShuttleDrunkTestContext.Provider>
    );
};

export default ShuttleDrunkTestContextProvider;