export const screenSize = {
    base: 0,
    sm: 568,
    md: 768,
    lg: 1024,
    xl: 1280,
}

export const device = {
    base: `(min-width: ${screenSize.base}px)`,
    sm: `(min-width: ${screenSize.sm}px)`,
    md: `(min-width: ${screenSize.md}px)`,
    lg: `(min-width: ${screenSize.lg}px)`,
    xl: `(min-width: ${screenSize.xl}px)`
};