import React, {useEffect, useContext} from 'react';
import PageWrapper from "../PageWrapper";
import {Page} from "../../styles/Page";
import {MeContext} from "../../../shared/context/MeContext";
import AveragePrices from "./AveragePrices";
import Forgotten from "./Forgotten";
import DryerCount from "./DryerCount";
import AveragePricesPerMonth from "./AveragePricesPerMonth";
import styled from 'styled-components';

const Container = styled(Page)`
    display: flex;
    flex-direction: column;
    text-align: center;
`


const StatsPage = () => {
    const { loadMe } = useContext(MeContext);

    useEffect(() => {
        loadMe();
    }, []);

    return (
        <PageWrapper>
            <Container>
                <AveragePrices />
                <AveragePricesPerMonth />
                <Forgotten />
                <DryerCount />
            </Container>
        </PageWrapper>
    )
}

export default StatsPage;