import React, {useContext, useEffect} from "react";
import {StatsContext} from "../../../shared/context/StatsContext";
import {Table} from "../../../desktop/styles/Page";
import {TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";

const DryerCountTable = () => {

    const { dryerCount, getDryerCount } = useContext(StatsContext);

    useEffect(() => {
        if (dryerCount.length === 0) {
            getDryerCount();
        }
    }, []);

    return (
        <>
            <TableContainer component={'div'}>
                <Table aria-label="dryer count table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>User</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Dried</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Ate</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>%</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dryerCount.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={3}>No data available</TableCell>
                            </TableRow>
                        )}
                        {dryerCount.map((x) => (
                            <TableRow key={x.user.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell>{x.user.username}</TableCell>
                                <TableCell>{x.amount}</TableCell>
                                <TableCell>{x.eatAmount}</TableCell>
                                <TableCell>{(100 * x.fraction).toFixed(2)}%</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default DryerCountTable;
