import React from "react";
import styled from "styled-components";
import {F1, Row} from "../../styles/Grid";
import {spacing} from "../../util/Spacing";
import {fontSizes} from "../../util/Fontsizes";
import {colors} from "../../../util/Colors";
import {func} from "../../../util/Helpers";
import MobileModal, {MobileModalBottom, MobileModalButtonRow, MobileModalContent} from "./MobileModal";
import Button from "../../styles/Button";


const StyledRow = styled(Row)`
    padding: ${spacing.base}px;
    padding-left: ${2 * spacing.base}px;
    
    font-size: ${fontSizes.base}px;
    
    border-bottom: 1px solid ${colors.borderLightGrey};
`;

const MobileModalBalance = ({ isOpen = false, close = func, title = '', balanceOverview = [] }) => {

    if (!isOpen) {
        return <></>;
    }

    return (
        <MobileModal
            isOpen={isOpen}
            close={close}
            title={title}
        >
            <MobileModalContent>
                {balanceOverview.length === 0 && (
                    <StyledRow>No data available</StyledRow>
                )}
                {balanceOverview.map(x => (
                    <StyledRow key={x.user.id}>
                        <F1>{x.user.username}</F1>
                        <F1>{x.balance}</F1>
                    </StyledRow>
                ))}
            </MobileModalContent>
            <MobileModalBottom>
                <MobileModalButtonRow>
                    <Button text={'Close'} onClick={close} />
                </MobileModalButtonRow>
            </MobileModalBottom>
        </MobileModal>
    );
};

export default MobileModalBalance;