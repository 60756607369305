import React, {createContext, useState} from "react";
import {getMe, putMeImageRequest, putMeRequest} from "../network/UserRequests";
import {impersonateRequest} from "../network/AuthRequests";
import {func} from "../../util/Helpers";

export const MeContext = createContext({});

const MeContextProvider = (props) => {

    const [me, setMe] = useState({
        id: -1,
        username: null,
        roles: [],
        image: null,
        autoEnroll: false,
    });

    const loadMe = async (force = false) => {
        if (me.id === -1 || force) {
            const data = await getMe();
            if (data) {
                setMe(data);
            }
        }
    };

    const updateMe = async (data, onSuccess = func) => {
        const result = await putMeRequest(data);
        if (result) {
            setMe(result);
            onSuccess();
        }
    };

    const updateMeImage = async (image, onSuccess = func) => {
        const data = await putMeImageRequest(image);
        if (data) {
            setMe(data);
            onSuccess();
        }
    };

    const impersonate = async (id, onSuccess = (result) => {}) => {
        const result = await impersonateRequest(id);
        if (result) {
            onSuccess(result);
        }
    };

    return (
        <MeContext.Provider value={{ me, loadMe, updateMe, updateMeImage, impersonate }}>
            {props.children}
        </MeContext.Provider>
    );
};

export default MeContextProvider;

