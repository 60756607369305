import React from "react";
import {Route, Redirect} from "react-router-dom";
import useJwt from "../hooks/JwtHook";

const PrivateRoute = ({component: Component, ...rest}) => {

    const { checkJwtIsValid } = useJwt();

    return (
        <Route {...rest} render={props => (
            checkJwtIsValid() ?
                <Component {...props} />
                : <Redirect to="/login" />
        )} />
    );
};

export default PrivateRoute;