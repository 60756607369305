import React from "react";
import {Fab as MaterialFab} from "@material-ui/core";
import {spacing} from "../util/Spacing";
import {colors} from "../../util/Colors";
import {fontSizes} from "../util/Fontsizes";

/**
 * Creates a column of FAB components
 *
 * @param items: Arrays of objects with name, onClick and text properties
 */
const FabStack = ({ items = [] }) => {
    return (
        <div style={{
            position: 'absolute',
            right: spacing.base,
            bottom: spacing.base,
            display: 'flex',
            flexDirection: 'column-reverse'
        }}>
            {items.map((x, i) => (
                <MaterialFab
                    key={i}
                    color="primary"
                    aria-label={x.name}
                    onClick={x.onClick}
                    style={{
                        marginBottom: spacing.base,
                        color: colors.white,
                        fontSize: fontSizes.big,
                    }}
                >
                    {x.text}
                </MaterialFab>
            ))}
        </div>
    )
};

export default React.memo(FabStack);