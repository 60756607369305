import React, {useContext, useEffect, useState} from "react";
import PageWrapper from "../PageWrapper";
import {BeerTypeContext} from "../../../shared/context/BeerTypeContext";
import {MeContext} from "../../../shared/context/MeContext";
import {UsersContext} from "../../../shared/context/UsersContext";
import {BeerInputContext} from "../../../shared/context/BeerInputContext";
import styled from "styled-components";
import BeerListTableHeader from "./BeerListTableHeader";
import BeerListTableBody from "./BeerListTableBody";
import TransactionTableFooter from "../transaction/TransactionTableFooter";
import {Row} from "../../styles/Layout";
import {isAdmin} from "../../../util/Helpers";
import ViewBeerInputModal from "./modal/ViewBeerInputModal";
import UploadFileModal from "../common/UploadFileModal";
import MyBeerBalancePanel from "../panel/MyBeerBalancePanel";
import {getMyTransactionBalanceFromOverview} from "../../../shared/util/TransactionUtils";
import EditBeerInputModal from "./modal/EditBeerInputModal";
import BeerTypePanel from "../beertype/BeerTypePanel";

const Page = styled.div`
    padding: 10px;
`;

const Table = styled.table`
    display: block;
    width: 470px;
`;

const Select = styled.select`
    width: 100px;
    margin-bottom: 10px;
`;

const CustomRow = styled(Row)`
    justify-content: space-between;
`;

export const initialBeerInput = {
    date: null,
    type: null,
    beerType: null,
    note: null,
    enrollments: []
}

const BeerListPage = () => {

    const { me, loadMe } = useContext(MeContext);
    const { users, loadUsers} = useContext(UsersContext);
    const { beerTypes, getAllBeerTypes } = useContext(BeerTypeContext);
    const { beerBalanceOverview, beerInputPageable, getBeerInputBalance, getBeerInputs, createBeerInput, updateBeerInput, uploadBeerInputImage } = useContext(BeerInputContext);

    const [ selectedBeerTypeId, setSelectedBeerTypeId ] = useState(-1);
    const [ selectedBeerInput, setSelectedBeerInput ] = useState(null);
    const [ showCreateModal, setShowCreateModal ] = useState(false);
    const [ showViewModal, setShowViewModal ] = useState(false);
    const [ showEditViewModal, setShowEditViewModal ] = useState(false);
    const [ showUploadModal, setShowUploadModal ] = useState(false);

    useEffect(() => {
        loadMe();
        loadUsers();
        getAllBeerTypes(setSelectedBeerTypeId);
    }, [])

    useEffect(() => {
        // When the selected beerType changes we need to reload all relevant data on the page
        if (!!selectedBeerTypeId && selectedBeerTypeId > -1) {
            getBeerInputBalance(true, selectedBeerTypeId);
            getBeerInputs(0, selectedBeerTypeId);
        }
    }, [selectedBeerTypeId])

    useEffect(() => {
        if (!showUploadModal)
            setShowViewModal(true);
    }, [showUploadModal])

    const handleBeerTypeChange = (event) => {
        setSelectedBeerTypeId(event.target.value);
    }

    const getBeerInputsOfCurrentBeer = (page) => {
        getBeerInputs(page, selectedBeerTypeId);
    }

    const uploadImage = (formData, closeModal) => {
        uploadBeerInputImage(selectedBeerInput.id, formData, closeModal);
        setSelectedBeerInput(null);
    }

    const update = (formData, onSuccess) => {
        updateBeerInput(selectedBeerInput.id, formData, onSuccess);
    }

    return (
        <PageWrapper rightPanels={[
            <MyBeerBalancePanel balance={getMyTransactionBalanceFromOverview(beerBalanceOverview, me.id)} />,
            isAdmin(me) && <BeerTypePanel />
        ]}>
            <Page>
                <CustomRow>
                    <Select
                        id={'recipientSelect'}
                        onChange={handleBeerTypeChange}
                        value={selectedBeerTypeId}
                    >
                        {beerTypes.map(x => (
                            <option key={x.id} value={x.id}>{x.name}</option>
                        ))}
                    </Select>
                    {isAdmin(me) && (
                        <button style={{height: 22}} onClick={() => setShowCreateModal(true)}>
                            Nieuw
                        </button>
                    )}
                </CustomRow>
                <Table>
                    <BeerListTableHeader headers={users.map(x => x.username)} />
                    <BeerListTableBody
                        users={users}
                        beerInputPageable={beerInputPageable}
                        getBeerInputs={getBeerInputsOfCurrentBeer}
                        setSelectedBeerInput={setSelectedBeerInput}
                        setShowViewModal={setShowViewModal}
                    />
                    <TransactionTableFooter users={users} balanceOverview={beerBalanceOverview} showTotal={true} />
                </Table>
            </Page>
            <EditBeerInputModal
                isOpen={showCreateModal}
                setIsOpen={setShowCreateModal}
                modify={createBeerInput}
                beerInput={initialBeerInput}
                beerTypes={beerTypes}
                currentBeerTypeId={selectedBeerTypeId === -1 ? undefined : selectedBeerTypeId}
                users={users}
                setSelectedBeerTypeId={setSelectedBeerTypeId}
                setSelectedBeerInput={setSelectedBeerInput}
                setShowUploadModal={setShowUploadModal}
            />
            <ViewBeerInputModal
                isOpen={showViewModal}
                setIsOpen={setShowViewModal}
                users={users}
                setIsEdit={setShowEditViewModal}
                beerInput={selectedBeerInput}
                canUpload={me.id === selectedBeerInput?.createdBy?.id}
                setShowUploadModal={setShowUploadModal}
            />
            <EditBeerInputModal
                isOpen={showEditViewModal}
                setIsOpen={setShowEditViewModal}
                users={users}
                modify={update}
                beerInput={selectedBeerInput || initialBeerInput}
                beerTypes={beerTypes}
                currentBeerTypeId={selectedBeerTypeId === -1 ? undefined : selectedBeerTypeId}
                setSelectedBeerInput={setSelectedBeerInput}
                setSelectedBeerTypeId={setSelectedBeerTypeId}
                setShowUploadModal={setShowUploadModal}
            />
            <UploadFileModal
                title={"Foto uploaden"}
                isOpen={showUploadModal}
                setIsOpen={setShowUploadModal}
                upload={uploadImage}
                accept={"image/*"}
            />
        </PageWrapper>
    );
};

export default BeerListPage;
