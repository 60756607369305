import React, {useContext, useEffect, useState} from "react";
import MobileModalEnrollments from "../../modal/MobileModalEnrollments";
import MobileModal, {MobileModalBottom, MobileModalButtonRow} from "../../modal/MobileModal";
import Button from "../../../styles/Button";
import {spacing} from "../../../util/Spacing";
import {CookingEventContext} from "../../../../shared/context/CookingEventContext";
import {changeAmountForUser, enrollmentsToWriteDto} from "../../../../shared/util/CookingEventUtils";
import {func} from "../../../../util/Helpers";

const EditEnrollmentsModal = ({ isOpen = false, close = func, title = '', users = [], eventId }) => {

    const [ formEnrollments, setFormEnrollments ] = useState([]);

    const { cookingEvents, updateCookingEvent } = useContext(CookingEventContext);

    useEffect(() => {
        if (isOpen) {
            setFormEnrollments(cookingEvents.find(x => x.id === eventId)?.enrolled || []);
        }
    }, [isOpen]);

    const submit = () => {
        const data = { enrollments: enrollmentsToWriteDto(formEnrollments) }
        updateCookingEvent(eventId, data).then(() => close());
    };

    if (!isOpen) {
        return <></>;
    }

    return (
        <MobileModal
            isOpen={isOpen}
            close={close}
            title={title}
        >
            <MobileModalEnrollments
                users={users}
                enrollments={formEnrollments}
                onChangeClick={(userId, change) => setFormEnrollments(changeAmountForUser(formEnrollments, userId, change))}
            />
            <MobileModalBottom>
                <MobileModalButtonRow>
                    <Button text={'Cancel'} color={'secondary'} style={{ marginRight: spacing.base }} onClick={close} />
                    <Button text={'Save'} onClick={submit} />
                </MobileModalButtonRow>
            </MobileModalBottom>
        </MobileModal>
    );
};

export default React.memo(EditEnrollmentsModal);