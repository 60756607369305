import styled from 'styled-components';
import PanelTopImage from '../images/panel/panel_top.png';
import PanelBodyImage from '../images/panel/panel_body.png';
import PanelBottomImage from '../images/panel/panel_bottom.png';
import PanelDividerImage from '../images/panel/panel_divider.png';

const Panel = styled.div`
    width: 192px;
`;

export const PanelContainer = styled(Panel)`
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
`;

export const PanelTop = styled(Panel)`
    height: 26px;
    background-image: url(${PanelTopImage});
`;

export const PanelBody = styled(Panel)`
    min-height: 64px;
    background-image: url(${PanelBodyImage});
`;

export const PanelBottom = styled(Panel)`
    height:4px;
    background-image: url(${PanelBottomImage});
`;

export const PanelDivider = styled.div`
    width: 172px;
    height: 4px;
    margin-left: 11px;
    margin-top: 9px;
    margin-bottom: 7px;
    background-image: url(${PanelDividerImage});
`;

export const Half = styled.div`
    width: 40%;
    color: white;
    float: left;
    padding-left: 10px;
    font-family: Verdana;
    font-size: 9.6px;
    margin-top: 2px;
    margin-bottom: 2px;
`;
