import Axios from "axios";
import {constants} from "../../../../util/Constants";
import {defaultAxiosErrorHandler} from "../../../util/RequestUtils";

const controllerPath = 'room-soliciting-days';

export const getRoomSolicitingDayForDateRequest = (date) => Axios({
    method: 'get',
    url: `${constants.apiBaseUrl}/${controllerPath}/get-or-create?date=${date}`,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const getRoomSolicitingDayForDatesRequest = (from, to) => Axios({
    method: 'get',
    url: `${constants.apiBaseUrl}/${controllerPath}/get-or-create/range?from=${from}&to=${to}`,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);
