import Axios from "axios";
import {constants} from "../../util/Constants";
import {defaultAxiosErrorHandler} from "../util/RequestUtils";

const controllerPath = 'printing';

export const postFileRequest = (data) => {
    const client = Axios.create({
        baseURL: constants.apiBaseUrl,
    });
    const headers = {
        'Content-Type': 'multipart/form-data',
    };
    return client.post(`/${controllerPath}`, data, headers)
        .then((response) => response.data)
        .catch(defaultAxiosErrorHandler);
};

export const getPrintingOverviewRequest = (activeOnly = true) => Axios({
    method: 'get',
    url: `${constants.apiBaseUrl}/${controllerPath}/overview?activeOnly=${activeOnly}`,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const getPrintingJobsRequest = (page) => {
    let url = `${constants.apiBaseUrl}/${controllerPath}?sort=date,desc`;
    if (page) {
        url += `&page=${page}`;
    }

    return Axios({
        method: 'get',
        url,
    }).then((response) => response.data).catch(defaultAxiosErrorHandler);
};
