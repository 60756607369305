import React, {createContext, useState} from "react";
import {
    getRoomSolicitorByIdRequest,
    getRoomSolicitorsRequest,
    postRoomSolicitorRequest, putRoomSolicitorRequest
} from "../../network/roomsoliciting/solicitor/RoomSolicitorRequests";
import {mergeArraysById} from "../../util/ArrayUtils";
import {genericSortDesc} from "../../util/SortUtils";

export const RoomSolicitorContext = createContext({});

const initialPage = {
    content: [],
    empty: true,
    first: true,
    last: true,
    number: 0,
    numberOfElements: 0,
    pageable: {
        offset: 0,
        pageSize: 20,
        paged: true,
        sort: {
            empty: false,
            sorted: true,
            unsorted: false,
        },
        unpaged: false,
    },
    size: 0,
    sort: {
        empty: false,
        sorted: true,
        unsorted: false,
    },
    totalElements: 0,
    totalPages: 1,
};

const RoomSolicitorContextProvider = (props) => {

    const [solicitorsPage, setSolicitorsPage] = useState(initialPage);

    const getSolicitors = async (page, size) => {
        const result = await getRoomSolicitorsRequest(page, size);
        if (result) {
            setSolicitorsPage({
                ...result,
                content: mergeArraysById(solicitorsPage.content, result.content).sort((a,b) => genericSortDesc(a, b, 'id')),
            });
        }
    }

    const getSolicitor = async (id) => {
        const result = await getRoomSolicitorByIdRequest(id);
        if (result) {
            setSolicitorsPage({
                ...solicitorsPage,
                content: mergeArraysById(solicitorsPage.content, [ result ]).sort((a,b) => genericSortDesc(a, b, 'id')),
            });
        }
    }

    const createSolicitor = async (data) => {
        const result = await postRoomSolicitorRequest(data);
        if (result) {
            setSolicitorsPage({
                ...solicitorsPage,
                content: mergeArraysById(solicitorsPage.content, [ result ]).sort((a,b) => genericSortDesc(a, b, 'id')),
            });
        }
    }

    const updateSolicitor = async (id, data) => {
        const result = await putRoomSolicitorRequest(id, data);
        if (result) {
            setSolicitorsPage({
                ...solicitorsPage,
                content: mergeArraysById(solicitorsPage.content, [ result ]).sort((a,b) => genericSortDesc(a, b, 'id')),
            });
        }
    }

    return (
        <RoomSolicitorContext.Provider value={{ solicitorsPage, getSolicitor, getSolicitors, createSolicitor, updateSolicitor }}>
            {props.children}
        </RoomSolicitorContext.Provider>
    );
};

export default RoomSolicitorContextProvider;
