import styled from "styled-components";
import {useEffect, useState} from "react";

const Table = styled.table`
    display: block;
    margin: 5px;
    color: white;
`;

const tableWidth = '190px';
const Thead = styled.thead`
    display: block;
    width: ${tableWidth};
    font-weight: bold;
`

const Tbody = styled.tbody`
    display: block;
    width: ${tableWidth}
`

const Tfoot = styled.tfoot`
    display: block;
    width: ${tableWidth};
    color: #3BADFF;
`

const Tr = styled.tr`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
`

const Td = styled.td`
    height: 11px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 1px;
    padding-right: 1px;
    white-space: nowrap;
    font-family: Verdana;
    font-size: 0.55em;
    overflow: hidden;
    flex: 1 1 0px;
`;

const Input = styled.input`
    height: 11px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 1px;
    padding-right: 1px;
    white-space: nowrap;
    font-family: Verdana;
    font-size: 0.55em;
    overflow: hidden;
    flex: 1 1 0px;
`

const Button = styled.button`
    font-size: 0.55em;
`

const BeerTypeTable = ({ beerTypes, createBeerType, updateBeerType }) => {
    const [selectedType, setSelectedType] = useState(null);
    const [creatingNew, setCreatingNew] = useState(false);
    const [selectedName, setSelectedName] = useState('Grolsch');
    const [selectedAmount, setSelectedAmount] = useState(24);

    const handleEditClick = (x) => {
        if (selectedType === x) {
            updateBeerType(x.id, {
                name: selectedName,
                beersPerCrate: selectedAmount
            });
            setSelectedType(null);
        } else {
            setSelectedType(x);
        }
    };

    useEffect(() => {
        if (selectedType) {
            setSelectedName(selectedType.name)
            setSelectedAmount(selectedType.beersPerCrate)
        }
    }, [selectedType]);

    const handleNameChange = (event) => {
        setSelectedName(event.target.value);
    }

    const handleAmountChange = (event) => {
        setSelectedAmount(event.target.value);
    }

    const getReadRow = (x) => {
        return (
            <Tr key={x.id}>
                <Td style={{ flexGrow: 4 }}>{x.name}</Td>
                <Td>{x.beersPerCrate}</Td>
                <Td style={{ cursor: 'pointer' }} onClick={() => handleEditClick(x)}>&#128393;</Td>
            </Tr>
        );
    };

    const getEditRow = (x) => {
        return (
            <Tr key={x.id}>
                <Input style={{flexGrow: 4}} type={"text"} maxLength="10" value={selectedName} onChange={handleNameChange} />
                <Input type={"number"} min="1" value={selectedAmount} onChange={handleAmountChange} />
                <Td style={{ cursor: 'pointer' }} onClick={() => handleEditClick(x)}>&#128190;</Td>
            </Tr>
        );
    }

    const handleSave = () => {
        createBeerType({ name: selectedName, beersPerCrate: selectedAmount });
        setCreatingNew(!creatingNew);
    }

    const getCreatingFooter = () => {
        return (
            <Tr key={-1}>
                <Input style={{flexGrow: 4}} type={"text"} maxLength="10" value={selectedName} onChange={handleNameChange} />
                <Input type={"number"} min="1" value={selectedAmount} onChange={handleAmountChange} />
                <Td style={{ cursor: 'pointer' }} onClick={() => handleSave()}>&#128190;</Td>
            </Tr>
        )
    }

    const getNonCreatingFooter = () => {
        return <Button onClick={() => setCreatingNew(!creatingNew)}>Voeg toe</Button>
    }

    return (
        <Table>
            <Thead>
                <Tr>
                    <Td style={{ flexGrow: 4 }}>Naam</Td>
                    <Td style={{ overflow: 'clip'}}>#/krat</Td>
                    <Td>{}</Td>
                </Tr>
            </Thead>
            <Tbody>
                {beerTypes.map(x => (
                    selectedType === x
                        ? getEditRow(x)
                        : getReadRow(x)
                ))}
            </Tbody>
            <Tfoot>
                {creatingNew
                    ? getCreatingFooter()
                    : getNonCreatingFooter()
                }

            </Tfoot>
        </Table>
    )
};

export default BeerTypeTable;
