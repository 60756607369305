import Axios from "axios";
import {constants} from "../../util/Constants";
import {defaultAxiosErrorHandler} from "../util/RequestUtils";

export const getShuttleDrunkTestResultsRequest = (page) => {
    let url = `${constants.apiBaseUrl}/shuttle-drunk-test?sort=id,desc`;
    if (page) {
        url += `&page=${page}`;
    }

    return Axios({
        method: 'get',
        url,
    }).then((response) => response.data).catch(defaultAxiosErrorHandler);
};

export const getShuttleDrunkTestOverviewRequest = (activeOnly = true) => Axios({
    method: 'get',
    url: `${constants.apiBaseUrl}/shuttle-drunk-test/high-score/overview?activeOnly=${activeOnly}`,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const createShuttleDrunkTestResultRequest = (data) => Axios({
    method: 'post',
    url: `${constants.apiBaseUrl}/shuttle-drunk-test`,
    data,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

/**
 * Returns a promise with the status code of the request
 */
export const deleteShuttleDrunkTestResultRequest = (id) => Axios({
    method: 'delete',
    url: `${constants.apiBaseUrl}/shuttle-drunk-test/${id}`,
}).then((response) => response.status).catch(defaultAxiosErrorHandler);