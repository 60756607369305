import React from "react";
import styled from 'styled-components';
import TableFooterImage from '../../images/cookingSchedule/table_footer.png';

const Row = styled.tr`
    width: 470px;
    height: 18px;
    
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    
    background-image: url(${TableFooterImage});
    
    margin-top: -2px;
    margin-left: -2px;
`;

const Td = styled.td`
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 0px;
    padding-right: 0px;
    
    font: 100% Verdana;
    white-space: nowrap;
    font-weight: bold;
    color: white;
    font-size: 0.55em;
    
    width: ${props => props.width ? props.width : 40}px;
    
    text-align: ${props => props.textAlign ? props.textAlign : 'center'};
`;

const TotalTd = styled(Td)`
    padding-left: 3px;
    padding-right: 0px;
`;

const AmountTd = styled(Td)`
    padding-left: 0px;
    padding-right: 3px;
`;

const getBalanceForUserId = (balanceOverview = [], userId = -1, toFixed = false) => {
    const balance = balanceOverview.find(x => x.user.id === userId)?.balance;
    if (balance)
        return toFixed ? balance.toFixed(2) : balance;
};

const computeTotal = (balanceOverview = []) => {
    return balanceOverview.reduce((a, b) => a + b.balance, 0);
};

const TransactionTableFooter = ({users = [], balanceOverview = [], toFixed = false, showTotal = false}) => {

    const width = (470 - (39 + 64 + (users.length * 3) + 17)) / users.length;

    return (
        <tfoot>
            <Row>
                <Td width={39} paddingLeft={2}>&nbsp;</Td>
                <TotalTd width={61} textAlign={'left'}>Totaal {showTotal ? ` (${computeTotal(balanceOverview)})` : ''}</TotalTd>
                {users.map(x => (
                    <AmountTd width={width} key={x.id}>{getBalanceForUserId(balanceOverview, x.id, toFixed)}</AmountTd>
                ))}
                <Td width={17}>&nbsp;</Td>
            </Row>
        </tfoot>
    );
};

export default TransactionTableFooter;
