import {useState} from "react";
import {loginRequest} from "../network/AuthRequests";
import {handleJwt} from "../../util/Jwt";
import {useHistory} from "react-router-dom";

function useLoginForm() {

    const history = useHistory();

    const [formValues, setFormValues] = useState({
        username: null,
        password: null
    });

    const setUsername = (input) => {
        setFormValues({...formValues, username: input});
    };

    const setPassword = (input) => {
        setFormValues({...formValues, password: input});
    };

    const handleSubmit = async () => {
        const result = await loginRequest(formValues.username, formValues.password);
        if (result) {
            handleJwt(result, history);
        }
    };

    return { setUsername, setPassword, handleSubmit };
}

export default useLoginForm;