import React from 'react';
import styled from "styled-components";
import ButtonWrapper from "../../styles/ButtonWrapper";
import Button from "../../styles/Button";
import {useHistory} from "react-router-dom";
import {routes} from "../../../shared/routing/Routes";

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
`;

const CustomButtonWrapper = styled(ButtonWrapper)`
    width: 50%;
    margin-left: 5px;
    margin-right: 5px;
`;

const RoomSolicitingNavigation = () => {

    const history = useHistory();

    const handleCalendarClick = () => {
        history.push(routes.roomSoliciting);
    };

    const handleSolicitorsClick = () => {
        history.push(routes.roomSolicitors);
    };

    return (
        <ButtonsContainer>
            <CustomButtonWrapper>
                <Button onClick={handleCalendarClick}>Agenda</Button>
            </CustomButtonWrapper>
            <CustomButtonWrapper>
                <Button onClick={handleSolicitorsClick}>Kamerzoekers</Button>
            </CustomButtonWrapper>
        </ButtonsContainer>
    );
};

export default RoomSolicitingNavigation;
