import React from "react";
import styled from 'styled-components';
import TableHeaderImage from '../../images/cookingSchedule/table_header.png';

const headerWidth = 470;
const dateWidth = 39;
const descriptionWidth = 64;
const scrollbarWidth = 17;

const HeaderRow = styled.tr`
    width: 470px;
    height: 18px;
    
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    
    background-image: url(${TableHeaderImage});
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    
    margin-left: -2px; // To fix some visual bug
`;

const HeaderCol = styled.th`
    ${props => props.width && `width: ${props.width}px;`}
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 0px;
    padding-right: 0px;
    
    overflow: hidden;
    
    font: 100% Verdana;
    white-space: nowrap;
    font-weight: bold;
    color: white;
    font-size: 0.55em;
    text-align: center;
`;

const DateCol = styled(HeaderCol)`
    border: none;
`;

const TransactionTableHeader = ({headers = []}) => {
    return (
        <thead>
            <HeaderRow>
                <DateCol width={dateWidth}>Datum</DateCol>
                <HeaderCol width={descriptionWidth}>Onderwerp</HeaderCol>
                {headers.map((x, i) => (
                    <HeaderCol
                        width={(headerWidth-(dateWidth+descriptionWidth+scrollbarWidth))/headers.length}
                        key={i}
                    >
                        {x}
                    </HeaderCol>
                ))}
                <HeaderCol width={scrollbarWidth}>&nbsp;</HeaderCol>
            </HeaderRow>
        </thead>
    );
};

export default TransactionTableHeader;