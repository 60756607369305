import PageWrapper from "../PageWrapper";
import {Page} from "../../styles/Page";
import React, {useContext, useEffect, useState} from "react";
import PrintingOverviewPanel from "../panel/PrintingOverviewPanel";
import {MeContext} from "../../../shared/context/MeContext";
import {PrintingContext} from "../../../shared/context/PrintingContext";
import PrintingTable from "./PrintingTable";
import {Row} from "../../styles/Layout";
import styled from "styled-components";
import UploadPDFModal from "./UploadPDFModal";

const CustomRow = styled(Row)`
    margin-bottom: 10px;
`

const PrintingPage = () => {
    const { loadMe } = useContext(MeContext);
    const { printingOverview, printingJobs, getPrintingOverview, getPrintingJobs, printFile } = useContext(PrintingContext);

    const [ showUploadModal, setShowUploadModal ] = useState(false);

    useEffect(() => {
        loadMe();
        getPrintingOverview();
        getPrintingJobs();
    }, []);

    useEffect(() => {
        getPrintingOverview();
    }, [printingJobs]);

    return (
        <PageWrapper rightPanels={[
            <PrintingOverviewPanel overview={printingOverview} />
        ]}>
            <Page>
                <CustomRow>
                    <button style={{height: 22}} onClick={() => setShowUploadModal(true)}>
                        Nieuw
                    </button>
                </CustomRow>
                <PrintingTable
                    content={printingJobs?.content}
                    getPrintingJobs={getPrintingJobs}
                    page={printingJobs.number}
                    last={printingJobs.last}
                />
            </Page>
            <UploadPDFModal
                isOpen={showUploadModal}
                setIsOpen={setShowUploadModal}
                upload={printFile}
                accept={".pdf"}
            />
        </PageWrapper>
    );
};

export default PrintingPage;
