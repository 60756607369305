import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {colors} from "../../util/Colors";
import {getImageRequest} from "../../shared/network/ImageRequests";
import {loadFromCache} from "../../shared/util/CachingUtils";
import {constants} from "../../util/Constants";
import DefaultImage from "../images/default-profile-picture.png";

const Wrapper = styled.div`
    position: relative;
`;

const Image = styled.img`
    border-radius: 999px;
    object-fit: cover;
`;

const Text = styled.div`
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    text-align: center;
    color: ${colors.white};
    font-weight: bold;
`;

const RoundImage = ({ imageId, width = '100%', borderColor = colors.darkOrange, borderWidth = 2, amount = 0, onClick }) => {

    const [image, setImage] = useState(undefined);

    useEffect(() => {
        if (imageId) {
            loadFromCache(constants.imageCacheName, imageId).then(cachedImage => {
                if (cachedImage) {
                    cachedImage.blob().then(blob => {
                        if (blob) {
                            const fileReaderInstance = new FileReader();
                            fileReaderInstance.readAsDataURL(blob);
                            fileReaderInstance.onload = () => {
                                const imageBase64 = fileReaderInstance.result;
                                setImage(imageBase64);
                            };
                        }
                    })
                    return;
                }

                getImageRequest(imageId).then(blob => {
                    if (blob) {
                        const fileReaderInstance = new FileReader();
                        fileReaderInstance.readAsDataURL(blob);
                        fileReaderInstance.onload = () => {
                            const imageBase64 = fileReaderInstance.result;
                            setImage(imageBase64);
                        };
                    }
                });
            })
        }
    }, [imageId]);

    const hasAmountText = amount > 1;

    return (
        <Wrapper>
            <Image
                src={image || DefaultImage}
                alt={''}
                style={{
                    width: width,
                    height: width,
                    border: `${borderWidth}px solid ${borderColor}`,
                    backgroundColor: borderColor,
                    cursor: onClick ? 'pointer' : 'default',
                    // opacity: hasAmountText ? 0.5 : 1,
                }}
                onClick={onClick ? () => onClick() : undefined}
            />
            {hasAmountText && (
                <Text><b>+{amount}</b></Text>
            )}
        </Wrapper>
    );
};

export default React.memo(RoundImage);