import React, {createContext, useContext, useState} from 'react';
import {getMyTransactionBalanceRequest, getTransactionBalanceOverviewRequest} from '../network/TransactionRequests';
import {MeContext} from "./MeContext";
import {sortByBalanceDescAndUserIdAsc} from "../util/SortUtils";

export const TransactionBalanceContext = createContext({});

const compareBalance = (a, b) => {
    if (a.balance > b.balance) return -1;
    if (a.balance < b.balance) return 1;
    return 0;
};

const TransactionBalanceContextProvider = (props) => {
    const { me } = useContext(MeContext);
    const [transactionBalanceOverview, setTransactionBalanceOverview] = useState([]);

    const getTransactionBalanceOverview = async (activeOnly = true) => {
        const data = await getTransactionBalanceOverviewRequest(activeOnly);
        if (data) {
            data.sort(compareBalance);
            setTransactionBalanceOverview(data);
        }
    };

    const getMyTransactionBalance = async () => {
        const data = await getMyTransactionBalanceRequest();
        if (typeof data === 'number') {
            let newValue = []
            // Check if user is already in the list
            if (transactionBalanceOverview.find(x => x.user.id === me.id)) {
                // Replace its value
                newValue = transactionBalanceOverview.map((x) => (x.user.id !== me.id ? x : { ...x, balance: data }))
            } else {
                // Add value
                newValue.push(...transactionBalanceOverview);
                newValue.push({ user: { id: me.id, username: me.username, image: me.image }, balance: data })
            }
            newValue.sort(sortByBalanceDescAndUserIdAsc);
            setTransactionBalanceOverview(newValue);
        }
    };

    return (
        <TransactionBalanceContext.Provider value={{
            transactionBalanceOverview,
            getTransactionBalanceOverview,
            getMyTransactionBalance
        }}>
            {props.children}
        </TransactionBalanceContext.Provider>
    );
};

export default TransactionBalanceContextProvider;
