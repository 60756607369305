import React from "react";
import styled from "styled-components";
import {spacing} from "../util/Spacing";
import {colors} from "../../util/Colors";
import dayjs from "dayjs";
import {fontSizes} from "../util/Fontsizes";

const StyledList = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    
    padding-left: ${spacing.base}px;
    padding-right: ${spacing.base}px;
    
    overflow-y: auto;
`;

export const ListItem = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    
    padding-top: 7px;
    padding-bottom: 7px;
    
    max-height: 65px;
    
    border-bottom: 1px solid ${colors.borderLightGrey};
`;

/**
 * Component that can be used to create a list that is meant to fill an entire page
 */
const List = ({ data = [], renderItem = () => {}, onEndReached = () => {}, listStyle }) => {

    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            onEndReached();
        }
    };

    return (
        <StyledList style={listStyle} onScroll={handleScroll}>
            {data.map(x => renderItem(x))}
        </StyledList>
    )
};

export default List;

export const StyledListDate = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    width: 65px;
    height: 65px;
    
    margin-right: ${spacing.base}px;

    background-color: ${colors.orange};
`;

export const ListDate = ({ date = '' }) => {
    return (
        <StyledListDate>
            <div style={{ color: colors.white, textAlign: 'center', fontSize: fontSizes.big, fontWeight: 'bold' }}>
                {dayjs(date).format('DD')}
            </div>
            <div style={{ color: colors.white, textAlign: 'center', fontSize: fontSizes.base, marginTop: -spacing.small }}>
                {dayjs(date).format('MMM')}
            </div>
        </StyledListDate>
    )
}
