import Axios from "axios";
import {constants} from "../../../../util/Constants";
import {defaultAxiosErrorHandler} from "../../../util/RequestUtils";

const controllerPath = 'room-soliciting-days/availability';

export const postRoomSolicitingAvailabilityRequest = (data) => Axios({
    method: 'post',
    url: `${constants.apiBaseUrl}/${controllerPath}`,
    data,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const putRoomSolicitingAvailabilityRequest = (id, data) => Axios({
    method: 'put',
    url: `${constants.apiBaseUrl}/${controllerPath}/${id}`,
    data,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);
