import React, {useContext, useState, useEffect} from "react";
import PageWrapper from "../PageWrapper";
import styled from "styled-components";
import ButtonWrapper from "../../styles/ButtonWrapper";
import Button from "../../styles/Button";
import {Page} from "../../styles/Page";
import { RgbColorPicker } from "react-colorful"
import { changeBrightnessRequest, changeColorRequest, customRequest } from "../../../shared/network/LedsRequests";
import {MeContext} from "../../../shared/context/MeContext";
import {isAdmin} from "../../../util/Helpers";

const NoButton = styled.div`
    margin: 2px;
    text-align: center;
    color: white;
`;

const Container = styled.div`
    display: flex;
`;

const CheckboxContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
`;

const Col = styled.div`
    flex: 50%;
    margin: 4px;
`;

const Center = styled.div`
    display: flex;
    justify-content: center;
`;

const Card = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    height: 10em;
    gap: 0.5em;
    border-radius: 30px;
    background: #FFF;
    box-shadow: 4px 8px 2px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    &:hover {
        filter: brightness(0.8);
    }
`;

const Image = styled.div`
    border-radius: 30px 30px 0 0;
    height: 80%;
    align-self: stretch;
    flex-shrink: 0;
    background: url(${props => props.src}) no-repeat center;
    background-size: cover;
`;

const LedsPage = () => {

    const { me, loadMe } = useContext(MeContext);

    useEffect(() => {
        loadMe();
    }, []);

    // For led strip specifics
    const [show, setShow] = useState(false);

    const toggleShow = () => {
        setShow(!show);
    };

    const [isCheckedMain, setIsCheckedMain] = useState(true);

    const handleOnChangeMain = () => {
      setIsCheckedMain(!isCheckedMain);
    };

    const [isCheckedBar, setIsCheckedBar] = useState(true);

    const handleOnChangeBar = () => {
      setIsCheckedBar(!isCheckedBar);
    };

    const [isCheckedHallway, setIsCheckedHallway] = useState(true);

    const handleOnChangeHallway = () => {
      setIsCheckedHallway(!isCheckedHallway);
    };

    const [color, setColor] = useState({ r: 255, g: 50, b: 0 });
    
    useEffect(() => {
        const timeOutId = setTimeout(() => changeColorRequest(color, isCheckedMain, isCheckedBar, isCheckedHallway), 300);
        return () => clearTimeout(timeOutId)
    }, [color])
    
    const [brightness, setBrightness] = useState(0.5)
    
    const changeBrightness = (event) => {
        setBrightness(event.target.value);
      };
      
    useEffect(() => {
        const timeOutId = setTimeout(() => changeBrightnessRequest(brightness, isCheckedMain, isCheckedBar, isCheckedHallway), 300);
        return () => clearTimeout(timeOutId)
    }, [brightness])

    return (
        <PageWrapper>
            <Page>
                <Container>
                    <Col>
                        <ButtonWrapper><NoButton>Verander kleur</NoButton></ButtonWrapper>
                        <Center>
                            <RgbColorPicker color={color} onChange={setColor} />
                        </Center>
                    </Col>
                    <Col>
                        <ButtonWrapper><NoButton>Verander helderheid</NoButton></ButtonWrapper>
                        <Center>
                            <input
                                type='range'
                                onChange={changeBrightness}
                                min={0}
                                max={1}
                                step={0.005}
                                value={brightness}>
                            </input>
                        </Center>
                    </Col>
                </Container>
                
                
                <ButtonWrapper><NoButton>Selecteer een programma</NoButton></ButtonWrapper>

                <Container>
                    <Col>
                        <Card onClick={() => customRequest('rainbow')}>
                            <Image src="https://www.theglowhouse.com/images/large/RainbowNeonLight.jpg"/>
                            Rainbow
                        </Card>
                    </Col>
                    <Col>
                    <Card onClick={() => customRequest('movie')}>
                            <Image src="https://cdn.cnn.com/cnnnext/dam/assets/210406161315-01-movie-theater-covid-0315-super-tease.jpg"/>
                            Movie
                        </Card>
                    </Col>

                </Container>

                <ButtonWrapper>
                    <Button onClick={toggleShow}>Kies een specifieke ledstrip</Button>
                </ButtonWrapper>
                {show && <CheckboxContainer>
                
                    <div>
                        <div style={{margin: "5px"}}>Main</div>
                        <input
                            type="checkbox"
                            checked={isCheckedMain}
                            onChange={handleOnChangeMain}
                        />
                    </div>

                    <div>
                    <div style={{margin: "5px"}}>Bar</div>
                        <input
                            type="checkbox"
                            checked={isCheckedBar}
                            onChange={handleOnChangeBar}
                        />
                    </div>

                    <div>
                    <div style={{margin: "5px"}}>Gang</div>
                        <input
                            type="checkbox"
                            checked={isCheckedHallway}
                            onChange={handleOnChangeHallway}
                        />
                    </div>
                
                </CheckboxContainer>}

                {isAdmin(me) && (
                    <ButtonWrapper>
                        <Button onClick={() => alert("Nog niet geïmplementeerd")}>Voeg programma toe</Button>
                    </ButtonWrapper>
                )}
               
            </Page>
        </PageWrapper>
    );

}

export default LedsPage;