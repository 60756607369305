import {func, minimum} from "../../../util/Helpers";
import MobileModal, {MobileModalBottom, MobileModalButtonRow, MobileModalContent} from "../modal/MobileModal";
import React, {useEffect} from "react";
import {spacing} from "../../util/Spacing";
import Button from "../../styles/Button";
import styled from "styled-components";
import {Col, F1, F2, Row} from "../../styles/Grid";
import {fontSizes} from "../../util/Fontsizes";
import {AmountCounter} from "../modal/MobileModalEnrollments";
import Select from "../../styles/Select";
import useCookingDonationForm from "../../../shared/hooks/CookingDonationFormHook";

const StyledRow = styled(Row)`
    padding: ${spacing.base}px;
`;

const Label = styled(F1)`
    display: flex;
    flex-direction: row;
    align-items: center;
    
    font-size: ${fontSizes.base}px;
`;

const Value = styled(F2)`
    display: flex;
    flex-direction: row;
`;

const CreateDonationModal = ({ isOpen = false, close = func, users = [], meId = -1 }) => {

    const { formValues, handleRecipientChange, handleAmountChange, handleSubmit, reset } = useCookingDonationForm();

    useEffect(() => {
        if (isOpen) {
            reset();
        }
    }, [isOpen]);

    const recipientOptions = users.filter(x => x.id !== meId);

    if (!isOpen) {
        return <></>;
    }

    return (
        <MobileModal
            isOpen={isOpen}
            close={close}
            title={'Add cooking points donation'}
        >
            <MobileModalContent>
                <Col>
                    <StyledRow style={{ marginTop: spacing.base }}>
                        <Label>Amount:</Label>
                        <Value>
                            <AmountCounter
                                amount={formValues.amount}
                                onClickMinus={() => handleAmountChange(minimum(formValues.amount - 1, 1))}
                                onClickPlus={() => handleAmountChange(formValues.amount + 1)}
                            />
                        </Value>
                    </StyledRow>
                    <StyledRow  style={{ marginBottom: spacing.base }}>
                        <Select
                            title={'Recipient'}
                            value={formValues.recipient}
                            data={recipientOptions}
                            labelExtractor={(x) => x.username}
                            onChange={handleRecipientChange}
                        />
                    </StyledRow>
                </Col>
            </MobileModalContent>
            <MobileModalBottom>
                <MobileModalButtonRow>
                    <Button text={'Cancel'} color={'secondary'} onClick={close} style={{ marginRight: spacing.base }} />
                    <Button text={'Save'} onClick={() => handleSubmit(close)} />
                </MobileModalButtonRow>
            </MobileModalBottom>
        </MobileModal>
    );
};

export default React.memo(CreateDonationModal);