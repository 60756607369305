import styled from 'styled-components';

export const Page = styled.div`
    padding: 10px;
`;

export const TableWrapper = styled.div`
    overflow-y: auto;
    overflow-x: hidden; 
    height: ${props => props.height ? props.height : '250px'};
`;

export const Table = styled.table`
    font-family: arial, sans-serif;
    font-size: 12px;
    border-collapse: collapse;
    width: 100%;
`;

export const THead = styled.thead`
    position: sticky; 
    top: 0;
    background-color: #dddddd;
`;

export const Tr = styled.tr`
    :nth-child(even) {
        background-color: #dddddd;
    } 
`;

export const Td = styled.td`
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
`;

export const Th = styled.th`
    position: sticky; 
    top: 0;
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    ${props => props.width && `width: ${props.width}px;`}
`;

export const ClickableText = styled.span`
    cursor: pointer;
`;