import SortableTable from "../common/SortableTable";
import React from "react";
import {Td, Tr} from "../../styles/Page";

const PrintingTable = ({content = [], getPrintingJobs = () => {}, page = 0, last = true}) => {

    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom && !last) {
            getPrintingJobs(page + 1);
        }
    };
    const buildHeaders = () => {
        return [
            {name: 'Datum', sortable: true, sortKey: 'date'},
            {name: 'Gebruiker', sortable: true, sortKey: 'user'},
            {name: 'Bestandsnaam', sortable: false}
        ];
    }

    const convertRow = (row) => {
        return (
            <Tr key={row.id}>
                <Td>{row.date}</Td>
                <Td>{row.user.username}</Td>
                <Td>{row.fileName}</Td>
            </Tr>
        );
    };

    return (
        <SortableTable
            handleScroll={handleScroll}
            headers={buildHeaders()}
            content={content}
            convertRow={convertRow}
            editable={false}
        />
    );
};

export default PrintingTable;
