import React from "react";
import styled from "styled-components";
import CookingEnrollmentRow from "./CookingEnrollmentRow";
import {getKeyFromIds} from "../../../../util/Helpers";
import dayjs from "dayjs";

const FormWrapper = styled.div`
    margin-top: 10px;
`;

const nrOfColumns = 3;

const CookingEnrollmentForm = ({users = [], event, myId, enroll}) => {
    const meIsCook = event.cook?.id === myId;
    const isPast = dayjs(event.date).isBefore(dayjs().startOf('day'));

    const nrOfRows = Math.ceil(users.length / nrOfColumns);

    const getRowsArray = () => {
        // Initialise arrays
        const userRows = [];
        const enrollmentRows = [];
        for (let i=0; i<nrOfRows; i++) {
            userRows.push([]);
            enrollmentRows.push([]);
        }
        // Set base grid values
        let currentCol = 0;
        let currentRow = 0;
        // Loop through all users
        for (let i=0; i<users.length && currentCol < nrOfColumns; i++) {
            // Get user and its enrollment
            const user = users[i];
            const enrollment = event.enrolled.find((x) => x.user.id === user.id);
            // Set data in grid
            userRows[currentRow].push(users[i]);
            enrollmentRows[currentRow].push(enrollment);
            // Go to next row and maybe next column
            if (currentRow + 1 === nrOfRows) {
                currentCol += 1;
            }
            currentRow = ((currentRow + 1) % nrOfRows);
        }

        const result = [];
        for (let i=0; i<userRows.length; i++) {
            const users = userRows[i];
            const enrollments = enrollmentRows[i];
            result.push(
                <CookingEnrollmentRow
                    key={getKeyFromIds(users)}
                    users={users}
                    enrollments={enrollments}
                    myId={myId}
                    meIsCook={meIsCook}
                    isPast={isPast}
                    enroll={enroll}
                />
            );
        }
        return result;
    }

    return (
        <FormWrapper>
            {getRowsArray()}
        </FormWrapper>
    );
};

export default CookingEnrollmentForm;
