import React from "react";
import {func} from "../../../util/Helpers";
import {Table, TableWrapper, Td, Th, THead, Tr} from "../../styles/Page";

const DefaultTable = ({ headers = [], content = [], convertRow = func, handleScroll = func, wrapperProps }) => {
    return (
        <TableWrapper {...wrapperProps} onScroll={handleScroll}>
            <Table>
                <THead>
                    <Tr>
                        {headers?.map(h => (
                            <Th key={h.name} width={h.width}>
                                {h.name}
                            </Th>
                        ))}
                    </Tr>
                </THead>
                <tbody>
                    {content.length === 0 && (
                        <Tr>
                            <Td colSpan={headers.length} style={{ textAlign: 'center' }}>
                                Geen Data Beschikbaar
                            </Td>
                        </Tr>
                    )}
                    {content.map((x, i) => convertRow(x, i))}
                </tbody>
            </Table>
        </TableWrapper>
    );
};

export default React.memo(DefaultTable);
