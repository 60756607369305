import {useEffect} from "react";
import {getMyNotificationsRequest} from "../network/NotificationRequests";

function useShowNotifications(jwt = '') {

    useEffect(() => {
        if (jwt) {
            // Execute notification request and show the result
            getMyNotificationsRequest().then(result => {
                if (result) {
                    result.forEach(x => alert(x.message));
                }
            })
        }
    }, [jwt]);

}

export default useShowNotifications;