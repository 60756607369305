import styled from 'styled-components';

export const FlexCenterContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export const Title = styled.h3`
    padding: 0px;
    margin: 0px;
    font: 100% Verdana;
    white-space: nowrap;
    font-weight: bold;
    color: white;
    padding-top: 2px;
    margin-left: 38px;
    font-size: 0.95em;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

export const W50 = styled.div`
    width: 50%;
`;
