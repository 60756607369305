import React, {useContext, useEffect, useState} from "react";
import PageWrapper from "../../PageWrapper";
import {UsersContext} from "../../../../shared/context/UsersContext";
import styled from "styled-components";
import {MeContext} from "../../../../shared/context/MeContext";
import EditUserModal from "./EditUserModal";
import CreateUserModal from "./CreateUserModal";
import {useHistory} from "react-router-dom";
import {handleJwt} from "../../../../util/Jwt";
import {Page, Td, Tr} from "../../../styles/Page";
import DefaultTable from "../../common/DefaultTable";


const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 15px;
`;

const headers = [
    {
        name: 'ID',
        width: 40,
    },
    {
        name: 'Naam',
        width: 80,
    },
    {
        name: 'Rollen',
    },
    {
        name: 'Actief',
        width: 40,
    },
    {
        name: '',
        width: 30,
    },
];

const UsersPage = () => {

    const history = useHistory();

    const { me, loadMe, impersonate } = useContext(MeContext);
    const { allUsers, loadAllUsers, createUser, updateUser } = useContext(UsersContext);

    const [ showCreateModal, setShowCreateModal ] = useState(false);
    const [ showEditModal, setShowEditModal ] = useState(false);
    const [ selectedUser, setSelectedUser ] = useState(null);

    useEffect(() => {
        loadMe();
        loadAllUsers();
    }, []);

    const editUser = (id, data, closeAction) => {
        const onSuccess = () => {
            closeAction();
            const forceReload = id === me.id;
            loadMe(forceReload);
        };
        updateUser(id, data, onSuccess);
    }

    const handleCreateClick = () => {
        setShowCreateModal(true);
    };

    const handleEditClick = (user) => {
        setSelectedUser(user);
        setShowEditModal(true);
    };

    const handleImpersonateClick = (userId) => {
        impersonate(userId, (result) => handleJwt(result, history, () => loadMe(true)));
    };

    const produceRow = (x) => {
        return (
            <Tr key={x.id}>
                <Td>{x.id}</Td>
                <Td>{x.username}</Td>
                <Td>{x.roles?.join(', ')}</Td>
                <Td>{x.active ? 'Ja' : 'Nee'}</Td>
                <Td>
                    <span onClick={() => handleEditClick(x)} style={{ cursor: 'pointer' }}>&#128393;</span>
                    &nbsp;
                    <span onClick={() => handleImpersonateClick(x.id)} style={{ cursor: 'pointer' }}>&#128100;</span>
                </Td>
            </Tr>
        );
    };

    return (
        <PageWrapper>
            <Page>
                <DefaultTable
                    headers={headers}
                    convertRow={produceRow}
                    content={allUsers}
                />
                <ButtonWrapper>
                    <button
                        type={'button'}
                        onClick={handleCreateClick}
                    >
                        Nieuw
                    </button>
                </ButtonWrapper>
                <CreateUserModal
                    isOpen={showCreateModal}
                    closeAction={() => setShowCreateModal(false)}
                    createAction={createUser}
                />
                <EditUserModal
                    isOpen={showEditModal}
                    closeAction={() => setShowEditModal(false)}
                    user={selectedUser}
                    editAction={editUser}
                />
            </Page>
        </PageWrapper>
    );
};

export default UsersPage;