import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {Row} from "../../styles/Layout";
import {useHistory} from "react-router";

const Wrapper = styled.div`
    display: flex;
    margin-top: 10px;
    border-top: 1px solid #C0C0C0;
`;

const CustomRow = styled(Row)`
    display: flex;
    flex: 1;
    
    margin-top: 10px;
    font: 100% Verdana;
    
    justify-content: space-between;
`;

const Form = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 150px;
`;

const Input = styled.input`
    margin-left: 5px;
    margin-right: 3px;
    width: ${props => props.small ? 10 : 20}px;
`;

const CookingPriceForm = ({ cookingEventId = -1, amount = 0, shouldCreate = true, createTransaction = () => {}, editTransaction = () => {}, openActionsModal = () => {} }) => {

    const centsRef = useRef();
    const history = useHistory();
    const parts = amount.toFixed(2).split('.');

    const [formValues, setFormValues] = useState({
        euros: '0',
        cents: '00'
    });

    useEffect(() => {
        setFormValues({
            euros: parts[0],
            cents: !parts[1] ? '00' : parts[1]
        });
    }, [parts[0], parts[1]]);

    const handleEurosChange = (event) => {
        setFormValues({...formValues, euros: event.target.value})
    };

    const handleCentsChange = (event) => {
        if (event.target.value.length < 3) {
            setFormValues({...formValues, cents: event.target.value})
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        } else if (event.key === '.' || event.key === ',') {
            event.preventDefault();  // This is so we don't have a '.' or ',' in the input
            centsRef.current.select();
        }
    };

    const handleSubmit = () => {
        const price = parseFloat(`${formValues.euros}.${formValues.cents}`);
        if (price >= 0) {
            shouldCreate
                ? createTransaction(cookingEventId, price, goToNewTransaction)
                : editTransaction(cookingEventId, price, goToNewTransaction)
        }
    };

    const goToNewTransaction = (newId) => {
        history.push(`/geldlijst/${newId}`);
    };

    return (
        <Wrapper>
            <CustomRow>
                <button
                    type={'button'}
                    title={'Acties'}
                    onClick={openActionsModal}
                >
                    ☰
                </button>
                <Form>
                    &euro;
                    <Input
                        type={'text'}
                        value={formValues.euros}
                        onChange={handleEurosChange}
                        onKeyDown={handleKeyDown}
                    />
                    ,
                    <Input
                        type={'text'}
                        value={formValues.cents}
                        onChange={handleCentsChange}
                        onKeyDown={handleKeyDown}
                        ref={centsRef}
                    />
                    <button
                        type={'button'}
                        onClick={handleSubmit}
                    >
                        Ok
                    </button>
                </Form>
            </CustomRow>
        </Wrapper>
    );
};

export default CookingPriceForm;