import React, {useEffect, useState} from "react";
import ModifyUserModal from "./ModifyUserModal";
import {areEqual} from "../../../../mobile/util/Utils";

const EditUserModal = ({isOpen = false, closeAction = () => {}, user = null, editAction = () => {}}) => {

    const [title, setTitle] = useState("");

    useEffect(() => {
        if (user?.id) {
            setTitle(`Edit user: ${user?.username}`);
        }
    }, [user?.id]);

    const modifyAction = (formValues) => {
        const valuesToPut = {
            ...formValues,
            password: formValues.password === "" ? undefined : formValues.password
        };
        editAction(user?.id, valuesToPut, closeAction);
    };

    const validateFormValues = (formValues) => {
        if (!user)
            return false;

        const valuesEqual = (
            formValues.username === user.username
            && areEqual(formValues.roles, user.roles)
            && formValues.active === user.active
        );
        return formValues.username && (formValues.password || !valuesEqual);
    };

    return (
        <ModifyUserModal
            title={title}
            user={user}
            isOpen={isOpen}
            closeAction={closeAction}
            modifyAction={modifyAction}
            validateFormValues={validateFormValues}
        />
    );
};

export default EditUserModal;
