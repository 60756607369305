import React, { createContext, useState, useContext } from 'react';
import { createCookingDonationRequest, getCookingDonationsRequest } from '../network/CookingDonationRequests';
import { CookingBalanceContext } from './CookingBalanceContext';
import {mergeArraysById} from "../util/ArrayUtils";
import {genericSortDesc} from "../util/SortUtils";

const initialPage = {
    content: [],
    empty: true,
    first: true,
    last: true,
    number: 0,
    numberOfElements: 0,
    pageable: {
        offset: 0,
        pageSize: 20,
        paged: true,
        sort: {
            empty: false,
            sorted: true,
            unsorted: false,
        },
        unpaged: false,
    },
    size: 0,
    sort: {
        empty: false,
        sorted: true,
        unsorted: false,
    },
    totalElements: 0,
    totalPages: 1,
};

export const CookingDonationContext = createContext({});

const CookingDonationContextProvider = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [cookingDonationsPage, setCookingDonationsPage] = useState(initialPage);

    const { getCookingBalanceOverview } = useContext(CookingBalanceContext);

    const getCookingDonations = async (page = 0, onSuccess = () => {}) => {
        setIsLoading(true);
        const result = await getCookingDonationsRequest(page);
        if (result) {
            setCookingDonationsPage({
                ...result,
                content: mergeArraysById(cookingDonationsPage.content, result.content).sort((a,b) => genericSortDesc(a,b,'id')),
            });
            onSuccess();
        }
        setIsLoading(false);
    };

    const createCookingDonation = async (data, onSuccess = () => {}) => {
        setIsLoading(true);
        const result = await createCookingDonationRequest(data);
        if (result) {
            setCookingDonationsPage({
                ...cookingDonationsPage,
                content: [result, ...cookingDonationsPage.content].sort((a,b) => genericSortDesc(a,b,'id')),
            });
            getCookingBalanceOverview();
            onSuccess();
        }
        setIsLoading(false);
    };

    return (
        <CookingDonationContext.Provider value={{
            isLoading,
            cookingDonationsPage,
            getCookingDonations,
            createCookingDonation,
        }}
        >
            {props.children}
        </CookingDonationContext.Provider>
    );
};

export default CookingDonationContextProvider;
