import React from "react";
import styled from "styled-components";
import CheckOrangeImage from "../../../images/checkbox/check_orange.png";
import CheckGreyNone from "../../../images/checkbox/check_none_14px.png";
import CheckCrossed from "../../../images/checkbox/check_crossed.png";
import ArrowUp from "../../../images/arrow/arrows_up.png";
import ArrowDown from "../../../images/arrow/arrows_down.png";

const CheckBox = styled.div`
    background-image: url(${props => props.backgroundImage});
    ${props => !props.disabled && 'cursor: pointer;'}
    background-repeat: no-repeat;
    width: 14px;
    height: 14px;
    margin-top: 2px;
    margin-left: 2px;
    margin-right: 2px;
`;

const CheckBoxWrapper = styled.div`
    margin-left: 2px;
    margin-right: 5px;
`;

const Arrow = styled.div`
    background-image: url(${props => props.up ? ArrowUp : ArrowDown});
    ${props => !props.disabled && 'cursor: pointer;'}
    background-repeat: no-repeat;
    width: 8px;
    height: 16px;
`;

const ArrowsWrapper = styled.div`
    width: 17px;
    display: flex;
    flex-direction: row;
`;

const Name = styled.div`
    font-family: Verdana;
    font-size: 16px;
    font-weight: 400;
`;

// When amountEnrolled is undefined, it should be displayed as not checked
// When amountEnrolled is 0, it should be displayed as crossed
// When amountEnrolled is greater than 0 is should be displayed as checked

const CookingEnrollmentCell = ({ userId, username, amountEnrolled, isMyEnrollment, meIsCook, isPast, enroll }) => {

    const onArrowDownClick = () => {
        if (canEdit) {
            if (amountEnrolled === undefined) {
                enroll(userId, 0);
                return;
            }
            if (amountEnrolled - 1 >= 0) {
                enroll(userId, amountEnrolled - 1);
                return;
            }
        }
    };

    const onArrowUpClick = () => {
        if (canEdit) {
            if (amountEnrolled === undefined) {
                enroll(userId, 1);
            } else {
                enroll(userId, amountEnrolled + 1);
            }
        }
    };

    const onCheckBoxClick = () => {
        if (canEdit) {
            enroll(userId, amountEnrolled > 0 ? 0 : 1);
        }
    };

    const getNameLabel = () => {
        if (amountEnrolled > 1) {
            return `${username}(${amountEnrolled})`;
        }
        return username;
    };

    const getCheckboxImage = () => {
        if (amountEnrolled > 0) {
            return CheckOrangeImage;
        }
        if (amountEnrolled === 0) {
            return CheckCrossed;
        }
        return CheckGreyNone;
    }

    const canEdit = meIsCook || (isMyEnrollment && !isPast);

    return (
        <React.Fragment>
            <ArrowsWrapper>
                {canEdit && (
                    <React.Fragment>
                        <Arrow up={false} onClick={onArrowDownClick} />
                        <Arrow up={true} onClick={onArrowUpClick} />
                    </React.Fragment>
                )}
            </ArrowsWrapper>
            <CheckBoxWrapper>
                <CheckBox
                    backgroundImage={getCheckboxImage()}
                    onClick={onCheckBoxClick}
                    disabled={!canEdit}
                />
            </CheckBoxWrapper>
            <Name>
                {getNameLabel()}
            </Name>
        </React.Fragment>
    );
};

export default CookingEnrollmentCell;