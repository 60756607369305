import {useContext, useState} from "react";
import {CookingDonationContext} from "../context/CookingDonationContext";

const initialFormValues = {
    recipient: "",
    amount: 1
};

function useCookingDonationForm() {

    const [formValues, setFormValues] = useState(initialFormValues);

    const { createCookingDonation } = useContext(CookingDonationContext);

    const handleRecipientChange = (event) => {
        setFormValues({
            ...formValues,
            recipient: event.target.value
        });
    };

    const handleAmountChange = (input) => {
        setFormValues({
            ...formValues,
            amount: input
        });
    };

    const handleSubmit = (onSuccess) => {
        const data = {
            amount: parseInt(formValues.amount),
            recipient: parseInt(formValues.recipient)
        }
        if (isNaN(data.amount)) {
            alert('Please enter a valid amount');
            return;
        }
        if (isNaN(data.recipient)) {
            alert('Please select a recipient');
            return;
        }
        createCookingDonation(data, onSuccess);
    };

    const reset = () => {
        setFormValues(initialFormValues);
    };

    return { formValues, setFormValues, handleRecipientChange, handleAmountChange, handleSubmit, reset };

}

export default useCookingDonationForm;