import React from "react";
import { Checkbox as MaterialCheckbox } from "@material-ui/core";
import {Row} from "./Grid";
import styled from "styled-components";

const CustomRow = styled(Row)`
    align-items: center;
`;

const Checkbox = ({ text = '', rowStyle, checked = false, ...props }) => {
    return (
        <CustomRow style={rowStyle}>
            <MaterialCheckbox color={'primary'} checked={checked} style={{ paddingLeft: '0px', paddingTop: '0px', paddingBottom: '5px' }} {...props} />
            <span>{text}</span>
        </CustomRow>
    )
};

export default Checkbox;