import React, {useContext, useEffect, useState} from "react";
import MobilePageWrapper from "../MobilePageWrapper";
import {MeContext} from "../../../shared/context/MeContext";
import CookingToday from "./CookingToday";
import {CookingEventContext} from "../../../shared/context/CookingEventContext";
import dayjs from "dayjs";
import ActionBar from "../ActionBar";
import {routes} from "../../../shared/routing/Routes";
import CookingNext from "./CookingNext";
import {CookingBalanceContext} from "../../../shared/context/CookingBalanceContext";
import {TransactionBalanceContext} from "../../../shared/context/TransactionBalanceContext";
import SmallList from "../../styles/SmallList";
import Button from "../../styles/Button";
import BalanceListItem from "./BalanceListItem";
import styled from "styled-components";
import {spacing} from "../../util/Spacing";
import {useHistory} from "react-router";

const ButtonWrapper = styled.div`
    margin-top: ${spacing.small}px;
    margin-left: ${spacing.base}px;
    margin-right: ${spacing.base}px;
    margin-bottom: ${spacing.base}px;
`;

const MobileHomePage = () => {

    const history = useHistory();

    const [ cookingEvent, setCookingEvent ] = useState(null);

    const { loadMe } = useContext(MeContext);
    const { getCookingEvent } = useContext(CookingEventContext);
    const { cookingBalanceOverview, getCookingBalanceOverview } = useContext(CookingBalanceContext);
    const { transactionBalanceOverview, getTransactionBalanceOverview } = useContext(TransactionBalanceContext);

    useEffect(() => {
        loadMe();
        getCookingEvent(dayjs().format('YYYY-MM-DD'), (result) => setCookingEvent(result));
        getCookingBalanceOverview();
        getTransactionBalanceOverview();
    }, []);

    return (
        <MobilePageWrapper>
            <ActionBar activeRoute={routes.home}>
                <CookingToday cook={cookingEvent?.cook} enrollments={cookingEvent?.enrolled} />
                <CookingNext overview={cookingBalanceOverview} />
                <SmallList
                    title={'Balance'}
                    data={transactionBalanceOverview}
                    renderItem={(item, index) => (
                        <BalanceListItem
                            key={item.user.id}
                            index={index}
                            imageId={item.user.image}
                            username={item.user.username}
                            balance={item.balance}
                        />
                    )}
                >
                    <ButtonWrapper>
                        <Button text={'Money List'} onClick={() => history.push(routes.moneyList)} style={{ width: '100%' }} />
                    </ButtonWrapper>
                </SmallList>
            </ActionBar>
        </MobilePageWrapper>
    );
};

export default MobileHomePage;
