import Axios from "axios";
import {constants} from "../../../../util/Constants";
import {defaultAxiosErrorHandler} from "../../../util/RequestUtils";

const controllerPath = 'room-soliciting-reviews';

export const getRoomSolicitorReviewsBySolicitorIdRequest = (id) => Axios({
    method: 'get',
    url: `${constants.apiBaseUrl}/${controllerPath}/solicitor/${id}`,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const postRoomSolicitorReviewRequest = (data) => Axios({
    method: 'post',
    url: `${constants.apiBaseUrl}/${controllerPath}`,
    data,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const putRoomSolicitorReviewRequest = (id, data) => Axios({
    method: 'put',
    url: `${constants.apiBaseUrl}/${controllerPath}/${id}`,
    data,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);