import {CentsReadonly,CustomRow,DescriptionReadonly,EurosReadonly,Wrapper} from "../../styles/TransactionForm";

const TransactionDetails = ({ transaction }) => {
    const { description, amount } = transaction;
    const parts = amount.toFixed(2).split('.');

    return (
        <Wrapper>
            <CustomRow>
                <DescriptionReadonly title={description} readOnly type={'text'} value={description} />
                &euro;
                <EurosReadonly readOnly type={'text'} value={parts[0]} />
                ,
                <CentsReadonly readOnly type={'text'} value={parts[1]} />
            </CustomRow>
        </Wrapper>
    );
};

export default TransactionDetails;
