import React, {useState} from "react";
import ReactModal from 'react-modal';
import {colors} from "../../util/Colors";
import {screenSize} from "../../util/MediaQuery";
import useWindowDimensions from "../../shared/hooks/WindowDimensionsHook";

const overlay = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(51, 51, 51, 0.75)'
};

const baseContent = {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    background: colors.white,
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    outline: 'none',
    padding: 0,
};

const desktopContent = {
    ...baseContent,
    width: '30%',
    top: '96px',
    left: '35%',
    right: '35%',
    bottom: '96px',
    border: '1px solid #ccc',
    borderRadius: '4px',
}

const CustomModal = (props) => {
    const {
        isOpen = false,
        setIsOpen = (value) => {}
    } = props;

    // Needed to fix chrome bug on mobile where the viewport height and width are only
    // correct after re-render
    const [ count, setCount ] = useState(0);

    const { height, width } = useWindowDimensions();

    const isMobile = window.innerWidth < screenSize.sm;

    const modalContentStyle = isMobile ?
        {
            ...baseContent,
            position: 'fixed',
            borderRadius: 0,

            left: 0,
            right: 0,
            top: 0,
            bottom: 0,

            maxWidth: `${width}px`,
            maxHeight: `${height}px`,
        }
        : desktopContent;

    return (
        <ReactModal
            style={{ overlay: overlay, content: modalContentStyle }}
            isOpen={isOpen}
            onRequestClose={() => setIsOpen(false)}
            appElement={document.getElementById('root')}
            // Prevent scrolling on page when modal is open
            onAfterOpen={() => {
                //TODO: reset to previous x and y (for mobile view)
                document.body.style.overflow = 'hidden';
                document.body.setAttribute('style', 'position: fixed');
                // Needed to fix chrome bug on mobile where the viewport height and width are only
                // correct after re-render
                setCount(count + 1);
            }}
            onAfterClose={() => document.body.removeAttribute('style')}
        >
            {props.children}
        </ReactModal>
    );
};

export default CustomModal;