import React, {useContext, useEffect, useState} from 'react';
import {MeContext} from "../../../shared/context/MeContext";
import {FERow} from "../../styles/Grid";
import Button from "../../styles/Button";
import Toast from "../../styles/Toast";
import {capitalizeFirstChar} from "../../../util/Helpers";
import { Checkbox as MaterialCheckbox } from "@material-ui/core";
import {areEqual} from "../../util/Utils";

const ProfileAutoEnrollForm = ({ username = '', autoEnroll }) => {

    const { updateMe } = useContext(MeContext);

    const [ showToast, setShowToast ] = useState(false);
    const [ saveDisabled, setSaveDisabled] = useState(true);

    const initialValues = { username, autoEnroll };
    const [formValues, setFormValues] = useState(initialValues);

    useEffect(() => {
        setFormValues({
            ...formValues,
            username,
            autoEnroll,
        });
    }, [ username, autoEnroll ]);

    useEffect(() => {
        setSaveDisabled(areEqual(formValues, initialValues))
    }, [ formValues ])

    const handleSubmit = () => {
        updateMe(formValues, () => setShowToast(true));
    };

    return (
        <>
            <table style={{ width: '100%', paddingTop: 30, paddingBottom: 20 }}>
                <thead>
                <tr>
                    <th style={{ fontWeight: 'initial', textAlign: 'left' }}>Day</th>
                    <th style={{ fontWeight: 'initial', textAlign: 'left', width: '15%' }}>No</th>
                    <th style={{ fontWeight: 'initial', textAlign: 'center', width: '15%' }}>Off</th>
                    <th style={{ fontWeight: 'initial', textAlign: 'right', width: '15%' }}>Yes</th>
                </tr>
                </thead>
                <tbody>
                {Object.keys(formValues.autoEnroll).map(day => (
                    <tr key={day}>
                        <td>{capitalizeFirstChar(day)}</td>
                        <td style={{ textAlign: 'left' }}>
                            <MaterialCheckbox
                                color={'primary'}
                                checked={formValues.autoEnroll[day] === false}
                                onChange={() => setFormValues({ ...formValues, autoEnroll: { ...formValues.autoEnroll, [day]: false } })}
                                style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
                            />
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <MaterialCheckbox
                                color={'primary'}
                                checked={formValues.autoEnroll[day] === null}
                                onChange={() => setFormValues({ ...formValues, autoEnroll: { ...formValues.autoEnroll, [day]: null } })}
                                style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
                            />
                        </td>
                        <td style={{ textAlign: 'right' }}>
                            <MaterialCheckbox
                                color={'primary'}
                                checked={formValues.autoEnroll[day] === true}
                                onChange={() => setFormValues({ ...formValues, autoEnroll: { ...formValues.autoEnroll, [day]: true } })}
                                style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
                            />
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            <FERow>
                <Button disabled={saveDisabled} text={'Save'} onClick={handleSubmit} />
            </FERow>
            <Toast isOpen={showToast} close={() => setShowToast(false)} text={'Profile updated'} snackbarProps={{ style: { bottom: 75 } }}/>
        </>
    );
};

export default ProfileAutoEnrollForm;