import Axios from "axios";
import {constants} from "../../util/Constants";
import {defaultAxiosErrorHandler} from "../util/RequestUtils";


export const getAverageCostRequest = () => Axios({
    method: 'get',
    url: `${constants.apiBaseUrl}/stats/average-costs`,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const getAverageCostPerMonthRequest = () => Axios({
    method: 'get',
    url: `${constants.apiBaseUrl}/stats/average-costs-per-month`,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const getForgottenTransactionsRequest = () => Axios({
    method: 'get',
    url: `${constants.apiBaseUrl}/stats/forgotten-transactions`,
}).then(response => response.data).catch(defaultAxiosErrorHandler);

export const getDryerCountRequest = () => Axios({
    method: 'get',
    url: `${constants.apiBaseUrl}/stats/dryer-count`,
}).then(response => response.data).catch(defaultAxiosErrorHandler);
