import React from "react";
import TransactionTableBodyRow from "./TransactionTableBodyRow";
import TransactionTableInputRow from "./TransactionTableInputRow";
import styled from "styled-components";

const TBody = styled.tbody`
    height: 240px;
    overflow-y: scroll;
    overflow-x: hidden;
    display: block;
    
    margin-top: -2px;
`;

const TransactionTableBody = ({users = [], transactions, currentTransactionId, getTransactions, page, last}) => {
    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom && !last) {
            getTransactions(page + 1);
        }
    };

    return (
        <TBody onScroll={handleScroll}>
            <TransactionTableInputRow key={0} active={currentTransactionId === -1} amountOfUsers={users.length} />
            {transactions?.map(x => (
                <TransactionTableBodyRow
                    key={x.id}
                    users={users}
                    transaction={x}
                    active={currentTransactionId === x.id}
                />
            ))}
        </TBody>
    );
};

export default TransactionTableBody;