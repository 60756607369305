import React, {useState, useEffect, useContext} from "react";
import styled from "styled-components";
import MobilePageWrapper from "../MobilePageWrapper";
import ActionBar from "../ActionBar";
import {routes} from "../../../shared/routing/Routes";
import { RgbColorPicker } from "react-colorful"
import {colors} from "../../../util/Colors";
import {CookingBalanceContext} from "../../../shared/context/CookingBalanceContext";
import {TransactionBalanceContext} from "../../../shared/context/TransactionBalanceContext";
import {MeContext} from "../../../shared/context/MeContext";
import { changeBrightnessRequest, changeColorRequest, customRequest } from "../../../shared/network/LedsRequests";
import {isAdmin} from "../../../util/Helpers";

const Header = styled.div`
    margin: 10px;
    text-align: center;
    color: white;
    background: ${colors.orange};
    border-radius: 5px;
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

const Center = styled.div`
    display: flex;
    justify-content: center;
`;

const Button = styled.div`
    margin: 2px;
    text-align: center;
    color: white;
    cursor: pointer;

    :hover {
       background-color: white;
       color: #f8941d;
    }
`;


const CheckboxContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
`;



const Card = styled.div`
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    height: 10em;
    gap: 0.5em;
    margin: 0.5em;
    border-radius: 30px;
    background: #FFF;
    box-shadow: 4px 8px 2px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    &:hover {
        filter: brightness(0.8);
    }
`;

const Image = styled.div`
    border-radius: 30px 30px 0 0;
    height: 80%;
    align-self: stretch;
    flex-shrink: 0;
    background: url(${props => props.src}) no-repeat center;
    background-size: cover;
`;

const MobileLedsPage = () => {

    const { me, loadMe } = useContext(MeContext);

    useEffect(() => {
        loadMe();
    }, []);

    const { getMyCookingBalance } = useContext(CookingBalanceContext);
    const { getMyTransactionBalance } = useContext(TransactionBalanceContext);

    useEffect(() => {
        if (me.id !== -1) {
            getMyCookingBalance();
            getMyTransactionBalance();
        }
    }, [me.id]);

    // For led strip specifics
    const [show, setShow] = useState(false);

    const toggleShow = () => {
        setShow(!show);
    };

    const [isCheckedMain, setIsCheckedMain] = useState(true);

    const handleOnChangeMain = () => {
      setIsCheckedMain(!isCheckedMain);
    };

    const [isCheckedBar, setIsCheckedBar] = useState(true);

    const handleOnChangeBar = () => {
      setIsCheckedBar(!isCheckedBar);
    };

    const [isCheckedHallway, setIsCheckedHallway] = useState(true);

    const handleOnChangeHallway = () => {
      setIsCheckedHallway(!isCheckedHallway);
    };

    const [color, setColor] = useState({ r: 255, g: 50, b: 0 });
    
    useEffect(() => {
        const timeOutId = setTimeout(() => changeColorRequest(color, isCheckedMain, isCheckedBar, isCheckedHallway), 300);
        return () => clearTimeout(timeOutId)
    }, [color])
    
    const [brightness, setBrightness] = useState(0.5)
    
    const changeBrightness = (event) => {
        setBrightness(event.target.value);
      };
      
    useEffect(() => {
        const timeOutId = setTimeout(() => changeBrightnessRequest(brightness, isCheckedMain, isCheckedBar, isCheckedHallway), 300);
        return () => clearTimeout(timeOutId)
    }, [brightness])

    return (
        <MobilePageWrapper>
            <ActionBar activeRoute={routes.leds}>
                <Container>
                    <Header>Verander kleur</Header>
                    <Center>
                        <RgbColorPicker color={color} onChange={setColor} />
                    </Center>
                    <Header>Verander helderheid</Header>
                    <Center>
                        <input
                            type='range'
                            onChange={changeBrightness}
                            min={0}
                            max={1}
                            step={0.005}
                            value={brightness}>
                        </input>
                    </Center>

                    <Header>Selecteer een programma</Header>

                    <Card onClick={() => customRequest('rainbow')}>
                        <Image src="https://www.theglowhouse.com/images/large/RainbowNeonLight.jpg"/>
                        Rainbow
                    </Card>
                    <Card onClick={() => customRequest('movie')}>
                        <Image src="https://cdn.cnn.com/cnnnext/dam/assets/210406161315-01-movie-theater-covid-0315-super-tease.jpg"/>
                        Movie
                    </Card>


                    <Button onClick={toggleShow}>
                    <Header>Kies een specifieke ledstrip</Header>
                    </Button>
                    {show && <CheckboxContainer>
                    
                        <div>
                            <div style={{margin: "5px"}}>Main</div>
                            <input
                                type="checkbox"
                                checked={isCheckedMain}
                                onChange={handleOnChangeMain}
                            />
                        </div>

                        <div>
                        <div style={{margin: "5px"}}>Bar</div>
                            <input
                                type="checkbox"
                                checked={isCheckedBar}
                                onChange={handleOnChangeBar}
                            />
                        </div>

                        <div>
                        <div style={{margin: "5px"}}>Gang</div>
                            <input
                                type="checkbox"
                                checked={isCheckedHallway}
                                onChange={handleOnChangeHallway}
                            />
                        </div>
                    
                    </CheckboxContainer>}

                    {isAdmin(me) && (
                        <Header>
                            <Button onClick={() => alert("Nog niet geïmplementeerd")}>Voeg programma toe</Button>
                        </Header>
                    )}
                </Container>
            </ActionBar>
        </MobilePageWrapper>
    )
}

export default MobileLedsPage;