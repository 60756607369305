import React from "react";
import {Half, PanelBody, PanelBottom, PanelContainer, PanelTop} from "../../styles/Panel";
import {Title} from "../../styles/Layout";
import {sortByBalanceDescAndUserIdAsc} from "../../../shared/util/SortUtils";

const ShuttleDrunkTestOverviewPanel = ({ overview = [] }) => {
    return (
        <PanelContainer>
            <PanelTop>
                <Title>Overzicht</Title>
            </PanelTop>
            <PanelBody>
                {overview?.sort(sortByBalanceDescAndUserIdAsc)?.map((x) => (
                    <React.Fragment key={x.user.id}>
                        <Half>{x.user.username}</Half>
                        <Half>{x.balance}</Half>
                    </React.Fragment>
                ))}
            </PanelBody>
            <PanelBottom />
        </PanelContainer>
    );
}

export default ShuttleDrunkTestOverviewPanel;