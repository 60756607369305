import React, {useContext} from "react";
import {PanelBody, PanelBottom, PanelDivider, PanelTop} from "../../styles/Panel";
import {Title} from "../../styles/Layout";
import styled from 'styled-components';
import NavButton from "./NavButton";
import {MeContext} from "../../../shared/context/MeContext";
import {isAdmin} from "../../../util/Helpers";
import {routes} from "../../../shared/routing/Routes";

const Name = styled.h4`
    font: 100% Verdana;
    color: white;
    font-weight: bold;
    white-space: no-wrap;
    margin-left: 21px;
    margin-bottom: 0px;
    margin-right: 0px;
    margin-top: 5px;
    padding-top: 1px;
    font-size: 1.5em;
`;

const SideNav = () => {
    const { me } = useContext(MeContext);

    return (
        <React.Fragment>
            <PanelTop>
                <Title>Welkom</Title>
            </PanelTop>
            <PanelBody>
                <Name>{me ? me.username : 'Not Found'}</Name>
                <PanelDivider />
                <NavButton text={'Kookagenda'} to={routes.cookingCalender}/>
                <NavButton text={'Geldlijst'} to={routes.moneyList}/>
                <NavButton text={'Bierlijst'} to={routes.beerList}/>
                <NavButton text={'LEDs'} to={routes.leds}/>
                <NavButton text={'Statistieken'} to={routes.stats}/>
                <NavButton text={'Iris'} to={routes.iris}/>
                <NavButton text={'Shuttle drunk test'} to={routes.shuttleDrunkTest}/>
                <NavButton text={'Albums'} to={routes.albums}/>
                <NavButton text={'Donaties'} to={routes.donations}/>
                <NavButton text={'Printen'} to={routes.printing} />
                <NavButton text={'Hospitaties'} to={routes.roomSoliciting} />
                <PanelDivider />
                {isAdmin(me) && (
                    <React.Fragment>
                        <NavButton text={'Accounts'} to={routes.accounts}/>
                        <PanelDivider />
                    </React.Fragment>
                )}
                <NavButton text={'Profiel'} to={routes.profile}/>
                <NavButton text={'Logout'} to={routes.logout}/>
            </PanelBody>
            <PanelBottom />
        </React.Fragment>
    );
}

export default SideNav;