import React, {useContext} from "react";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import LoginPage from "../../desktop/components/login/LoginPage";
import PrivateRoute from "./PrivateRoute";
import HomePage from "../../desktop/components/home/HomePage";
import CookingPage from "../../desktop/components/cooking/CookingPage";
import ProfilePage from "../../desktop/components/profile/ProfilePage";
import TransactionPage from "../../desktop/components/transaction/TransactionPage";
import BeerListPage from "../../desktop/components/beerlist/BeerListPage";
import LogoutHandler from "./LogoutHandler";
import UsersPage from "../../desktop/components/admin/users/UsersPage";
import CookingDonationPage from "../../desktop/components/donation/CookingDonationPage";
import LedsPage from "../../desktop/components/leds/LedsPage";
import MobileLedsPage from "../../mobile/components/leds/MobileLedsPage";
import ShuttleDrunkTestPage from "../../desktop/components/shuttledrunktest/ShuttleDrunkTestPage";
import StatsPage from "../../desktop/components/stats/StatsPage";
import {isMobile} from 'react-device-detect';
import MobileLoginPage from "../../mobile/components/login/MobileLoginPage";
import MobileHomePage from "../../mobile/components/home/MobileHomePage";
import {routes} from "./Routes";
import MobileProfilePage from "../../mobile/components/profile/MobileProfilePage";
import MobileTransactionPage from "../../mobile/components/transaction/MobileTransactionPage";
import MobileDonationPage from "../../mobile/components/donation/MobileDonationPage";
import MobileCookingPage from "../../mobile/components/cooking/list/MobileCookingPage";
import MobileCookingDetailPage from "../../mobile/components/cooking/detail/MobileCookingDetailPage";
import {SettingsContext} from "../context/SettingsContext";
import MobileBeerListPage from "../../mobile/components/beerlist/MobileBeerListPage";
import AlbumsPage from "../../desktop/components/albums/AlbumsPage";
import AlbumDetailPage from "../../desktop/components/albums/AlbumDetailPage";
import MobileAlbumsPage from "../../mobile/components/albums/list/MobileAlbumsPage";
import MobileAlbumDetailPage from "../../mobile/components/albums/detail/MobileAlbumDetailPage";
import MobileStatsPage from "../../mobile/components/stats/MobileStatsPage";
import PrintingPage from "../../desktop/components/printing/PrintingPage";
import MobileShuttleDrunkTestPage from "../../mobile/components/shuttledrunktest/MobileShuttleDrunkTestPage";
import RoomSolicitingDaysPage from "../../desktop/components/roomsoliciting/day/RoomSolicitingDaysPage";
import RoomSolicitorsPage from "../../desktop/components/roomsoliciting/solicitor/list/RoomSolicitorsPage";
import RoomSolicitorDetailPage from "../../desktop/components/roomsoliciting/solicitor/detail/RoomSolicitorDetailPage";

const PageRouter = () => {

    const { settings } = useContext(SettingsContext);

    const showMobile = settings.showMobileView && isMobile;

    return (
        <Router>
            <Switch>
                <Route exact path={routes.login} component={showMobile ? MobileLoginPage : LoginPage} />
                <PrivateRoute path={`${routes.cookingCalenderDetail}/:date`} component={showMobile ? MobileCookingDetailPage : HomePage} /> {/* Fall back to homepage since there is no desktop version */}
                <PrivateRoute path={`${routes.cookingCalender}/:date`} component={showMobile ? MobileCookingPage : CookingPage} />
                <PrivateRoute exact path={routes.cookingCalender} component={showMobile ? MobileCookingPage : CookingPage} />
                <PrivateRoute path={`${routes.moneyList}/:id`} component={showMobile ? MobileTransactionPage : TransactionPage} />
                <PrivateRoute exact path={routes.moneyList} component={showMobile ? MobileTransactionPage : TransactionPage} />
                <PrivateRoute exact path={routes.beerList} component={showMobile ? MobileBeerListPage : BeerListPage} />
                <PrivateRoute exact path={routes.leds} component={showMobile ? MobileLedsPage : LedsPage} />
                <PrivateRoute exact path={routes.stats} component={showMobile ? MobileStatsPage : StatsPage} />
                <PrivateRoute exact path={routes.iris} component={() => {
                    window.location = 'http://residentiedirtya.student.utwente.nl:6680/iris'
                }} />
                <PrivateRoute exact path={routes.shuttleDrunkTest} component={showMobile ? MobileShuttleDrunkTestPage : ShuttleDrunkTestPage} />
                <PrivateRoute path={`${routes.albums}/:id`} component={showMobile ? MobileAlbumDetailPage : AlbumDetailPage} />
                <PrivateRoute exact path={routes.albums} component={showMobile ? MobileAlbumsPage : AlbumsPage} />
                <PrivateRoute exact path={routes.donations} component={showMobile ? MobileDonationPage : CookingDonationPage} />
                <PrivateRoute exact path={routes.printing} component={PrintingPage} />

                <PrivateRoute path={`${routes.roomSoliciting}/:date`} component={RoomSolicitingDaysPage} />
                <PrivateRoute exact path={routes.roomSoliciting} component={RoomSolicitingDaysPage} />
                <PrivateRoute path={`${routes.roomSolicitor}/:id`} component={RoomSolicitorDetailPage} />
                <PrivateRoute exact path={routes.roomSolicitors} component={RoomSolicitorsPage} />

                <PrivateRoute exact path={routes.accounts} component={UsersPage} />
                <PrivateRoute exact path={routes.profile} component={showMobile ? MobileProfilePage : ProfilePage} />
                <PrivateRoute exact path={routes.logout} component={LogoutHandler} />


                <PrivateRoute path={routes.home} component={showMobile ? MobileHomePage : HomePage} />
            </Switch>
        </Router>
    );
};

export default PageRouter;