import React, {useContext, useEffect} from "react";
import PageWrapper from "../../PageWrapper";
import DateSlider from "./DateSlider";
import {useParams} from "react-router";
import dayjs from "dayjs";
import RoomSolicitingDayAvailability from "./RoomSolicitingDayAvailability";
import RoomSolicitingDayAvailabilityForm from "./RoomSolicitingDayAvailabilityForm";
import RoomSolicitingNavigation from "../RoomSolicitingNavigation";
import {MeContext} from "../../../../shared/context/MeContext";
import {UsersContext} from "../../../../shared/context/UsersContext";
import RoomSolicitingDayAppointments from "./RoomSolicitingDayAppointments";
import RoomSolicitingDayAppointmentForm from "./RoomSolicitingDayAppointmentForm";

const RoomSolicitingDaysPage = () => {

    const { date } = useParams();

    const { loadMe } = useContext(MeContext);
    const { loadUsers } = useContext(UsersContext)

    const selectedDate = date || dayjs().format('YYYY-MM-DD');

    useEffect(() => {
        loadMe();
        loadUsers();
    }, []);

    return (
        <PageWrapper>
            <RoomSolicitingNavigation />
            <DateSlider selectedDate={selectedDate} />
            <RoomSolicitingDayAvailability selectedDate={selectedDate} />
            <RoomSolicitingDayAvailabilityForm selectedDate={selectedDate} />
            <RoomSolicitingDayAppointments selectedDate={selectedDate} />
            <RoomSolicitingDayAppointmentForm selectedDate={selectedDate} />
        </PageWrapper>
    );
};

export default RoomSolicitingDaysPage;
