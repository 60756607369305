import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import BeerInputModal from "./BeerInputModal";
import {getTypeText} from "../../../../shared/util/BeerTypeUtil";
import {initialBeerInput} from "../BeerListPage";

const EditBeerInputModal = ({
    isOpen = false,
    setIsOpen = (value) => {},
    users = [],
    modify = () => {},
    beerInput = {},
    beerTypes = [],
    currentBeerTypeId = null,
    setSelectedBeerTypeId = (value) => {},
    setSelectedBeerInput = (beerInput) => {},
    setShowUploadModal = () => {}
}) => {

    const [ formValues, setFormValues ] = useState(beerInput);
    const [ showMultiplyButton, setShowMultiplyButton ] = useState(false);
    const [ multiplyAmount, setMultiplyAmount ] = useState(1);

    useEffect(() => {
        if (beerInput) {
            setFormValues({
                ...beerInput,
                enrollments: beerInput.enrollments.map(x => ({
                    user: x.user.id,
                    amount: x.amount,
                }))
            });
        }
    }, [beerInput])

    useEffect(() => {
        if (isOpen) {
            setFormValues({
                ...formValues,
                beerType: currentBeerTypeId
            });
        }
    }, [isOpen])

    useEffect(() => {
        setShowMultiplyButton(formValues.type === 'RECEIPT');
    }, [formValues.type])

    useEffect(() => {
        if (formValues.beerType) {
            const beerType = beerTypes.find(x => x.id.toString() === formValues.beerType.toString());
            if (beerType) {
                setMultiplyAmount(beerType.beersPerCrate);
            }
        }
    }, [formValues.beerType]);

    const resetBeerInput = () => {
        setFormValues(initialBeerInput);
    }

    const handleTypeChange = (event) => {
        setFormValues({
            ...formValues,
            type: event.target.value
        });
    };

    const handleBeerTypeChange = (event) => {
        setFormValues({
            ...formValues,
            beerType: event.target.value
        });
    };

    const handleEnrollmentChange = (event, userId) => {
        const currentEnrollment = formValues.enrollments.find(x => x.user === userId);
        if (currentEnrollment) {
            // Update enrollment
            setFormValues({
                ...formValues,
                enrollments: formValues.enrollments.map(x => x.user !== userId ? x : {
                    ...x,
                    amount: event.target.value
                })
            });
        } else {
            // Create enrollment
            setFormValues({
                ...formValues,
                enrollments: [...formValues.enrollments, { user: userId, amount: event.target.value }]
            })
        }
    };

    const handleNoteChange = (event) => {
        setFormValues({
            ...formValues,
            note: event.target.value
        });
    };

    const onMultiply = () => {
        setFormValues({
            ...formValues,
            enrollments: formValues.enrollments.map(e => {
                return {
                    ...e,
                    amount: e.amount * multiplyAmount,
                }
            })
        })
    }

    const handleSubmit = () => {
        modify({
            ...formValues,
            date: dayjs().format('YYYY-MM-DD'),
            // Filter all zero or negative values
            // And multiply the amount times the beersPerCrate when it is a receipt
            enrollments: formValues.enrollments.filter(x => x.amount > 0).map(x => ({
                ...x,
                amount: parseInt(x.amount) || 0
            }))
        }, (beerInput) => {
            setSelectedBeerTypeId(formValues.beerType);
            setIsOpen(false);
            setShowUploadModal(true);
            setSelectedBeerInput(beerInput);
            resetBeerInput()
        });
    };

    const inputTypes = (
        <select value={formValues.type || ''} onChange={handleTypeChange}>
            <option value={null} />
            <option value={'LIST'}>Bierlijst</option>
            <option value={'RECEIPT'}>Bonnetje</option>
        </select>
    );

    const beerTypeSelect = (
        <select value={formValues.beerType || ''} onChange={handleBeerTypeChange}>
            <option value={null} />
            {beerTypes.map(x => (
                <option key={x.id} value={x.id}>{x.name}</option>
            ))}
        </select>
    );

    const getUserInput = (user, vals) => {
        return (
            <input
                type={'number'}
                value={vals.enrollments.find(e => e.user === user.id)?.amount}
                onChange={(event) => handleEnrollmentChange(event, user.id)}
                min={0}
            />
        );
    }

    const buildNote = (
        <textarea
            rows={3}
            placeholder={"Optioneel"}
            value={formValues.note || ''}
            onChange={handleNoteChange}
        />
    );

    const title = beerInput.date === null
        ? 'Nieuwe invoer'
        : `Edit "${dayjs(beerInput.date).format('DD/MM') + ' ' + getTypeText(beerInput.type)}"`

    return (
        <BeerInputModal
            title={title}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isEdit={true}
            inputTypes={inputTypes}
            beerTypes={beerTypeSelect}
            users={users}
            userEntry={(user) => getUserInput(user, formValues)}
            note={buildNote}
            handleSubmit={handleSubmit}
            showMultiplyButton={showMultiplyButton}
            multiplyAmount={multiplyAmount}
            onMultiply={onMultiply}
        />
    );
};

export default EditBeerInputModal;
