export const mergeArraysById = (oldState, newState) => {
    newState.forEach((newObject) => {
        const index = oldState.findIndex((oldObject) => oldObject.id === newObject.id);
        if (index === -1) {
            oldState.push(newObject);
        } else {
            oldState[index] = newObject;
        }
    });
    // Return a new object here so this function can be used in React Context
    // (When returning the same reference a Context will not update)
    return [...oldState];
};

export const getPropertyFromArrayByKey = (array = [], keyName = '', key, propertyName = '', defaultValue = 'Error') => {
    const object = array.find((x) => x[keyName] === key);
    return object ? object[propertyName] : defaultValue;
};
