import React from "react";
import MobileModal, {MobileModalBottom, MobileModalButtonRow, MobileModalContent} from "../../modal/MobileModal";
import Button from "../../../styles/Button";
import styled from "styled-components";
import {Col, F1, Row} from "../../../styles/Grid";
import {spacing} from "../../../util/Spacing";
import {getDryerApplicableUsers} from "../../../../shared/util/CookingEventUtils";
import useCookingDryers from "../../../../shared/hooks/CookingDryersHook";
import {func} from "../../../../util/Helpers";
import Select from "../../../styles/Select";

const CustomRow = styled(Row)`
    padding: ${spacing.base}px;
`;

const DryersModal = ({ isOpen = false, close = func, title = '', event }) => {

    const {
        formValues,
        dryerSuggestions,
        handleDryer1Change,
        handleDryer2Change,
        applySuggestions,
        handleSubmit
    } = useCookingDryers(isOpen, close, event);

    const dryerApplicableUsers = getDryerApplicableUsers(event);

    const optionsDryer1 = dryerApplicableUsers.filter(x => x.id !== parseInt(formValues.dryer2));
    const optionsDryer2 = dryerApplicableUsers.filter(x => x.id !== parseInt(formValues.dryer1));

    if (!isOpen) {
        return <></>;
    }

    return (
        <MobileModal
            isOpen={isOpen}
            close={close}
            title={title}
        >
            <MobileModalContent>
                <Col>
                    <CustomRow style={{ marginTop: spacing.big }}>
                        <F1>Suggestions</F1>
                        <F1>{dryerSuggestions?.map(x => x.username).join(', ')}</F1>
                    </CustomRow>
                    <Row style={{ paddingLeft: spacing.base, paddingRight: spacing.base }}>
                        <Button text={'Apply suggestions'} onClick={applySuggestions} style={{ width: '100%' }} />
                    </Row>
                    <CustomRow>&nbsp;</CustomRow>
                    <CustomRow>
                        <Select
                            title={'Dryer 1'}
                            onChange={handleDryer1Change}
                            data={optionsDryer1}
                            value={formValues.dryer1}
                            labelExtractor={(x) => x.username}
                        />
                    </CustomRow>
                    <CustomRow>
                        <Select
                            title={'Dryer 2'}
                            onChange={handleDryer2Change}
                            data={optionsDryer2}
                            value={formValues.dryer2}
                            labelExtractor={(x) => x.username}
                        />
                    </CustomRow>
                    <CustomRow>&nbsp;</CustomRow>
                    <CustomRow>&nbsp;</CustomRow>
                </Col>
            </MobileModalContent>
            <MobileModalBottom>
                <MobileModalButtonRow>
                    <Button text={'Cancel'} color={'secondary'} onClick={close} style={{ marginRight: spacing.base }} />
                    <Button text={'Save'} onClick={handleSubmit} />
                </MobileModalButtonRow>
            </MobileModalBottom>
        </MobileModal>
    );
};

export default React.memo(DryersModal);