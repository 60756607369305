import React, {useContext, useEffect} from "react";
import PageWrapper from "../PageWrapper";
import {MeContext} from "../../../shared/context/MeContext";
import ProfileForm from "./ProfileForm";

const ProfilePage = () => {
    const { me, loadMe } = useContext(MeContext);

    useEffect(() => {
        loadMe();
    }, [])

    return (
        <PageWrapper>
            <ProfileForm username={me.username} autoEnroll={me.autoEnroll} />
        </PageWrapper>
    );
};

export default ProfilePage;