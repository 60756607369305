import {useContext, useEffect, useState} from "react";
import {TransactionContext} from "../../shared/context/TransactionContext";
import {priceRegex} from "../util/Utils";
import {enrollmentsToWriteDto} from "../../shared/util/CookingEventUtils";

const initialState = {
    description: '',
    price: '',
    enrolled: [],
};

/**
 * Hook to manage transaction form state. When the transaction is null or undefined it will act in create mode,
 * otherwise in edit mode.
 */
function useTransactionForm(transaction) {

    const [values, setValues] = useState({});

    const { createTransaction, editTransaction } = useContext(TransactionContext);

    // Set initial values for when the transaction changes
    useEffect(() => {
        if (!transaction?.id) {
            setValues(initialState);
        } else {
            setValues({
                description: transaction.description,
                price: `${transaction.amount}`,
                enrolled: transaction.enrollments?.map((x) => ({
                    user: x.user,
                    amount: x.amount,
                })),
            });
        }
    }, [transaction?.id]);

    const changeDescription = (input) => {
        setValues({
            ...values,
            description: input
        });
    };

    const changePrice = (input) => {
        setValues({
            ...values,
            price: input.replace(',', '.')
        });
    };

    const changeAmountForUser = (userId, change) => {
        const enrollment = values.enrolled && values.enrolled.find((x) => x.user.id === userId);
        const currentValue = enrollment ? enrollment.amount : 0;

        const possibleNewValue = currentValue + change;
        const finalNewValue = possibleNewValue < 0 ? 0 : possibleNewValue;

        if (enrollment) {
            // Enrollment already exists, so update it
            setValues({
                ...values,
                enrolled: values.enrolled.map((x) => (x.user.id === userId ? { ...x, amount: finalNewValue } : x)),
            });
        } else {
            // Add new enrollment
            setValues({
                ...values,
                enrolled: [...values.enrolled, { user: { id: userId }, amount: finalNewValue }],
            });
        }
    };

    const handleSubmit = (onSuccess) => {
        if (!values.description || values.description.length === 0) {
            alert('Please enter a description');
            return;
        }
        if (!values.price) {
            alert('Please enter a price');
            return;
        }
        if (!values.price.match(priceRegex)) {
            alert('Please enter a valid price');
            return;
        }

        const result = {
            ...values,
            enrolled: enrollmentsToWriteDto(values.enrolled),
        };

        transaction
            ? editTransaction(transaction.id, result, true, onSuccess)
            : createTransaction(result, onSuccess);
    };

    return { values, changeAmountForUser, changeDescription, changePrice, handleSubmit };
}

export default useTransactionForm;