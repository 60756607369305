import React from "react";
import styled from "styled-components";
import {spacing} from "../../util/Spacing";
import {colors} from "../../../util/Colors";
import RoundImage from "../RoundImage";
import {fontSizes} from "../../util/Fontsizes";
import RoundImageRow from "../RoundImageRow";

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: ${spacing.base}px;
    flex: 1;
    background-color: ${colors.orange};
`;

const Left = styled.div`
    display: flex;
    flex: 1;
`;

const Right = styled.div`
    display: flex;
    flex: 3;
    flex-direction: column;
    flex-wrap: nowrap;
    
    padding-left: ${spacing.base}px;
`;

const Title = styled.div`
    font-weight: bold;
    font-size: ${fontSizes.big}px;
    color: ${colors.white};
`;

const Subtitle = styled.div`
    font-size: ${fontSizes.base}px;
    color: ${colors.white};
`;

const RoundImageRowWrapper = styled.div`
    margin-top: ${spacing.small}px;
`;

function countEnrollments(enrollments) {
    let result = 0;
    enrollments.forEach((x) => result += x.amount);
    return result;
}

const CookingToday = ({cook, enrollments = []}) => {
    return (
        <Wrapper>
            <Left>
                <RoundImage imageId={cook?.image} width={'24vw'} />
            </Left>
            <Right>
                <Title>{cook?.username || 'Nobody'}</Title>
                <Subtitle>Is cooking for {countEnrollments(enrollments)} people</Subtitle>
                <RoundImageRowWrapper>
                    <RoundImageRow
                        imageIds={enrollments.filter(x => x.amount > 0).map(x => x.user.image)}
                        amounts={enrollments.filter(x => x.amount > 0).map(x => x.amount)}
                        imageWidth={30}
                        imageBorderColor={colors.white}
                    />
                </RoundImageRowWrapper>
            </Right>
        </Wrapper>
    );
};

export default CookingToday;