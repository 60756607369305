import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
    margin-top: 10px;
    border-top: 1px solid #C0C0C0;
    padding-top: 10px;
    
    width: 100%;
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const getDryerNames = (event) => {
    let dryers = [];
    if (event.dryer1) dryers.push(event.dryer1.username)
    if (event.dryer2) dryers.push(event.dryer2.username)
    return dryers.join(', ')
}

const CookingDryers = ({ canEdit = false, event, openUpdateModal = () => {} }) => {

    return (
        <Wrapper>
            <span>Afdrogers: {getDryerNames(event)}</span>
            {canEdit && (
                <button type={'button'} onClick={openUpdateModal}>Pas aan</button>
            )}
        </Wrapper>
    );
};

export default CookingDryers;