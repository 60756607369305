import React, {useCallback, useContext, useRef, useState} from "react";
import styled from "styled-components";
import {colors} from "../../../util/Colors";
import {FWCol} from "../../styles/Grid";
import {fontSizes} from "../../util/Fontsizes";
import {spacing} from "../../util/Spacing";
import RoundImage from "../RoundImage";
import {MeContext} from "../../../shared/context/MeContext";
import Toast from "../../styles/Toast";

const Wrapper = styled.div`
    width: 100vw;
    background-color: ${colors.orange};
    height: 135px;
`;

const Title = styled.div`
    margin-top: ${spacing.base}px;
    color: ${colors.white};
    font-size: ${fontSizes.big}px;
    font-weight: bold;
    
    width: 100%;
    text-align: center;
`;

const ImageWrapper = styled.div`
    margin-top: ${spacing.base}px;
    width: 100%;
    display: flex;
    justify-content: center;
`;

const ProfileImage = ({ username = '', imageId }) => {

    const inputRef = useRef(null);

    const { updateMeImage } = useContext(MeContext);

    const [ showToast, setShowToast ] = useState(false);

    const handleImageClick = useCallback( () => {
        inputRef.current.click();
    }, [inputRef]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            updateMeImage(file, onSuccess);
        }
    };

    const onSuccess = () => {
        setShowToast(true);
    };

    return (
        <Wrapper>
            <FWCol>
                <input type='file' id='file' ref={inputRef} style={{display: 'none'}} onChange={handleFileChange}/>
                <Title>{username}</Title>
                <ImageWrapper>
                    <RoundImage imageId={imageId} width={'135px'} borderColor={colors.white} onClick={handleImageClick} />
                </ImageWrapper>
                <Toast isOpen={showToast} close={() => setShowToast(false)} text={'Profile picture updated'} />
            </FWCol>
        </Wrapper>
    );
};

export default React.memo(ProfileImage);