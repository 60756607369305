import styled from "styled-components";
import CheckOrangeImage from "../images/checkbox/check_orange.png";
import CheckGreyNone from "../images/checkbox/check_none.png";
import ArrowUp from "../images/arrow/arrows_up.png";
import ArrowDown from "../images/arrow/arrows_down.png";
import {Row} from "./Layout";

export const CheckBox = styled.div`
    background-image: url(${props => props.checked ? CheckOrangeImage : CheckGreyNone});
    ${props => !props.disabled && 'cursor: pointer;'}
    background-repeat: no-repeat;
    width: ${props => props.checked ? 14 : 10}px;
    height: ${props => props.checked ? 14 : 10}px;
    margin-top: ${props => props.checked ? 2 : 6}px;
    margin-left: ${props => props.checked ? 0 : 2}px;
    margin-right: ${props => props.checked ? 0 : 2}px;
`;

export const CheckBoxWrapper = styled.div`
    margin-left: 2px;
    margin-right: 5px;
`;

export const Arrow = styled.div`
    background-image: url(${props => props.up ? ArrowUp : ArrowDown});
    ${props => !props.disabled && 'cursor: pointer;'}
    background-repeat: no-repeat;
    width: 8px;
    height: 16px;
`;

export const ArrowsWrapper = styled.div`
    width: 17px;
    display: flex;
    flex-direction: row;
`;

export const Name = styled.div`
    font-family: Verdana;
    font-size: 16px;
    font-weight: 400;
`;

export const Wrapper = styled.div`
    margin-top: 10px;
    border-top: 1px solid #C0C0C0;
`;

export const CustomRow = styled(Row)`
    margin-top: 10px;
    font: 100% Verdana;
    justify-content: flex-end;
`;

export const Input = styled.input`
    margin-left: 5px;
    margin-right: 3px;
`;

export const DescriptionInput = styled(Input)`
    width: 120px;
    margin-right: 14px;
    margin-left: 0px;
`;

export const DescriptionReadonly = styled(DescriptionInput)`
    color: #9D9D9D;
`;

export const EurosInput = styled(Input)`
    width: 22px;
    text-align: right;
`;

export const EurosReadonly = styled(EurosInput)`
    color: #9D9D9D;
`;

export const CentsInput = styled(Input)`
    width: 16px;
`;

export const CentsReadonly = styled(CentsInput)`
    color: #9D9D9D;
`;
