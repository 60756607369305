import React from "react";
import styled from "styled-components";
import BeerListTableBodyRow from "./BeerListTableBodyRow";

const TBody = styled.tbody`
    height: 240px;
    overflow-y: scroll;
    overflow-x: hidden;
    display: block;
    
    margin-top: -2px;
`;

const BeerListTableBody = ({users = [], beerInputPageable, getBeerInputs, setShowViewModal = () => {}, setSelectedBeerInput = () => {}}) => {
    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom && !beerInputPageable.last) {
            getBeerInputs(beerInputPageable.number + 1);
        }
    };

    return (
        <TBody onScroll={handleScroll}>
            {beerInputPageable?.content?.map(x => (
                <BeerListTableBodyRow
                    key={x.id}
                    users={users}
                    beerInput={x}
                    setSelectedBeerInput={setSelectedBeerInput}
                    setShowViewModal={setShowViewModal}
                />
            ))}
        </TBody>
    );
};

export default BeerListTableBody;