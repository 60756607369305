import styled from "styled-components";

const Flex = styled.div`
    display: flex;
`;

export const Col = styled(Flex)`
    flex-direction: column;
`;

// Full width column
export const FWCol = styled(Col)`
    width: 100%;
`;

export const Row = styled(Flex)`
    flex-direction: row;
`;

export const FWRow = styled(Row)`
    width: 100%;
`;

// Flex end row
export const FERow = styled(Row)`
    justify-content: flex-end;
`;

export const FCRow = styled(Row)`
    justify-content: center;
`;

export const F1 = styled.div`
    flex: 1;
`;

export const F2 = styled.div`
    flex: 2;
`;