import Axios from "axios";
import {constants} from "../../util/Constants";
import {defaultAxiosErrorHandler} from "../util/RequestUtils";

export const changeBrightnessRequest = (brightness, main, bar, hallway) => {
    let url = `${constants.ledApiBaseUrl}/brightness?b=${brightness}&main=${main}&bar=${bar}&hallway=${hallway}`;

    return Axios({
        method: 'get',
        url,
    }).then((response) => response.data).catch(defaultAxiosErrorHandler);
};

export const changeColorRequest = (color, main, bar, hallway) => {
    let url = `${constants.ledApiBaseUrl}/color?r=${color.r}&g=${color.g}&b=${color.b}&main=${main}&bar=${bar}&hallway=${hallway}`;

    console.log(url);

    return Axios({
        method: 'get',
        url,
    }).then((response) => response.data).catch(defaultAxiosErrorHandler);
};

export const customRequest = (name) => {
    let url = `${constants.ledApiBaseUrl}/${name}`;

    return Axios({
        method: 'get',
        url,
    }).then((response) => response.data).catch(defaultAxiosErrorHandler);
};