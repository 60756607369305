import styled from "styled-components";

const Button = styled.div`
    margin: 2px;
    text-align: center;
    color: white;
    cursor: pointer;

    :hover {
       background-color: white;
       color: #f8941d;
    }
`;

export default Button;
