import {createContext, useState} from "react";
import {getPrintingJobsRequest, getPrintingOverviewRequest, postFileRequest} from "../network/PrintRequests";
import {func} from "../../util/Helpers";
import {mergeArraysById} from "../util/ArrayUtils";
import {genericSortDesc} from "../util/SortUtils";

const initialPage = {
    content: [],
    empty: true,
    first: true,
    last: true,
    number: 0,
    numberOfElements: 0,
    pageable: {
        offset: 0,
        pageSize: 20,
        paged: true,
        sort: {
            empty: false,
            sorted: true,
            unsorted: false,
        },
        unpaged: false,
    },
    size: 0,
    sort: {
        empty: false,
        sorted: true,
        unsorted: false,
    },
    totalElements: 0,
    totalPages: 1,
};

export const PrintingContext = createContext({});

const PrintContextProvider = (props) => {
    const [printingJobs, setPrintingJobs] = useState(initialPage);
    const [printingOverview, setPrintingOverview] = useState([]);

    const getPrintingJobs = async (page = 0, onSuccess = func) => {
        const result = await getPrintingJobsRequest(page);
        if (result) {
            setPrintingJobs({
                ...result,
                content: mergeArraysById(printingJobs.content, result.content).sort((a,b) => genericSortDesc(a,b,'id')),
            });
            onSuccess();
        }
    };

    const getPrintingOverview = async (activeOnly = true) => {
        const result = await getPrintingOverviewRequest(activeOnly);
        if (result) {
            setPrintingOverview(result);
        }
    };

    const printFile = async (data, onSuccess = func) => {
        const result = await postFileRequest(data);
        if (result) {
            setPrintingJobs({
                ...printingJobs,
                content: [result, ...printingJobs.content].sort((a,b) => genericSortDesc(a, b, 'id'))
            });
            onSuccess();
        }
    }

    return (
        <PrintingContext.Provider value={{
            printingJobs,
            printingOverview,
            getPrintingOverview,
            getPrintingJobs,
            printFile }}
        >
            {props.children}
        </PrintingContext.Provider>
    );
};

export default PrintContextProvider;
