import Axios from "axios";
import {constants} from "../../../../util/Constants";
import {defaultAxiosErrorHandler} from "../../../util/RequestUtils";

const controllerPath = 'room-soliciting-appointments';

export const postRoomSolicitingAppointmentRequest = (data) => Axios({
    method: 'post',
    url: `${constants.apiBaseUrl}/${controllerPath}`,
    data,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const putRoomSolicitingAppointmentRequest = (id, data) => Axios({
    method: 'put',
    url: `${constants.apiBaseUrl}/${controllerPath}/${id}`,
    data,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const deleteRoomSolicitingAppointmentRequest = (id) => Axios({
    method: 'delete',
    url: `${constants.apiBaseUrl}/${controllerPath}/${id}`,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);
