import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(isSameOrBefore);

export const createEmptyEvent = (id, dateString) => ({
    id: id,
    date: dateString,
    cook: null,
    enrolled: [],
});

export const parseDateString = (dateString) => {
    let date = dayjs(dateString)
    // Manually set year, otherwise years before 1901 are parsed incorrectly,
    // https://github.com/iamkun/dayjs/issues/1237
    return date.year(dateString.split('-')[0])
};

export const dayjsYearFixer = (dateString) => {
    // Fixes years before 1901, see https://github.com/iamkun/dayjs/issues/1237
    // Bug: Date(0001, 12, 25).format('YYYY-MM-DD') -> '1-12-25'
    //   We get the output of that here, but we can't send that to the API since that's an invalid date: it expects
    //   a date in the format 'YYYY-MM-DD', not 'Y-MM-DD', so we need to add leading zeroes.
    //   Since 'YYYY-MM-DD' is always 10 characters long except for the missing leading zeroes, we can just pad
    //   with leading zeroes until we have length 10
    return dateString.padStart(10, '0');
};

export const getActiveEvents = (cookingEvents, firstDayString, lastDayString) => {
    firstDayString = dayjsYearFixer(firstDayString);
    lastDayString = dayjsYearFixer(lastDayString);
    const result = [];
    let newEventId = -1;

    let day = parseDateString(firstDayString);
    const lastDay = parseDateString(lastDayString);
    while (day.isSameOrBefore(lastDay)) {
        const currentDate = dayjsYearFixer(day.format('YYYY-MM-DD'));
        const event = cookingEvents.find((x) => x.date === currentDate) || createEmptyEvent(newEventId--, currentDate);

        result.push(event);
        day = day.add(1, 'day');
    }

    return result;
};

/**
 * Changes the amount a user is enrolled
 * @param enrollments: The array with enrollments
 * @param userId: The id of a user {Number}
 * @param change: The amount the current amount has to be changed
 */
export const changeAmountForUser = (enrollments = [], userId = -1, change = 0) => {
    const enrollment = enrollments.find((x) => x.user.id === userId);
    const currentValue = enrollment ? enrollment.amount : 0;

    // Prevent from going negative
    const possibleNewValue = currentValue + change;
    const finalNewValue = possibleNewValue < 0 ? 0 : possibleNewValue;

    if (enrollment) {
        // Enrollment already exists, so update it
        return enrollments.map((x) => (x.user.id === userId ? { ...x, amount: finalNewValue } : x));
    } else {
        // Add new enrollment
        return [...enrollments, { id: -new Date().getTime(), user: { id: userId }, amount: finalNewValue }];
    }
};

export const getEnrolledAmountByUserId = (enrollments = [], userId = -1) => {
    const enrollment = enrollments.find((x) => x.user.id === userId);
    return enrollment ? enrollment.amount : 0;
};

export const enrollmentsToWriteDto = (enrollments = []) => {
    return enrollments.map(x => ({
        user: x.user.id,
        amount: x.amount
    }));
};

export const getEnrolledUsersExcludingCook = (users, event) => users.filter(x =>
    x.id !== event?.cook?.id &&
    event.enrolled.map(x => x.user.id).includes(x.id)
);

export const getDryerApplicableUsers = (event) => event.enrolled.filter(x => x.user.id !== event.cook?.id && x.amount > 0).map(x => x.user);
