import React, {useContext, useEffect, useState} from 'react';
import Toast from "../../styles/Toast";
import {MeContext} from "../../../shared/context/MeContext";
import {SettingsContext} from "../../../shared/context/SettingsContext";
import Input from "../../styles/Input";
import Checkbox from "../../styles/Checkbox";
import {FERow} from "../../styles/Grid";
import Button from "../../styles/Button";
import {areEqual} from "../../util/Utils";

const ProfileDetailsForm = ({ username = '' }) => {

    const [ showToast, setShowToast ] = useState(false);
    const [ saveDisabled, setSaveDisabled ] = useState(true);

    const { updateMe } = useContext(MeContext);
    const { settings, setSettings } = useContext(SettingsContext);

    const initialValues = {
        username: username,
        oldPassword: "",
        password: "",
        passwordRepeat: "",
    };
    const [formValues, setFormValues] = useState(initialValues);

    const setUsername = (input) => {
        setFormValues({ ...formValues, username: input });
    };

    const setOldPassword = (input) => {
        setFormValues({ ...formValues, oldPassword: input });
    };

    const setPassword = (input) => {
        setFormValues({ ...formValues, password: input });
    };

    const setPasswordRepeat = (input) => {
        setFormValues({ ...formValues, passwordRepeat: input });
    };

    useEffect(() => {
        setFormValues({
            ...formValues,
            username: username
        });
    }, [ username ]);

    useEffect(() => {
        setSaveDisabled(areEqual(formValues, initialValues));
    }, [initialValues, formValues, setSaveDisabled])

    const handleSubmit = (onSuccess) => {
        if (formValues.oldPassword || formValues.password || formValues.passwordRepeat) {
            if (!formValues.oldPassword) {
                alert('Vul je oude wachtwoord in');
                return;
            }
            if (!formValues.password) {
                alert('Vul je nieuwe wachtwoord in');
                return;
            }
            if (!formValues.passwordRepeat) {
                alert('Herhaal je nieuwe wachtwoord');
                return;
            }
        }
        updateMe({
            ...formValues,
            oldPassword: formValues.oldPassword || null,
            password: formValues.password || null,
            passwordRepeat: formValues.passwordRepeat || null,
        }, onSuccess);
    };

    const onSuccess = () => {
        setFormValues(initialValues)
        setShowToast(true);
    };

    const submit = (event) => {
        event.preventDefault();
        handleSubmit(onSuccess);
    }

    return (
        <form onSubmit={submit}>
            <Input
                placeholder={'Username'}
                value={formValues.username}
                onChange={event => setUsername(event.target.value)}
            />
            <Input
                type="password"
                placeholder={'Old Password'}
                value={formValues.oldPassword}
                onChange={event => setOldPassword(event.target.value)}
            />
            <Input
                type="password"
                placeholder={'New Password'}
                value={formValues.password}
                onChange={event => setPassword(event.target.value)}
            />
            <Input
                type="password"
                placeholder={'New Password Repeat'}
                value={formValues.passwordRepeat}
                onChange={event => setPasswordRepeat(event.target.value)}
            />
            <Checkbox checked={settings.showMobileView} text={'Show Mobile View'} onClick={() => setSettings({ ...settings, showMobileView: !settings.showMobileView })} />
            <FERow>
                <Button disabled={saveDisabled} type={"submit"} text={'Save'} />
            </FERow>
            <Toast isOpen={showToast} close={() => setShowToast(false)} text={'Profile updated'} snackbarProps={{ style: { bottom: 75 } }}/>
        </form>
    );
};

export default ProfileDetailsForm;