export const routes = {
    home: '/',
    login: '/login',
    cookingCalender: '/kookagenda',
    cookingCalenderDetail: '/kookagenda/dag',
    moneyList: '/geldlijst',
    beerList: '/bierlijst',
    leds: '/leds',
    stats: '/statistieken',
    iris: '/iris',
    shuttleDrunkTest: '/shuttle-drunk-test',
    albums: '/albums',
    donations: '/donaties',
    printing: '/printing',
    roomSoliciting: '/hospitaties',
    roomSolicitors: '/kamerzoekers',
    roomSolicitor: '/kamerzoeker',
    accounts: '/accounts',
    profile: '/profiel',
    logout: '/logout',
};