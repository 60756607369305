import React from "react";
import PageRouter from "./shared/routing/PageRouter";
import ContextProvider from "./shared/ContextProvider";

function App() {
  return (
      <ContextProvider>
          <PageRouter />
      </ContextProvider>
  );
}

export default App;
