import React from "react";
import {Row} from "../../../styles/Layout";
import dayjs from "dayjs";
import CookingTableBodyCell from "./CookingTableBodyCell";

const isSameDate = (dateString1, dateString2) => {
    return dayjs(dateString1).isSame(dayjs(dateString2), 'date');
};

const CookingTableBodyRow = ({ large, events, currentDate, me, setMeAsCook, unSetMeAsCook, enroll }) => {
    return (
        <Row>
            {events.map((event) => (
                <CookingTableBodyCell
                    key={event.id}
                    cookingEvent={event}
                    me={me}
                    large={large}
                    current={isSameDate(event.date, currentDate)}
                    setMeAsCook={setMeAsCook}
                    unSetMeAsCook={unSetMeAsCook}
                    enroll={enroll}
                />
            ))}
        </Row>
    );
};

export default CookingTableBodyRow;
