import React from "react";
import styled from 'styled-components';
import {PanelBody, PanelBottom, PanelContainer, PanelTop} from "../styles/Panel";
import {Title} from "../styles/Layout";
import constructionImage from '../images/construction.png';

const ConstructionImage = styled.img`
    margin-top: 12px;
    margin-bottom: 10px;
    margin-left: 25%;
    margin-right: 25%;
    width: 50%;
`;

const ConstructionPanel = () => {
    return (
        <PanelContainer>
            <PanelTop>
                <Title>---</Title>
            </PanelTop>
            <PanelBody>
                <ConstructionImage src={constructionImage} alt={''}/>
            </PanelBody>
            <PanelBottom />
        </PanelContainer>
    );
};

export default ConstructionPanel;