import React, { createContext, useState } from 'react';
import {
    getAverageCostPerMonthRequest,
    getAverageCostRequest,
    getDryerCountRequest,
    getForgottenTransactionsRequest
} from "../network/StatsRequests";

export const StatsContext = createContext({});

const StatsContextProvider = (props) => {
    const [averageCosts, setAverageCosts] = useState([]);
    const [averageCostsPerMonth, setAverageCostsPerMonth] = useState([]);
    const [forgottenTransactions, setForgottenTransactions] = useState([]);
    const [dryerCount, setDryerCount] = useState([]);

    const getAverageCosts = async () => {
        const result = await getAverageCostRequest();
        if (result) {
            setAverageCosts(result);
        }
    }

    const getAverageCostsPerMonth = async () => {
        const result = await getAverageCostPerMonthRequest();
        if (result) {
            setAverageCostsPerMonth(result);
        }
    }

    const getForgottenTransactions = async () => {
        const result = await getForgottenTransactionsRequest();
        if (result) {
            setForgottenTransactions(result);
        }
    }

    const getDryerCount = async () => {
        const result = await getDryerCountRequest();
        if (result) {
            setDryerCount(result);
        }
    }

    return (<StatsContext.Provider value={{
        averageCosts,
        getAverageCosts,
        averageCostsPerMonth,
        getAverageCostsPerMonth,
        forgottenTransactions,
        getForgottenTransactions,
        dryerCount,
        getDryerCount
    }}>
        {props.children}
    </StatsContext.Provider>
    );
};

export default StatsContextProvider;