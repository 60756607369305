import React from "react";
import {NavLink} from "react-router-dom";
import ButtonHolder from "../../styles/ButtonHolder";
import Button from "../../styles/Button";


const NavButton = ({to, text}) => {
    return (
        <ButtonHolder>
            <NavLink to={to}><Button>{text}</Button></NavLink>
        </ButtonHolder>
    );
}

export default NavButton;