import React, {useContext, useState} from 'react';
import styled from "styled-components";
import {colors} from "../../../../../util/Colors";
import CustomModal from "../../../CustomModal";
import {RoomSolicitorContext} from "../../../../../shared/context/roomsoliciting/RoomSolicitorContext";
import RoomSolicitorForm, {INITIAL_SOLICITOR_FORM_STATE} from "../form/RoomSolicitorForm";

const TitleHolder = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    padding-bottom: 20px;
    padding-top: 20px;

    border-style: solid;
    border-width: 0px 0px 1px 0px;
    border-color: ${colors.borderLightGrey};

    height: 26px;
`;

const Title = styled.div`
    font-size: 18px;
`;

const CloseHolder = styled.div`
    position: absolute;
    right: 0;
    cursor: pointer;
    margin-right: 20px;
    font-size: 22px;
`;

const Body = styled.div`
    display: flex;
    padding: 20px;
    flex-direction: column;
`;

const Footer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex: 1;

    padding: 20px;

    border-style: solid;
    border-width: 1px 0px 0px 0px;
    border-color: ${colors.borderLightGrey};
`;

const RoomSolicitorCreateModal = ({
    title = '',
    isOpen = false,
    setIsOpen = () => {},
}) => {

    const [ formState, setFormState ] = useState(INITIAL_SOLICITOR_FORM_STATE);

    const { createSolicitor } = useContext(RoomSolicitorContext);

    const handleSubmit = () => {
        createSolicitor(formState).then(() => {
            setFormState(INITIAL_SOLICITOR_FORM_STATE);
            setIsOpen(false);
        });
    };

    return (
        <CustomModal isOpen={isOpen} setIsOpen={setIsOpen}>
            <TitleHolder>
                <Title><b>{title}</b></Title>
                <CloseHolder onClick={() => setIsOpen(false)}>X</CloseHolder>
            </TitleHolder>
            <Body>
                <RoomSolicitorForm formState={formState} setFormState={setFormState} />
            </Body>
            <Footer>
                <button type={'button'} onClick={() => setIsOpen(false)} style={{ marginRight: 5 }}>Cancel</button>
                <button type={'button'} onClick={handleSubmit}>Save</button>
            </Footer>
        </CustomModal>
    );
};

export default RoomSolicitorCreateModal;
