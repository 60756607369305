import React, {useContext, useEffect, useState} from "react";
import {Row} from "../../../styles/Layout";
import {RoomSolicitorContext} from "../../../../shared/context/roomsoliciting/RoomSolicitorContext";
import {RoomSolicitingDayContext} from "../../../../shared/context/roomsoliciting/RoomSolicitingDayContext";
import {postRoomSolicitingAppointmentRequest} from "../../../../shared/network/roomsoliciting/appointment/RoomSolicitingAppointmentRequests";
import styled from "styled-components";

const Container = styled.div`
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
`;

const RoomSolicitingDayAppointmentForm = ({ selectedDate }) => {

    const [ solicitorValue, setSolicitorValue ] = useState(-1);
    const [ remarksValue, setRemarksValue ] = useState('');

    const { days, getDay } = useContext(RoomSolicitingDayContext);
    const { solicitorsPage, getSolicitors } = useContext(RoomSolicitorContext);

    const day = days.find(x => x.date === selectedDate);

    useEffect(() => {
        getSolicitors(0, 10000);
    }, []);

    const handleSolicitorChange = (e) => {
        setSolicitorValue(e.target.value);
    };

    const handleRemarksChange = (e) => {
        setRemarksValue(e.target.value);
    };

    const handleSubmit = () => {
        const data = {
            day: day?.id,
            solicitor: solicitorValue,
            remarks: remarksValue,
        };
        postRoomSolicitingAppointmentRequest(data).then(() => {
            getDay(selectedDate);
            setSolicitorValue(-1);
            setRemarksValue('');
        });
    };

    return (
        <Container>
            <Row style={{ marginTop: 10 }}>
                <select value={solicitorValue} onChange={handleSolicitorChange}>
                    <option value={-1}> </option>
                    {solicitorsPage.content.map(x => (
                        <option key={x.id} value={x.id}>{x.name}</option>
                    ))}
                </select>
                <input type={'text'} placeholder={'Opmerkingen'} style={{ width: '100%' }} value={remarksValue} onChange={handleRemarksChange} />
                <button type={'button'} onClick={handleSubmit}>+</button>
            </Row>
        </Container>
    );
};

export default RoomSolicitingDayAppointmentForm;
