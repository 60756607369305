export const getTypeText = (type) => {
    switch (type) {
        case 'LIST':
            return 'Bierlijst';
        case 'RECEIPT':
            return 'Bonnetje';
        default:
            return 'Error';
    }
}
