import React from 'react';
import {CheckBox, CheckBoxWrapper, Name, ArrowsWrapper} from "../../../styles/TransactionForm";

const TransactionEnrollmentCellAll = ({users = [], transaction, enroll, canEdit}) => {

    const everyoneEnrolled = () => {
        const enrolled = new Map();
        transaction.enrollments.forEach(e => enrolled[e.user.id] = e.amount > 0);
        return users.every(u => enrolled[u.id]);
    };

    const onCheckboxClick = () => {
        if (!canEdit) return;
        
        if (everyoneEnrolled())
            unenrollEveryone();
        else
            enrollEveryone();
    };

    const unenrollEveryone = () => {
        let enrollments = transaction.enrollments.map(t => ({
            user: t.user.id,
            amount: transaction.owner.id === t.user.id ? 1 : 0,
        }));
        enroll(enrollments);
    };

    const enrollEveryone = () => {
        const enrollments = users.map(u => ({
            user: u.id,
            amount: 1
        }));
        enroll(enrollments);
    };

    return (
        <React.Fragment>
            {canEdit && <ArrowsWrapper/>}
            <CheckBoxWrapper>
                <CheckBox
                    checked={everyoneEnrolled()}
                    onClick={onCheckboxClick}
                    disabled={!canEdit}
                />
            </CheckBoxWrapper>
            <Name>
                {'Iedereen'}
            </Name>
        </React.Fragment>
    );
};

export default TransactionEnrollmentCellAll;