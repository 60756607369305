import React, {useContext, useEffect, useState} from 'react';
import useParamsId from "../../../../../shared/hooks/UseParamsId";
import PageWrapper from "../../../PageWrapper";
import {MeContext} from "../../../../../shared/context/MeContext";
import {UsersContext} from "../../../../../shared/context/UsersContext";
import {RoomSolicitorContext} from "../../../../../shared/context/roomsoliciting/RoomSolicitorContext";
import RoomSolicitingNavigation from "../../RoomSolicitingNavigation";
import styled from "styled-components";
import RoomSolicitorForm, {INITIAL_SOLICITOR_FORM_STATE} from "../form/RoomSolicitorForm";
import RoomSolicitorReviews from "./RoomSolicitorReviews";

const Container = styled.div`
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
`;

const Divider = styled.div`
    margin-top: 10px;
    border-top: 1px solid #C0C0C0;
    margin-bottom: 10px;
`;

const RoomSolicitorDetailPage = () => {

    const id = useParamsId()

    const [ formState, setFormState ] = useState(INITIAL_SOLICITOR_FORM_STATE);

    const { loadMe } = useContext(MeContext);
    const { loadUsers } = useContext(UsersContext);
    const { solicitorsPage, getSolicitor, updateSolicitor } = useContext(RoomSolicitorContext);

    useEffect(() => {
        loadMe();
        loadUsers();
    }, []);

    useEffect(() => {
        if (id) {
            getSolicitor(id);
        }
    }, [ id ]);

    const solicitor = solicitorsPage.content.find(x => x.id === id);

    useEffect(() => {
        if (solicitor) {
            setFormState({ ...solicitor });
        }
    }, [ solicitor?.id ]);

    const handleSubmit = () => {
        updateSolicitor(id, formState);
    };

    return (
        <PageWrapper>
            <RoomSolicitingNavigation />
            <Container>
                {solicitor && (
                    <>
                        <RoomSolicitorForm formState={formState} setFormState={setFormState} />
                        <button type={'button'} onClick={handleSubmit}>Save</button>
                        <Divider />
                        <RoomSolicitorReviews solicitorId={solicitor?.id} />
                    </>
                )}
            </Container>
        </PageWrapper>
    );
};

export default RoomSolicitorDetailPage;
