import Axios from "axios";
import {constants} from "../../util/Constants";
import {defaultAxiosErrorHandler} from "../util/RequestUtils";

const controllerPath = 'beer-input';

export const getBeerBalanceOverviewRequest = (activeOnly = true, beerType = -1) => Axios({
    method: 'get',
    url: `${constants.apiBaseUrl}/${controllerPath}/balance?activeOnly=${activeOnly}&beerType=${beerType}`,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const getBeerInputsRequest = (page, beerType) => {
    let url = `${constants.apiBaseUrl}/${controllerPath}?sort=id,desc`;
    if (page) {
        url += `&page=${page}`;
    }
    if (beerType) {
        url += `&beerType=${beerType}`;
    }

    return Axios({
        method: 'get',
        url: url,
    }).then((response) => response.data).catch(defaultAxiosErrorHandler);
}

export const createBeerInputRequest = (data) => Axios({
    method: 'post',
    url: `${constants.apiBaseUrl}/${controllerPath}`,
    data: data
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const updateBeerInputRequest = (id, data) => Axios({
    method: 'put',
    url: `${constants.apiBaseUrl}/${controllerPath}/${id}`,
    data: data
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const uploadBeerInputImageRequest = (id, formData) => Axios({
    method: 'put',
    url: `${constants.apiBaseUrl}/${controllerPath}/${id}/image`,
    data: formData,
    headers: {'Content-Type': 'multipart/form-data' }
}).then((response) => response.data).catch(defaultAxiosErrorHandler);