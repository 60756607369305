import React, {useContext} from "react";
import styled from "styled-components";
import DefaultTable from "../../common/DefaultTable";
import {RoomSolicitingDayContext} from "../../../../shared/context/roomsoliciting/RoomSolicitingDayContext";
import {Td, Tr} from "../../../styles/Page";
import {deleteRoomSolicitingAppointmentRequest} from "../../../../shared/network/roomsoliciting/appointment/RoomSolicitingAppointmentRequests";
import {Link} from "react-router-dom";
import {routes} from "../../../../shared/routing/Routes";

const Container = styled.div`
    margin-top: 10px;
    border-top: 1px solid #C0C0C0;
    padding-top: 10px;

    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
`;

const headers = [
    {
        name: 'Naam',
    },
    {
        name: 'Opmerkingen',
    },
    {
        name: '',
    },
];

const RoomSolicitingDayAppointments = ({ selectedDate }) => {

    const { days, getDay } = useContext(RoomSolicitingDayContext);

    const day = days.find(x => x.date === selectedDate);

    const handleDeleteClick = (id) => {
        deleteRoomSolicitingAppointmentRequest(id).then(() => getDay(day.date));
    }

    const produceRow = (x) => {
        return (
            <Tr key={x.id}>
                <Td><Link to={`${routes.roomSolicitor}/${x.solicitor.id}`}>{x.solicitor.name}</Link></Td>
                <Td>{x.remarks}</Td>
                <Td><span onClick={() => handleDeleteClick(x.id)} style={{ cursor: 'pointer' }} title={'Verwijder'}>X</span></Td>
            </Tr>
        );
    };

    return (
        <Container>
            <DefaultTable
                headers={headers}
                convertRow={produceRow}
                content={day?.appointments || []}
                wrapperProps={{ height: 'auto' }}
            />
        </Container>
    );
};

export default RoomSolicitingDayAppointments;
