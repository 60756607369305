import React from "react";
import styled from "styled-components";
import TransactionEnrollmentCell from "./TransactionEnrollmentCell";
import TransactionEnrollmentCellAll from "./TransactionEnrollmentCellAll";

const RowWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

const Col = styled.div`
    display: flex;
    flex-direction: row;
    width: 33%;
`;

const getAmountEnrolled = (enrollments, userId) => {
    const enrollment = enrollments.find((x) => x?.user.id === userId);
    return enrollment ? enrollment.amount : 0;
}

const TransactionEnrollmentRow = ({users = [], enrolledArray = [], me, transaction, enroll, allUsers= [], multipleEnroll}) => {
    return (
        <RowWrapper>
            {users?.map((x, i) => (
                x.id !== -1 ?
                <Col key={i}>
                    <TransactionEnrollmentCell
                        userId={x.id}
                        username={x.username}
                        amountEnrolled={getAmountEnrolled(enrolledArray, x.id)}
                        canEdit={transaction.owner?.id === me.id}
                        enroll={enroll}
                    />
                </Col> :
                    <Col key={-1}>
                        <TransactionEnrollmentCellAll
                            canEdit={transaction.owner?.id === me.id}
                            users={allUsers}
                            transaction={transaction}
                            enroll={multipleEnroll}
                        />
                    </Col>
            ))}
        </RowWrapper>
    );
};

export default TransactionEnrollmentRow;