import React, {useContext, useEffect, useMemo, useState} from 'react';
import {routes} from "../../../shared/routing/Routes";
import MobilePageWrapper from "../MobilePageWrapper";
import ActionBar from "../ActionBar";
import styled from "styled-components";
import {Col} from "../../styles/Grid";
import {Table} from "../../../desktop/styles/Page";
import {TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography} from "@material-ui/core";
import {MeContext} from "../../../shared/context/MeContext";
import {UsersContext} from "../../../shared/context/UsersContext";
import {ShuttleDrunkTestContext} from "../../../shared/context/ShuttleDrunkTestContext";
import {colors} from "../../../util/Colors";
import FabStack from "../../styles/FabStack";
import MobileModalBalance from "../modal/MobileModalBalance";

const Wrapper = styled(Col)`
    height: 100%;
`;

const MobileShuttleDrunkTestPage = () => {

    const [ showBalanceModal, setShowBalanceModal ] = useState(false);

    const { loadMe } = useContext(MeContext);
    const { loadUsers} = useContext(UsersContext);
    const {
        shuttleDrunkTestResultPage,
        shuttleDrunkTestOverview,
        getShuttleDrunkTestResults,
        getShuttleDrunkTestOverview,
    } = useContext(ShuttleDrunkTestContext);

    useEffect(() => {
        loadMe();
        loadUsers();
        getShuttleDrunkTestOverview();
        getShuttleDrunkTestResults();
    }, []);

    const fabItems = useMemo(() => {
        return [
            {
                name: 'Overview',
                text: '☰',
                onClick: () => setShowBalanceModal(true)
            },
        ];
    }, []);

    return (
        <MobilePageWrapper>
            <ActionBar activeRoute={routes.donations}>
                <Wrapper>
                    <Toolbar style={{ backgroundColor: colors.lightOrange, color: colors.white }}>
                        <Typography variant="h6" id="tableTitle" component="div">
                            Shuttle Drunk Test
                        </Typography>
                    </Toolbar>
                    <TableContainer component={'div'}>
                        <Table aria-label="shuttle drunk test table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>Date</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>User</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Score</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {shuttleDrunkTestResultPage?.content.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={3}>No data available</TableCell>
                                    </TableRow>
                                )}
                                {shuttleDrunkTestResultPage?.content.map((x) => (
                                    <TableRow key={x.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>{x.date}</TableCell>
                                        <TableCell>{x.user.username}</TableCell>
                                        <TableCell>{x.score}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <FabStack items={fabItems} />
                    <MobileModalBalance
                        isOpen={showBalanceModal}
                        close={() => setShowBalanceModal(false)}
                        title={'Score overview'}
                        balanceOverview={shuttleDrunkTestOverview?.map(x => ({
                            user: x.user,
                            balance: x.score
                        }))}
                    />
                </Wrapper>
            </ActionBar>
        </MobilePageWrapper>
    );
};

export default MobileShuttleDrunkTestPage;