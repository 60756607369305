import Axios from "axios";
import {constants} from "../../util/Constants";
import {defaultAxiosErrorHandler} from "../util/RequestUtils";

const controllerPath = 'beer-types';

export const getAllBeerTypesRequest = () => Axios({
    method: 'get',
    url: `${constants.apiBaseUrl}/${controllerPath}`,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const createBeerTypeRequest = (data) => Axios({
    method: 'post',
    url: `${constants.apiBaseUrl}/${controllerPath}`,
    data: data
}).then((response) => response.data).catch(defaultAxiosErrorHandler);

export const updateBeerTypeRequest = (id, data) => Axios({
    method: 'put',
    url: `${constants.apiBaseUrl}/${controllerPath}/${id}`,
    data: data
}).then((response) => response.data).catch(defaultAxiosErrorHandler);