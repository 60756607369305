import {ClickableText, Table, TableWrapper, Th, THead, Tr} from "../../styles/Page";
import {useState} from "react";
import {genericSortAsc, genericSortDesc} from "../../../shared/util/SortUtils";
import {func} from "../../../util/Helpers";

const getSortIcon = (column = '', sort) => {
    let icon = (sort.direction === 'DESC') ? '⬆': '⬇';
    return column === sort.column ? icon : '';
};

const sortTableContent = (content = [], sort) => {
    if (sort.direction === 'DESC')
        return content.sort((a, b) => genericSortDesc(a, b, sort.column));
    else
        return content.sort((a, b) => genericSortAsc(a, b, sort.column));
}

const SortableTable = ({handleScroll = func, headers = [], content = [], convertRow = func, editable = false}) => {

    const [sort, setSort] = useState({
        column: 'id',
        direction: 'DESC'
    });

    const handleSortClicked = (column = '') => {
        if (sort.column === column) {
            if (sort.direction === 'DESC') {
                setSort({
                    column: column,
                    direction: 'ASC'
                });
            } else {
                setSort({
                    column:  'id',
                    direction: 'DESC'
                });
            }
        } else {
            setSort({
                column: column,
                direction: 'DESC'
            });
        }
    };

    return (
        <TableWrapper onScroll={handleScroll}>
            <Table>
                <THead>
                    <Tr>
                        {headers?.map(h => (
                            h.sortable
                            ?
                                <Th key={h.name} width={h.width}>
                                    <ClickableText onClick={() => handleSortClicked(h.sortKey)}>
                                        {h.name} {getSortIcon(h.sortKey, sort)}
                                    </ClickableText>
                                </Th>
                            :
                                <Th key={h.name} width={h.width}>
                                    {h.name}
                                </Th>
                        ))}
                        {editable && (
                            <Th width={10} />
                        )}
                    </Tr>
                </THead>
                <tbody onScroll={handleScroll}>
                    {sortTableContent(content, sort).map((x, i) => convertRow(x, i))}
                </tbody>
            </Table>
        </TableWrapper>
    )
};

export default SortableTable;