import React, {useContext, useEffect} from 'react';
import styled from "styled-components";
import MobilePageWrapper from "../../MobilePageWrapper";
import ActionBar from "../../ActionBar";
import {routes} from "../../../../shared/routing/Routes";
import {Row} from "../../../styles/Grid";
import {colors} from "../../../../util/Colors";
import {spacing} from "../../../util/Spacing";
import {fontSizes} from "../../../util/Fontsizes";
import {MeContext} from "../../../../shared/context/MeContext";
import {UsersContext} from "../../../../shared/context/UsersContext";
import {CookingBalanceContext} from "../../../../shared/context/CookingBalanceContext";
import {TransactionBalanceContext} from "../../../../shared/context/TransactionBalanceContext";
import {CookingEventContext} from "../../../../shared/context/CookingEventContext";
import {useHistory, useParams} from "react-router";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import isoWeek from "dayjs/plugin/isoWeek";
import List from "../../../styles/List";
import CookingListItem from "./CookingListItem";
import {getActiveEvents} from "../../../../shared/util/CookingEventUtils";
dayjs.extend(weekday);
dayjs.extend(isoWeek);

const Header = styled(Row)`
    justify-content: space-between;
    align-items: center;
    background-color: ${colors.orange};
    padding: ${spacing.small}px;
`;

const HeaderButton = styled.div`
    color: ${colors.white};
    padding: ${spacing.small}px;
    font-size: ${fontSizes.semiBig}px;
    cursor: pointer;
`;

const HeaderText = styled.div`
    color: ${colors.white};
    font-size: ${fontSizes.semiBig}px;
    font-weight: bold;
    text-transform: uppercase;
`;

const getFirstDateVisible = (dateString) => {
    let weekday = dayjs(dateString).weekday();
    // Correct sunday being 0
    if (weekday === 0) {
        weekday += 7;
    }
    return dayjs(dateString).subtract(weekday-1, 'days').format('YYYY-MM-DD');
};

const getLastDateVisible = (dateString) => {
    let weekday = dayjs(dateString).weekday();
    // Correct sunday being 0
    if (weekday === 0) {
        weekday += 7;
    }
    return dayjs(dateString).add(7-weekday, 'days').format('YYYY-MM-DD');
};

const getToTalEnrolled = (event) => {
    let result= 0;
    event.enrolled.forEach((x) => result += x.amount);
    return result;
};

const getMeEnrolled = (event, me) => {
    return event.enrolled.find(x => x.user.id === me.id)?.amount;
};

const getHeaderText = (currentDate) => {
    const weekDiff = dayjs(currentDate).endOf('week').diff(dayjs().endOf('week'), 'weeks');
    if (weekDiff === 0) {
        return 'This week';
    } else if (weekDiff === -1) {
        return 'Previous week';
    } else if (weekDiff < -1) {
        return `${weekDiff * -1} weeks ago`;
    } else if (weekDiff === 1) {
        return 'Next week';
    } else {
        return `In ${weekDiff} weeks`;
    }
};

const getAutoEnrolledAmount = (autoEnroll, date) => {
    const days = Object.keys(autoEnroll);
    const currentDay = days[dayjs(date).isoWeekday() - 1];
    const autoEnrollStatus = autoEnroll[currentDay];

    if (autoEnrollStatus === null) {
        return null;
    }
    if (autoEnrollStatus === true) {
        return 1;
    }
    if (autoEnrollStatus === false) {
        return 0
    }

    console.error('Unexpected value reached in getAutoEnrolledAmount function');
    return undefined;
}

const MobileCookingPage = () => {

    const history = useHistory();

    const { date } = useParams();

    const { me, loadMe } = useContext(MeContext);
    const { loadUsers } = useContext(UsersContext);

    const { getMyCookingBalance } = useContext(CookingBalanceContext);
    const { getMyTransactionBalance } = useContext(TransactionBalanceContext);

    const { cookingEvents, getCookingEvents } = useContext(CookingEventContext);

    const currentDate = date || dayjs().format('YYYY-MM-DD');

    const firstDate = getFirstDateVisible(currentDate);
    const lastDate = getLastDateVisible(currentDate);

    useEffect(() => {
        loadMe();
        loadUsers();
    }, []);

    useEffect(() => {
        if (me.id !== -1) {
            getMyCookingBalance();
            getMyTransactionBalance();
        }
    }, [me.id]);

    useEffect(() => {
        // Correct for backend call not being inclusive of last date
        const correctedLastDate = dayjs(lastDate).add(1, 'day').format('YYYY-MM-DD');
        // Make backend call
        getCookingEvents(firstDate, correctedLastDate);
    }, [firstDate, lastDate]);

    return (
        <MobilePageWrapper>
            <ActionBar activeRoute={routes.cookingCalender}>
                <Header>
                    <HeaderButton onClick={() => history.push(`${routes.cookingCalender}/${dayjs(currentDate).subtract(1, 'week').format('YYYY-MM-DD')}`)}>◀</HeaderButton>
                    <HeaderText>{getHeaderText(currentDate)}</HeaderText>
                    <HeaderButton onClick={() => history.push(`${routes.cookingCalender}/${dayjs(currentDate).add(1, 'week').format('YYYY-MM-DD')}`)}>▶</HeaderButton>
                </Header>
                <List
                    data={getActiveEvents(cookingEvents, firstDate, lastDate)}
                    renderItem={(item) => (
                        <CookingListItem
                            key={item.id}
                            date={item.date}
                            cook={item.cook?.username}
                            amountEnrolled={getToTalEnrolled(item)}
                            amountMeEnrolled={getMeEnrolled(item, me)}
                            isToday={item.date === dayjs().format('YYYY-MM-DD')}
                            autoEnrollAmount={getAutoEnrolledAmount(me.autoEnroll, item.date)}
                        />
                    )}
                />
            </ActionBar>
        </MobilePageWrapper>
    );
};

export default MobileCookingPage;
