import React, {useContext, useEffect} from "react";
import {StatsContext} from "../../../shared/context/StatsContext";
import {Table} from "../../../desktop/styles/Page";
import {TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";

const MobileAveragePricesTable = () => {

    const { averageCosts, getAverageCosts } = useContext(StatsContext);

    useEffect(() => {
        if (averageCosts.length === 0) {
            getAverageCosts();
        }
    }, []);

    return (
        <>
            <TableContainer component={'div'}>
                <Table aria-label="forgotten transactions table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>User</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Average</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>σ</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Amount</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {averageCosts.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={4}>No data available</TableCell>
                            </TableRow>
                        )}
                        {averageCosts.map((x) => (
                            <TableRow key={x.user.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell>{x.user.username}</TableCell>
                                <TableCell>€{x.price.toFixed(2)}</TableCell>
                                <TableCell>{x.stdDev.toFixed(2)}</TableCell>
                                <TableCell>{x.amount}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default MobileAveragePricesTable;
