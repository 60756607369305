import React, {createContext, useState} from "react";
import {constants} from "../../util/Constants";

export const SettingsContext = createContext({});

const SettingsContextProvider = (props) => {

    const [settings, _setSettings] = useState(JSON.parse(window.localStorage.getItem(constants.localStorageSettingsKey) || '{}'));

    const setSettings = (settings) => {
        window.localStorage.setItem(constants.localStorageSettingsKey, JSON.stringify(settings));
        _setSettings(settings);
    };

    return (
        <SettingsContext.Provider value={{ settings, setSettings }}>
            {props.children}
        </SettingsContext.Provider>
    );
};

export default SettingsContextProvider;