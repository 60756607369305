import React from 'react';
import styled from "styled-components";
import {colors} from "../../../util/Colors";
import RoundImage from "../RoundImage";
import {formatBalance} from "../../util/Utils";
import {Row} from "../../styles/Grid";
import {spacing} from "../../util/Spacing";
import {fontSizes} from "../../util/Fontsizes";

const StyledRow = styled(Row)`
    flex-wrap: nowrap;
    flex: 1;
    align-items: center;
    
    padding-top: ${spacing.small}px;
    padding-left: ${spacing.base}px;
    padding-right: ${spacing.base}px;
    
    justify-content: space-between;
`;

const ImageAndName = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Name = styled.div`
    margin-left: ${spacing.base}px;
    font-size: ${fontSizes.base}px;
    color: ${colors.black};
    font-weight: bold;
`;

const Balance = styled.div`
    font-size: ${fontSizes.base}px;
    color: ${colors.black};
    font-weight: bold;
`;

const BalanceListItem = ({ index = 0, imageId, username = '', balance = 0}) => {
    return (
        <StyledRow style={{ backgroundColor: index % 2 === 0 ? colors.lightgrey : colors.white }}>
            <ImageAndName>
                <RoundImage imageId={imageId} width={'30px'} />
                <Name>{username}</Name>
            </ImageAndName>
            <Balance>{formatBalance(balance)}</Balance>
        </StyledRow>
    );
};

export default React.memo(BalanceListItem);