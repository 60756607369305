import React from "react";
import styled from "styled-components";
import {colors} from "../../util/Colors";
import {spacing} from "../util/Spacing";
import {fontSizes} from "../util/Fontsizes";

const paddingLeft = spacing.small;

const StyledInput = styled.input`
    border: none;
    width: calc(100% - ${paddingLeft}px);
    font-size: ${fontSizes.input}px;
    padding-left: ${paddingLeft}px;
    padding-bottom: ${spacing.small}px;
    margin-bottom: ${spacing.base}px;
`;

const Input = ({ type = 'text', color = colors.black, borderColor = colors.black, backgroundColor = colors.white, ...props }) => {
    return (
        <StyledInput
            type={type}
            style={{
                color: color,
                backgroundColor: backgroundColor,
                borderBottom: `1px solid ${borderColor}`,
            }}
            {...props}
        />
    );
};

export default Input;