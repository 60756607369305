import React, {useCallback, useContext, useEffect, useState} from "react";
import styled from "styled-components";
import MobilePageWrapper from "../MobilePageWrapper";
import ActionBar from "../ActionBar";
import {routes} from "../../../shared/routing/Routes";
import {Col} from "../../styles/Grid";
import List from "../../styles/List";
import {MeContext} from "../../../shared/context/MeContext";
import {UsersContext} from "../../../shared/context/UsersContext";
import {CookingBalanceContext} from "../../../shared/context/CookingBalanceContext";
import {TransactionBalanceContext} from "../../../shared/context/TransactionBalanceContext";
import {CookingDonationContext} from "../../../shared/context/CookingDonationContext";
import DonationListItem from "./DonationListItem";
import Fab from "../../styles/Fab";
import CreateDonationModal from "./CreateDonationModal";
import Loading from "../../styles/Loading";

const Wrapper = styled(Col)`
    height: 100%;
`;

const MobileDonationPage = () => {

    const [ showCreateModal, setShowCreateModal ] = useState(false);

    const { me, loadMe } = useContext(MeContext);
    const { users, loadUsers } = useContext(UsersContext);

    const { getMyCookingBalance } = useContext(CookingBalanceContext);
    const { getMyTransactionBalance } = useContext(TransactionBalanceContext);

    const { isLoading, cookingDonationsPage, getCookingDonations } = useContext(CookingDonationContext);

    useEffect(() => {
        loadMe();
        loadUsers();
        getCookingDonations();
    }, []);

    useEffect(() => {
        if (me.id !== -1) {
            getMyCookingBalance();
            getMyTransactionBalance();
        }
    }, [me.id]);

    const getNewPage = () => {
        if (!cookingDonationsPage.last) {
            getCookingDonations(cookingDonationsPage.number + 1);
        }
    };

    const closeCreateModal = useCallback(() => {
        setShowCreateModal(false);
    }, []);

    return (
        <MobilePageWrapper>
            <ActionBar activeRoute={routes.donations}>
                <Wrapper>
                    <List
                        data={cookingDonationsPage?.content}
                        renderItem={(item) => (
                            <DonationListItem
                                key={item.id}
                                createdOn={item.createdOn}
                                createdBy={item.createdBy.username}
                                amount={item.amount}
                                recipient={item.recipient.username}
                            />
                        )}
                        onEndReached={getNewPage}
                    />

                    <Fab text={'+'} onClick={() => setShowCreateModal(true)} />

                    <CreateDonationModal
                        isOpen={showCreateModal}
                        close={closeCreateModal}
                        users={users}
                        meId={me.id}
                    />

                    <Loading isOpen={isLoading} />
                </Wrapper>
            </ActionBar>
        </MobilePageWrapper>
    )
}

export default MobileDonationPage;