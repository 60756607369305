import React from "react";
import styled from "styled-components";
import Header from "./header/Header";
import {FlexCenterContainer} from "../styles/Layout";
import {PanelContainer} from "../styles/Panel";
import MainPanelWrapper from "./MainPanel";
import ConstructionPanel from "./ConstructionPanel";
import SideNav from "./navigation/SideNav";
import useJwt from "../../shared/hooks/JwtHook";
import useShowNotifications from "../../shared/hooks/ShowNotificationsHook";
import Background from "../images/background_summer.jpg";

const Page = styled.div`
    background-image: url(${Background});
    background-size: cover;
    
    min-width: 1000px;
    height: 100vh;
`;

/**
 * Wrapper component that MUST be used by every page
 */
const PageWrapper = (props) => {
    const { jwt } = useJwt();

    // Load notifications if a jwt is found
    useShowNotifications(jwt);

    return (
        <Page>
            <Header />
            <FlexCenterContainer>
                <PanelContainer>
                    {props.leftPanel || <SideNav />}
                </PanelContainer>
                <MainPanelWrapper>
                    {props.children}
                </MainPanelWrapper>
                <PanelContainer>
                    {!props.rightPanels?.length ? (<ConstructionPanel />) : props.rightPanels.map((x, i) => <React.Fragment key={i}>{x}</React.Fragment>)}
                </PanelContainer>
            </FlexCenterContainer>
        </Page>
    );
};

export default PageWrapper;
