import React from "react";
import styled from 'styled-components';
import SmallGreyImage from '../../../images/cookingSchedule/cell_small_grey.png';
import SmallBlueImage from '../../../images/cookingSchedule/cell_small_blue.png';
import LargeGreyImage from '../../../images/cookingSchedule/cell_large_grey.png';
import LargeBlueImage from '../../../images/cookingSchedule/cell_large_blue.png';
import CheckGreyImage from '../../../images/checkbox/check_grey.png';
import CheckOrangeImage from '../../../images/checkbox/check_orange.png';
import CheckCrossed from '../../../images/checkbox/check_crossed.png';
import CheckGreyNone from '../../../images/checkbox/check_none_14px.png';
import CheckLightGrey from '../../../images/checkbox/check_light_grey.png';
import CheckLightCrossed from '../../../images/checkbox/check_light_crossed.png';
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import isoWeek from "dayjs/plugin/isoWeek";
import {Row} from "../../../styles/Layout";
import {useHistory} from "react-router-dom";
dayjs.extend(weekday);
dayjs.extend(isoWeek);

const Base = styled.div`
    padding: 3px;
    width: 62px;
`;

const Small = styled(Base)`
    height: 34px;
`;

const SmallGrey = styled(Small)`
    background-image: url(${SmallGreyImage});
`;

const SmallBlue = styled(Small)`
    background-image: url(${SmallBlueImage});
    color: white;
`;

const Large = styled(Base)`
    height: 74px;
`;

const LargeGrey = styled(Large)`
    background-image: url(${LargeGreyImage});
`;

const LargeBlue = styled(Large)`
    background-image: url(${LargeBlueImage});
    color: white;
`;

const SmallRow = styled(Row)`
    font-size: 9.6px;
    font-family: Verdana;
    display: flex;
    justify-content: space-between;
`;

const PointerSpan = styled.span`
    cursor: pointer;
    ${props => props.selected && 'text-decoration: underline; font-weight: bold;'}
    
    :hover {
        text-decoration: underline;
    }
`;

const FloatRightSpan = styled.span`
    float: right;
`;

const CheckBox = styled.div`
    margin-top: -2px;
    background-image: url(${props => props.image});
    ${props => !props.disabled && 'cursor: pointer;'}
    background-repeat: no-repeat;
    width: 14px;
    height: 14px;
`;

const EnrolledAmount = styled.div`
    font: bold 40px Verdana;
    ${props => props.underlined && 'text-decoration: underline;'}
    text-align: center;
    
    cursor: pointer;
    
    :hover {
        text-decoration: underline;
    }
`;

const dayOfWeekToName = (weekDay) => ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"][weekDay - 1];

const CookingTableBodyCell = ({ cookingEvent, me, large, current, setMeAsCook, unSetMeAsCook, enroll }) => {
    const history = useHistory();

    const isEnrolled = cookingEvent.enrolled.some((x) => x.user.id === me.id && x.amount > 0);
    const isFutureDate = !dayjs(cookingEvent.date).isBefore(dayjs().subtract(1, 'day'));
    const isEnrolledZeroTimes = cookingEvent.enrolled.some((x) => x.user.id === me.id && x.amount === 0);

    const weekday = dayOfWeekToName(dayjs(cookingEvent.date).isoWeekday());
    const autoEnroll = !isEnrolled && me.autoEnroll[weekday];

    const handleCheckBoxClick = () => {
        if (isFutureDate) {
            enroll(cookingEvent.id, isEnrolled ? 0 : 1);
        }
    };

    const handleCookClick = () => {
        if (!current) {
            goToThisDate()
        } else {
            if (cookingEvent.cook) {
                if (cookingEvent.cook.id === me.id) {
                    unSetMeAsCook(cookingEvent.id);
                }
            } else {
                setMeAsCook(cookingEvent.id);
            }
        }
    }

    const getToTalEnrolled = () => {
        let result= 0;
        cookingEvent.enrolled.forEach((x) => result += x.amount);
        return result;
    };
    
    const goToThisDate = () => {
        history.push(`/kookagenda/${dayjs(cookingEvent.date).format('YYYY-MM-DD')}`);
    };

    const getImage = () => {
        if (isEnrolledZeroTimes)
            return CheckCrossed;

        if (isFutureDate) {
            if (isEnrolled)
                return CheckOrangeImage;

            if (autoEnroll === true) {
                return CheckLightGrey;
            } else if (autoEnroll === false) {
                return CheckLightCrossed;
            }
        } else {
            if (isEnrolled)
                return CheckGreyImage;
        }

        return CheckGreyNone;
    }
    
    const getCellContent = (large) => {
        return (
            <React.Fragment>
                <SmallRow>
                    <PointerSpan selected={current} onClick={goToThisDate}>
                        {dayjs(cookingEvent.date).format('D MMM')}
                    </PointerSpan>
                    <FloatRightSpan>
                        <CheckBox
                            checked={isEnrolled}
                            future={isFutureDate}
                            onClick={handleCheckBoxClick}
                            disabled={!isFutureDate}
                            image={getImage()}
                        />
                    </FloatRightSpan>
                </SmallRow>
                <SmallRow onClick={handleCookClick}><PointerSpan>{cookingEvent.cook ? cookingEvent.cook.username : '.........'}</PointerSpan></SmallRow>
                {large && (
                    <EnrolledAmount
                        underlined={current}
                        onClick={goToThisDate}
                    >
                        {getToTalEnrolled()}
                    </EnrolledAmount>
                )}
            </React.Fragment>
        );
    };

    if (!cookingEvent) {
        return <React.Fragment/>;
    }
    
    if (large) {
        // If the given date is not before the current dayjs it is in the future (or today, which is the future)
        if (!dayjs(cookingEvent.date).isBefore(dayjs().subtract(1, 'day'))) {
            if (current) {
                // Large future current
                return <LargeBlue>{getCellContent(true)}</LargeBlue>
            } else {
                // Large future not current
                return <LargeBlue>{getCellContent(true)}</LargeBlue>
            }
        } else {
            if (current) {
                // Large past current
                return <LargeGrey>{getCellContent(true)}</LargeGrey>
            } else {
                // Large past not current
                return <LargeGrey>{getCellContent(true)}</LargeGrey>
            }
        }
    } else {
        // If the given date is not before the current dayjs it is in the future (or today, which is the future)
        if (!dayjs(cookingEvent.date).isBefore(dayjs().subtract(1, 'day'))) {
            // Small future
            return <SmallBlue>{getCellContent(false)}</SmallBlue>
        } else {
            // Small past
            return <SmallGrey>{getCellContent(false)}</SmallGrey>
        }
    }
};

export default CookingTableBodyCell;
