import MobilePageWrapper from "../../MobilePageWrapper";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {routes} from "../../../../shared/routing/Routes";
import ActionBar from "../../ActionBar";
import {MeContext} from "../../../../shared/context/MeContext";
import {AlbumContext} from "../../../../shared/context/AlbumsContext";
import {isAdmin} from "../../../../util/Helpers";
import Fab from "../../../styles/Fab";
import {List, ListItem, ListItemSecondaryAction, ListItemText, Toolbar, Typography} from "@material-ui/core";
import {useHistory} from "react-router";
import {colors} from "../../../../util/Colors";
import CreateAlbumModal from "./CreateAlbumModal";

//TODO: create modal
const MobileCookingPage = () => {

    const history = useHistory();

    const [ showCreateModal, setShowCreateModal ] = useState(false);

    const { me, loadMe } = useContext(MeContext);
    const { albums, loadAlbums, deleteAlbum } = useContext(AlbumContext);

    useEffect(() => {
        loadMe();
        loadAlbums();
    }, []);

    const closeShowCreateModal = useCallback(() => {
        setShowCreateModal(false);
    }, []);

    const meIsAdmin = isAdmin(me);

    return (
        <MobilePageWrapper>
            <ActionBar activeRoute={routes.albums}>
                <Toolbar style={{ backgroundColor: colors.lightOrange, color: colors.white }}>
                    <Typography variant="h6" id="tableTitle" component="div">
                        Albums
                    </Typography>
                </Toolbar>
                <List component="nav" aria-label="main albums" style={{ padding: 0 }}>
                    {albums.map(x => (
                        <ListItem
                            key={x.id}
                            button
                            divider
                            onClick={() => history.push(`${routes.albums}/${x.id}`)}
                        >
                            <ListItemText primary={x.name} />
                            {meIsAdmin && x.editable && (
                                <ListItemSecondaryAction>
                                    <span onClick={() => deleteAlbum(x.id)} style={{ cursor: 'pointer' }} title={'Delete'}>X</span>
                                </ListItemSecondaryAction>
                            )}
                        </ListItem>
                    ))}
                </List>

                {meIsAdmin && (
                    <>
                        <Fab text={'+'} onClick={() => setShowCreateModal(true)} />

                        <CreateAlbumModal isOpen={showCreateModal} close={closeShowCreateModal} title={'Create album'} />
                    </>
                )}

            </ActionBar>
        </MobilePageWrapper>
    );
};

export default MobileCookingPage;