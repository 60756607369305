import UsersContextProvider from "./context/UsersContext";
import CookingBalanceContextProvider from "./context/CookingBalanceContext";
import TransactionBalanceContextProvider from "./context/TransactionBalanceContext";
import CookingEventContextProvider from "./context/CookingEventContext";
import TransactionContextProvider from "./context/TransactionContext";
import CookingDonationContextProvider from "./context/CookingDonationContext";
import BeerTypeContextProvider from "./context/BeerTypeContext";
import BeerInputContextProvider from "./context/BeerInputContext";
import ShuttleDrunkTestContextProvider from "./context/ShuttleDrunkTestContext";
import StatsContextProvider from "./context/StatsContext";
import MeContextProvider from "./context/MeContext";
import { ThemeProvider } from "@material-ui/core";
import { createTheme } from '@material-ui/core/styles'
import {colors} from "../util/Colors";
import SettingsContextProvider from "./context/SettingsContext";
import AlbumContextProvider from "./context/AlbumsContext";
import PrintingContextProvider from "./context/PrintingContext";
import RoomSolicitingDayContextProvider from "./context/roomsoliciting/RoomSolicitingDayContext";
import RoomSolicitingAppointmentContextProvider from "./context/roomsoliciting/RoomSolicitorContext";
import RoomSolicitorReviewContextProvider from "./context/roomsoliciting/RoomSolicitorReviewContext";

const theme = createTheme({
    palette: {
        primary: {
            main: colors.orange,
            light: colors.lightOrange,
            dark: colors.darkOrange
        },
        secondary: {
            main: colors.lightOrange,
        },
    },
});

function ContextProvider({ children }) {
    return (
        <ThemeProvider theme={theme}> {/* used for mobile styling */}
            <SettingsContextProvider>
                <MeContextProvider>
                    <UsersContextProvider>
                        <CookingBalanceContextProvider>
                            <TransactionBalanceContextProvider>
                                <CookingEventContextProvider>
                                    <TransactionContextProvider>
                                        <CookingDonationContextProvider>
                                            <BeerTypeContextProvider>
                                                <BeerInputContextProvider>
                                                        <ShuttleDrunkTestContextProvider>
                                                            <StatsContextProvider>
                                                                <AlbumContextProvider>
                                                                    <PrintingContextProvider>
                                                                        <RoomSolicitingDayContextProvider>
                                                                            <RoomSolicitingAppointmentContextProvider>
                                                                                <RoomSolicitorReviewContextProvider>
                                                                                    {children}
                                                                                </RoomSolicitorReviewContextProvider>
                                                                            </RoomSolicitingAppointmentContextProvider>
                                                                        </RoomSolicitingDayContextProvider>
                                                                    </PrintingContextProvider>
                                                                </AlbumContextProvider>
                                                            </StatsContextProvider>
                                                        </ShuttleDrunkTestContextProvider>
                                                </BeerInputContextProvider>
                                            </BeerTypeContextProvider>
                                        </CookingDonationContextProvider>
                                    </TransactionContextProvider>
                                </CookingEventContextProvider>
                            </TransactionBalanceContextProvider>
                        </CookingBalanceContextProvider>
                    </UsersContextProvider>
                </MeContextProvider>
            </SettingsContextProvider>
        </ThemeProvider>
    )
}

export default ContextProvider;