import React, {useCallback, useContext, useEffect, useState} from "react";
import styled from "styled-components";
import MobilePageWrapper from "../../MobilePageWrapper";
import ActionBar from "../../ActionBar";
import {routes} from "../../../../shared/routing/Routes";
import {useParams} from "react-router";
import {MeContext} from "../../../../shared/context/MeContext";
import {UsersContext} from "../../../../shared/context/UsersContext";
import {CookingBalanceContext} from "../../../../shared/context/CookingBalanceContext";
import {TransactionBalanceContext} from "../../../../shared/context/TransactionBalanceContext";
import {CookingEventContext} from "../../../../shared/context/CookingEventContext";
import {colors} from "../../../../util/Colors";
import dayjs from "dayjs";
import {fontSizes} from "../../../util/Fontsizes";
import {FWRow, Row} from "../../../styles/Grid";
import {spacing} from "../../../util/Spacing";
import RoundImage from "../../RoundImage";
import Button from "../../../styles/Button";
import SmallList, {SmallListTitle} from "../../../styles/SmallList";
import EnrollmentListItem from "./EnrollmentListItem";
import {formatBalance} from "../../../util/Utils";
import {getActiveEvents} from "../../../../shared/util/CookingEventUtils";
import EditEnrollmentsModal from "./EditEnrollmentsModal";
import ActionsModal from "./ActionsModal";
import DryersModal from "./DryersModal";
import CookingTransactionModal from "./CookingTransactionModal";
import {isAdmin, preventNegative} from "../../../../util/Helpers";
import MobileModalBalance from "../../modal/MobileModalBalance";
import {customSortAsc} from "../../../../shared/util/SortUtils";

const Date = styled(Row)`
    justify-content: center;
    align-items: center;
    
    padding: ${spacing.base}px;

    background-color: ${colors.orange};
    color: ${colors.white};
    
    font-size: ${fontSizes.big}px;
    font-weight: bold;
`;

const CookWrapper = styled(Row)`
    justify-content: center;

    height: 65px;
    
    background-color: ${colors.lightOrange};
`;

const RoundImageWrapper = styled.div`
    padding-top: ${spacing.base}px;
`;

const CookButtonWrapper = styled(Row)`
    justify-content: center;
    background-color: ${colors.lightOrange};
    padding: ${spacing.big}px;
`;

const PageButtonWrapper = styled.div`
    margin-top: ${spacing.small}px;
    margin-left: ${spacing.base}px;
    margin-right: ${spacing.base}px;
    margin-bottom: ${spacing.base}px;
`;

const PriceText = styled.span`
    padding-left: ${spacing.base}px;
    color: ${colors.textDarkGrey};
`;

const Footer = styled(FWRow)`
    position: absolute;
    bottom: 0;
    left: 0;
    
    background-color: ${colors.lightOrange};
`;

const FooterContent = styled.div`
    display: flex;
    justify-content: center;
    flex: 1;
    
    padding-top: ${spacing.small}px;
    padding-bottom: ${spacing.small}px;
    padding-left: ${spacing.base}px;
    padding-right: ${spacing.base}px;
`;

const getToTalEnrolled = (event) => {
    let result= 0;
    event.enrolled.forEach((x) => result += x.amount);
    return result;
};

const MobileCookingDetailPage = () => {

    const { date } = useParams();

    const [ showEnrollmentsModal, setShowEnrollmentsModal ] = useState(false);
    const [ showCookingTransactionModal, setShowCookingTransactionModal ] = useState(false);
    const [ showDryersModal, setShowDryersModal ] = useState(false);
    const [ showActionsModal, setShowActionsModal ] = useState(false);
    const [ showPointsOverviewModal, setShowPointsOverviewModal ] = useState(false);

    const { me, loadMe } = useContext(MeContext);
    const { users, loadUsers } = useContext(UsersContext);

    const { cookingBalanceOverview, getCookingBalanceOverview } = useContext(CookingBalanceContext);
    const { getMyTransactionBalance } = useContext(TransactionBalanceContext);

    const { cookingEvents, getCookingEvent, setMeAsCook, unSetMeAsCook, enroll } = useContext(CookingEventContext);

    const cookingEvent = getActiveEvents(cookingEvents, date, date)[0];

    const dateIsInPast = dayjs(cookingEvent?.date).isBefore(dayjs().startOf('day'));
    const meIsCook = cookingEvent.cook?.id === me.id;
    const meIsAdmin = isAdmin(me);
    const myEnrollment = cookingEvent.enrolled.find(x => x.user.id === me.id);
    const canEdit = !dateIsInPast || meIsCook;

    useEffect(() => {
        loadMe();
        loadUsers();
    }, []);

    useEffect(() => {
        if (me.id !== -1) {
            getCookingBalanceOverview();
            getMyTransactionBalance();
        }
    }, [me.id]);

    useEffect(() => {
        getCookingEvent(date);
    }, [date]);

    const enrollMe = async (amountDiff = 1) => {
        const currentAmount = myEnrollment ? myEnrollment.amount : 0;
        const newAmount = preventNegative(currentAmount + amountDiff);
        enroll(cookingEvent.id, newAmount);
    };

    const unEnrollAsCook = useCallback(() => {
        unSetMeAsCook(cookingEvent.id);
    }, [cookingEvent.id]);

    const closeEnrollmentsModal = useCallback(() => {
        setShowEnrollmentsModal(false);
    }, []);

    const closeCookingTransactionModal = useCallback(() => {
        setShowCookingTransactionModal(false);
    }, []);

    const closeDryersModal = useCallback(() => {
        setShowDryersModal(false);
    }, []);

    const closeActionsModal = useCallback(() => {
        setShowActionsModal(false);
    }, []);

    const closePointsOverviewModal = useCallback(() => {
        setShowPointsOverviewModal(false);
    }, []);

    return (
        <MobilePageWrapper>
            <ActionBar activeRoute={routes.cookingCalender}>
                <Date>{dayjs(date).format('DD MMMM')}</Date>
                {cookingEvent.cook ? (
                    <CookWrapper>
                        <RoundImageWrapper>
                            <RoundImage
                                imageId={cookingEvent.cook.image}
                                width={'80px'}
                                borderColor={colors.white}
                                onClick={meIsCook ? unEnrollAsCook : undefined}
                            />
                        </RoundImageWrapper>
                    </CookWrapper>
                ) : (
                    <CookButtonWrapper>
                        <Button text={'I want to cook'} onClick={() => setMeAsCook(cookingEvent.id)} />
                    </CookButtonWrapper>
                )}
                <SmallList
                    title={`Enrollments (${getToTalEnrolled(cookingEvent)})`}
                    data={cookingEvent.enrolled.filter(x => x.amount > 0).sort((a, b) => customSortAsc(a, b, (x) => x.user.id))}
                    renderItem={item => (
                        <EnrollmentListItem
                            key={item.user.id}
                            imageId={item.user.image}
                            username={item.user.username}
                            amount={item.amount}
                        />
                    )}
                />
                <SmallList
                    title={'Dryers'}
                    data={[cookingEvent.dryer1, cookingEvent.dryer2]}
                    renderItem={item => item && (
                        <EnrollmentListItem
                            key={item.id}
                            imageId={item.image}
                            username={item.username}
                        />
                    )}
                />
                <div>
                    <SmallListTitle>Price</SmallListTitle>
                    <PriceText>{formatBalance(cookingEvent.transaction?.amount)}</PriceText>
                </div>

                {meIsCook && (
                    <div style={{ marginBottom: 46.5 }}>
                        <SmallListTitle>Cook Controls</SmallListTitle>
                        <Row>
                            <PageButtonWrapper style={{ flex: 1 }}>
                                <Button text={'enrollments'} onClick={() => setShowEnrollmentsModal(true)} style={{ width: '100%' }} />
                            </PageButtonWrapper>
                            <PageButtonWrapper style={{ flex: 1 }}>
                                <Button text={'transaction'} onClick={() => setShowCookingTransactionModal(true)} style={{ width: '100%' }} />
                            </PageButtonWrapper>
                        </Row>
                        <Row>
                            <PageButtonWrapper style={{ flex: 1 }}>
                                <Button text={'dryers'} onClick={() => setShowDryersModal(true)} style={{ width: '100%' }} />
                            </PageButtonWrapper>
                            <PageButtonWrapper style={{ flex: 1 }}>
                                <Button text={'actions'} onClick={() => setShowActionsModal(true)} style={{ width: '100%' }} />
                            </PageButtonWrapper>
                        </Row>

                        <EditEnrollmentsModal
                            isOpen={showEnrollmentsModal}
                            close={closeEnrollmentsModal}
                            title={'Edit enrollments'}
                            users={users}
                            eventId={cookingEvent.id}
                        />
                        <CookingTransactionModal
                            isOpen={showCookingTransactionModal}
                            close={closeCookingTransactionModal}
                            title={'Create transaction'}
                            eventId={cookingEvent.id}
                            price={cookingEvent.transaction?.amount}
                        />
                        <DryersModal
                            isOpen={showDryersModal}
                            close={closeDryersModal}
                            title={'Dryers'}
                            event={cookingEvent}
                        />
                        <ActionsModal
                            isOpen={showActionsModal}
                            close={closeActionsModal}
                            title={'Actions'}
                            eventId={cookingEvent.id}
                        />
                    </div>
                )}

                {!meIsCook && meIsAdmin && (
                    <div style={{ marginBottom: 46.5 }}>
                        <SmallListTitle>Admin Controls</SmallListTitle>
                        <Row>
                            <PageButtonWrapper style={{ flex: 1 }}>
                                <Button text={'dryers'} onClick={() => setShowDryersModal(true)} style={{ width: '100%' }} />
                            </PageButtonWrapper>
                            <PageButtonWrapper style={{ flex: 1 }}>
                                <Button text={'actions'} onClick={() => setShowActionsModal(true)} style={{ width: '100%' }} />
                            </PageButtonWrapper>
                        </Row>
                        <DryersModal
                            isOpen={showDryersModal}
                            close={closeDryersModal}
                            title={'Dryers'}
                            event={cookingEvent}
                        />
                        <ActionsModal
                            isOpen={showActionsModal}
                            close={closeActionsModal}
                            title={'Actions'}
                            eventId={cookingEvent.id}
                        />
                    </div>
                )}

                <MobileModalBalance
                    isOpen={showPointsOverviewModal}
                    close={closePointsOverviewModal}
                    title={'Cooking points overview'}
                    balanceOverview={cookingBalanceOverview}
                />

                <Footer>
                    <FooterContent>
                        <Button text={'Overview'} onClick={() => setShowPointsOverviewModal(true)} />
                        {!myEnrollment && canEdit && (
                            <Button text={'Enroll'} onClick={() => enrollMe(1)} style={{ marginLeft: spacing.base }} />
                        )}
                        {myEnrollment && canEdit && (
                            <div style={{ marginLeft: spacing.base }}>
                                <Button text={'-'} onClick={() => enrollMe(-1)} style={{ minWidth: 15, width: 15 }} />
                                <span style={{ color: colors.white, padding: spacing.base }}>
                                    {myEnrollment.amount}
                                </span>
                                <Button text={'+'} onClick={() => enrollMe(1)} style={{ minWidth: 15, width: 15 }} />
                            </div>
                        )}
                    </FooterContent>
                </Footer>

            </ActionBar>
        </MobilePageWrapper>
    );
};

export default MobileCookingDetailPage;
