import styled from "styled-components";

const ButtonHolder = styled.div`
    border: 1px solid white;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 7px;
`;

export default ButtonHolder;
