import MobileAveragePricesTable from "./MobileAveragePricesTable";
import AveragePricesPerMonthChart from "../../../shared/stats/AveragePricesPerMonthChart";
import styled from "styled-components";

const AverageItem = styled.div`
    margin-bottom: 10px;
`;

const MobileAveragePrices = () => {
    return (
        <>
            <AverageItem>
                <MobileAveragePricesTable />
            </AverageItem>
            <AverageItem>
                <AveragePricesPerMonthChart />
            </AverageItem>
        </>
    )
}

export default MobileAveragePrices;
