import React from "react";
import styled from 'styled-components';
import TransactionTableBodyCell from "./TransactionTableBodyCell";

const Row = styled.tr`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    
    background-color: #c7dbee;
    :nth-child(even) {
        background-color: #a1c5de;
    }
`;

const TransactionTableBodyRow = ({users = [], transaction, active}) => {

    const getCells = () => {
        const width = (470 - (39 + 64 + (users.length * 3) + 17)) / users.length;

        let amounts = [];
        users.forEach((a, i) => {
            let accountIsInTransaction = transaction.enrollments.filter((e) => e.user.id === a.id)[0];
            let price = accountIsInTransaction ? accountIsInTransaction.price : 0;
            let accountIsOwner = transaction.owner.id === a.id;
            price = accountIsOwner ? Math.round((transaction.amount + price) * 100) / 100 : price;
            amounts.push(<TransactionTableBodyCell width={width} active={active} type={'amount'} key={i} content={price === 0 ? '' : price} />);
        });

        return (
            <React.Fragment>
                <TransactionTableBodyCell active={active} width={37} transaction={transaction} type={'date'} content={transaction.date}/>
                <TransactionTableBodyCell active={active} width={61} transaction={transaction} type={'description'} content={transaction.description} />
                {amounts}
            </React.Fragment>
        );
    };

    return transaction ? (
        <Row>
            {getCells()}
        </Row>
    ) : <></>;
};

export default TransactionTableBodyRow;
