import React, {createContext, useState} from "react";
import {
    getRoomSolicitorReviewsBySolicitorIdRequest,
    postRoomSolicitorReviewRequest, putRoomSolicitorReviewRequest
} from "../../network/roomsoliciting/review/RoomSolicitorReviewRequests";
import {mergeArraysById} from "../../util/ArrayUtils";
import {genericSortDesc} from "../../util/SortUtils";

export const RoomSolicitorReviewContext = createContext({});

const RoomSolicitorReviewContextProvider = (props) => {

    const [ reviews, setReviews ] = useState([]);

    const getReviews = async (solicitorId) => {
        const result = await getRoomSolicitorReviewsBySolicitorIdRequest(solicitorId);
        if (result) {
            setReviews(result);
        }
    };

    const createReview = async (data) => {
        const result = await postRoomSolicitorReviewRequest(data);
        if (result) {
            setReviews(mergeArraysById(reviews, [ result ]).sort((a,b) => genericSortDesc(a, b, 'id')));
        }
    };

    const updateReview = async (id, data) => {
        const result = await putRoomSolicitorReviewRequest(id, data);
        if (result) {
            setReviews(mergeArraysById(reviews, [ result ]).sort((a,b) => genericSortDesc(a, b, 'id')));
        }
    };

    return (
        <RoomSolicitorReviewContext.Provider value={{ reviews, getReviews, createReview, updateReview }}>
            {props.children}
        </RoomSolicitorReviewContext.Provider>
    );
};

export default RoomSolicitorReviewContextProvider;
