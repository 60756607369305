import Axios from "axios";
import {constants} from "../../util/Constants";
import {defaultAxiosErrorHandler} from "../util/RequestUtils";

export const loginRequest = async (username, password, onSuccess) => {
    return Axios.post(`${constants.apiBaseUrl}/auth/authenticate`, {
        username: username,
        password: password
    })
        .then((response) => response.data)
        .catch(defaultAxiosErrorHandler);
};

export const impersonateRequest = (id) => Axios({
    method: 'get',
    url: `${constants.apiBaseUrl}/auth/impersonate/${id}`,
}).then((response) => response.data).catch(defaultAxiosErrorHandler);