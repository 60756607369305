import React from "react";
import styled from "styled-components";
import LargeGreyImage from '../../../images/cookingSchedule/cell_large_grey.png';
import LargeBlueImage from '../../../images/cookingSchedule/cell_large_blue.png';
import dayjs from "dayjs";
import {Row} from "../../../styles/Layout";
import {useHistory} from "react-router-dom";
import {routes} from "../../../../shared/routing/Routes";
import {AVAILABILITY_TYPE} from "../../../../shared/util/enum/AvailabilityType";

const Base = styled.div`
    padding: 3px;
    width: 62px;
`;

const Large = styled(Base)`
    height: 74px;
`;

const LargeGrey = styled(Large)`
    background-image: url(${LargeGreyImage});
`;

const LargeBlue = styled(Large)`
    background-image: url(${LargeBlueImage});
    color: white;
`;

const SmallRow = styled(Row)`
    font-size: 9.6px;
    font-family: Verdana;
    display: flex;
    justify-content: space-between;
`;

const PointerSpan = styled.span`
    cursor: pointer;
    ${props => props.selected && 'text-decoration: underline; font-weight: bold;'}
    
    :hover {
        text-decoration: underline;
    }
`;

const DateSliderItem = ({ day, selectedDate }) => {

    const history = useHistory();

    const isCurrentDate = dayjs(selectedDate).isSame(dayjs(day.date), 'date');

    const handleDateClick = () => {
        history.push(`${routes.roomSoliciting}/${dayjs(day.date).format('YYYY-MM-DD')}`);
    }

    const getContent = () => {
        return (
            <>
                <SmallRow style={{ marginBottom: 5 }}>
                    <PointerSpan selected={isCurrentDate} onClick={handleDateClick}>
                        {dayjs(day.date).format('D MMM')}
                    </PointerSpan>
                </SmallRow>
                <SmallRow style={{ marginBottom: 3 }}>Huis: {day.availabilities.filter(x => x.availabilityType === AVAILABILITY_TYPE.FULLY_AVAILABLE).length}</SmallRow>
                <SmallRow>Zoekers: {day.appointments.length}</SmallRow>
            </>
        );
    };

    if (!dayjs(day.date).isBefore(dayjs().subtract(1, 'day'))) {
        return (
            <LargeBlue>{getContent()}</LargeBlue>
        );
    }

    return (
        <LargeGrey>{getContent()}</LargeGrey>
    );
};

export default DateSliderItem;
