import React from "react";
import {PanelBody, PanelBottom, PanelContainer, PanelTop} from "../../styles/Panel";
import {Title} from "../../styles/Layout";
import Amount from "../../styles/Amount";


const shouldNotUpdate = (prevProps, nextProps) => {
    return prevProps.balance === nextProps.balance;
}

const formatBalance = (balance = 0) => {
    return (Math.round(balance * 100) / 100).toFixed(2);
};

const MyBalancePanel = ({ balance }) => {
    return (
        <PanelContainer>
            <PanelTop>
                <Title>Tegoed</Title>
            </PanelTop>
            <PanelBody>
                <Amount>{formatBalance(balance)}</Amount>
            </PanelBody>
            <PanelBottom />
        </PanelContainer>
    );

}

export default React.memo(MyBalancePanel, shouldNotUpdate);