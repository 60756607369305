import {ListDate, ListItem} from "../../styles/List";
import {Col} from "../../styles/Grid";
import React from "react";
import styled from "styled-components";
import {fontSizes} from "../../util/Fontsizes";
import {colors} from "../../../util/Colors";
import {capitalizeFirstChar} from "../../../util/Helpers";

const ItemInfoWrapper = styled(Col)`
    justify-content: space-between;
    flex-grow: 1;
`;

const ItemTitle = styled.div`
    font-size: ${fontSizes.semiBig}px;
    font-weight: bold;
`;

const ItemDescription = styled.div`
    font-size: ${fontSizes.base}px;
    max-width: 60vw;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

const ItemOwner = styled.div`
    font-size: ${fontSizes.base}px;
    color: ${colors.textLightGrey};
`;

const ItemAmountForMe = styled(Col)`
    justify-content: center;
    align-items: center;
`;

const BeerListItem = ({ date = '', type = '', note = '', createdBy = '', amountForMe = 0 }) => {
    return (
        <ListItem>
            <ListDate date={date} />
            <ItemInfoWrapper>
                <Col>
                    <ItemTitle>{capitalizeFirstChar(type)}</ItemTitle>
                    <ItemDescription>{note}</ItemDescription>
                </Col>
                <ItemOwner>{createdBy}</ItemOwner>
            </ItemInfoWrapper>
            <ItemAmountForMe>
                {amountForMe}
            </ItemAmountForMe>
        </ListItem>
    );
};

export default React.memo(BeerListItem);