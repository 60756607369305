import React, {useContext, useEffect, useState} from "react";
import PageWrapper from "../../../PageWrapper";
import RoomSolicitingNavigation from "../../RoomSolicitingNavigation";
import {MeContext} from "../../../../../shared/context/MeContext";
import {UsersContext} from "../../../../../shared/context/UsersContext";
import {RoomSolicitorContext} from "../../../../../shared/context/roomsoliciting/RoomSolicitorContext";
import {Td, Tr} from "../../../../styles/Page";
import DefaultTable from "../../../common/DefaultTable";
import styled from "styled-components";
import RoomSolicitorCreateModal from "./RoomSolicitorCreateModal";
import {Link} from "react-router-dom";
import {routes} from "../../../../../shared/routing/Routes";

const Container = styled.div`
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
`;

const headers = [
    {
        name: 'Naam',
    },
    {
        name: 'Leeftijd',
    },
    {
        name: 'Jaar',
    },
    {
        name: 'Studie',
    },
    {
        name: 'Score',
    },
];

const RoomSolicitorsPage = () => {

    const [ isOpen, setIsOpen ] = useState(false);

    const { loadMe } = useContext(MeContext);
    const { loadUsers } = useContext(UsersContext);
    const { solicitorsPage, getSolicitors } = useContext(RoomSolicitorContext);

    useEffect(() => {
        loadMe();
        loadUsers();
        getSolicitors();
    }, []);

    const produceRow = (x) => {
        return (
            <Tr key={x.id}>
                <Td><Link to={`${routes.roomSolicitor}/${x.id}`}>{x.name}</Link></Td>
                <Td>{x.age}</Td>
                <Td>{x.studentYear}</Td>
                <Td>{x.study}</Td>
                <Td>{x.averageScore === 'NaN' ? '-' : x.averageScore}</Td>
            </Tr>
        );
    };

    const handleNewClick = () => {
        setIsOpen(true);
    };

    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom && !solicitorsPage.last) {
            getSolicitors(solicitorsPage.number + 1);
        }
    };

    return (
        <PageWrapper>
            <RoomSolicitingNavigation />
            <Container>
                <DefaultTable
                    headers={headers}
                    convertRow={produceRow}
                    content={solicitorsPage?.content || []}
                    handleScroll={handleScroll}
                />
                <button type={'button'} style={{ marginTop: 5 }} onClick={handleNewClick}>Nieuw</button>
            </Container>

            <RoomSolicitorCreateModal title={'Nieuwe Kamerzoeker'} isOpen={isOpen} setIsOpen={setIsOpen} />
        </PageWrapper>
    );
};

export default RoomSolicitorsPage;
