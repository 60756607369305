import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {MeContext} from "../../shared/context/MeContext";
import {TransactionBalanceContext} from "../../shared/context/TransactionBalanceContext";
import {CookingBalanceContext} from "../../shared/context/CookingBalanceContext";
import ActionBarBar from "./ActionBarBar";
import {mobileConstants} from "../util/Constants";
import NavigationDrawer from "./NavigationDrawer";

const DarkOverlay = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    
    background-color: rgba(51, 51, 51, 0.75);
    
    z-index: 99;
`;

const Body = styled.div`
    overflow-y: auto;
    height: calc(100vh - ${mobileConstants.actionBarHeight}px);
`;

const getMyTransactionBalance = (overview = [], me) => {
    const myBalanceOverview = overview.find((x) => x.user.id === me?.id);
    return myBalanceOverview ? myBalanceOverview.balance : 0;
};

const getMyCookingPointsBalance = (overview = [], me) => {
    const myOverview = overview.find((x) => x.user.id === me?.id);
    return myOverview ? myOverview.balance : 0;
};

const ActionBar = ({ activeRoute = '', balanceTextOverride, children }) => {

    const [open, setOpen] = useState(false);

    const drawerRef = useRef(null);

    const { me } = useContext(MeContext);
    const { transactionBalanceOverview } = useContext(TransactionBalanceContext);
    const { cookingBalanceOverview } = useContext(CookingBalanceContext);

    /* Close the drawer when the user clicks outside of it */
    useEffect(() => {
        const closeDrawer = (event) => {
            if (drawerRef.current && drawerRef.current.contains(event.target)) {
                return;
            }

            setOpen(false);
        };

        document.addEventListener("mousedown", closeDrawer);
        return () => document.removeEventListener("mousedown", closeDrawer);
    }, []);

    const onMenuClick = useCallback(() => setOpen(!open), [open]);

    const close = useCallback(() => setOpen(false), []);

    return (
        <>
            <ActionBarBar
                transactionBalance={getMyTransactionBalance(transactionBalanceOverview, me)}
                cookingBalance={getMyCookingPointsBalance(cookingBalanceOverview, me)}
                balanceText={balanceTextOverride}
                onMenuClick={onMenuClick}
            />

            <Body>
                {children}
            </Body>

            {open && (
                <DarkOverlay />
            )}

            <NavigationDrawer isOpen={open} close={close} drawerRef={drawerRef} />
        </>
    );
};

export default ActionBar;