import React from "react";
import styled from "styled-components";
import {colors} from "../../../util/Colors";
import {Row} from "../../styles/Layout";
import CustomModal from "../CustomModal";
import {getDryerApplicableUsers} from "../../../shared/util/CookingEventUtils";
import useCookingDryers from "../../../shared/hooks/CookingDryersHook";

const TitleHolder = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    
    padding-bottom: 20px;
    padding-top: 20px;
    
    border-style: solid;
    border-width: 0px 0px 1px 0px;
    border-color: ${colors.borderLightGrey};
    
    height: 26px;
`;

const Title = styled.div`
    font-size: 18px;
`;

const CloseHolder = styled.div`
    position: absolute;
    right: 0;
    cursor: pointer;
    margin-right: 20px;
    font-size: 22px;
`;

const Body = styled.div`
    display: flex;
    padding: 20px;
    flex-direction: column;
`;

const Footer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    
    padding: 20px;
    
    border-style: solid;
    border-width: 1px 0px 0px 0px;
    border-color: ${colors.borderLightGrey};
`;

const CustomRow = styled(Row)`
    margin-bottom: 5px;
`;

const Half = styled.div`
    width: 50%;
`;

const Select = styled.select`
    min-width: 80px;
`;

const Spacer = styled.div`
    height: 20px;
`;

const UpdateDryersModal = ({ isOpen = false, setIsOpen = (value) => {}, event, update = () => {} }) => {

    const {
        formValues,
        dryerSuggestions,
        handleDryer1Change,
        handleDryer2Change,
        applySuggestions,
        handleSubmit
    } = useCookingDryers(isOpen, () => setIsOpen(false), event);

    const dryerApplicableUsers = getDryerApplicableUsers(event);

    const optionsDryer1 = dryerApplicableUsers.filter(x => x.id !== parseInt(formValues.dryer2));
    const optionsDryer2 = dryerApplicableUsers.filter(x => x.id !== parseInt(formValues.dryer1));

    return (
        <CustomModal isOpen={isOpen} setIsOpen={setIsOpen}>
            <TitleHolder>
                <Title><b>Afdrogers wijzigen</b></Title>
                <CloseHolder onClick={() => setIsOpen(false)}>X</CloseHolder>
            </TitleHolder>
            <Body>
                <CustomRow>
                    <Half>Suggesties</Half>
                    <Half>{dryerSuggestions?.map(x => x.username).join(', ')}</Half>
                </CustomRow>
                <CustomRow>
                    <button type={'button'} onClick={applySuggestions}>Neem suggesties over</button>
                </CustomRow>
                <Spacer />
                <CustomRow>
                    <Half>Afdroger 1</Half>
                    <Half>
                        <Select onChange={handleDryer1Change} value={formValues.dryer1}>
                            <option value={null} />
                            {optionsDryer1.map(x => (
                                <option key={x.id} value={x.id}>{x.username}</option>
                            ))}
                        </Select>
                    </Half>
                </CustomRow>
                <CustomRow>
                    <Half>Afdroger 2</Half>
                    <Half>
                        <Select onChange={handleDryer2Change} value={formValues.dryer2}>
                            <option value={null} />
                            {optionsDryer2.map(x => (
                                <option key={x.id} value={x.id}>{x.username}</option>
                            ))}
                        </Select>
                    </Half>
                </CustomRow>
            </Body>
            <Footer>
                <button type={'button'} onClick={handleSubmit}>Save</button>
            </Footer>
        </CustomModal>
    );
};

export default UpdateDryersModal;