import React from "react";
import CookingTableBodyRow from "./CookingTableBodyRow";

const CookingTableBody = ({ me, cookingEvents, currentDate, setMeAsCook, unSetMeAsCook, enroll }) => {
    return (
        <React.Fragment>
            <CookingTableBodyRow
                large={false}
                events={cookingEvents.slice(0, 7)}
                currentDate={currentDate}
                me={me}
                setMeAsCook={setMeAsCook}
                unSetMeAsCook={unSetMeAsCook}
                enroll={enroll}
            />
            <CookingTableBodyRow
                large={false}
                events={cookingEvents.slice(7, 14)}
                currentDate={currentDate}
                me={me}
                setMeAsCook={setMeAsCook}
                unSetMeAsCook={unSetMeAsCook}
                enroll={enroll}
            />
            <CookingTableBodyRow
                large={true}
                events={cookingEvents.slice(14, 21)}
                currentDate={currentDate}
                me={me}
                setMeAsCook={setMeAsCook}
                unSetMeAsCook={unSetMeAsCook}
                enroll={enroll}
            />
            <CookingTableBodyRow
                large={false}
                events={cookingEvents.slice(21, 28)}
                currentDate={currentDate}
                me={me}
                setMeAsCook={setMeAsCook}
                unSetMeAsCook={unSetMeAsCook}
                enroll={enroll}
            />
            <CookingTableBodyRow
                large={false}
                events={cookingEvents.slice(28, 35)}
                currentDate={currentDate}
                me={me}
                setMeAsCook={setMeAsCook}
                unSetMeAsCook={unSetMeAsCook}
                enroll={enroll}
            />
        </React.Fragment>
    );
};

export default CookingTableBody;
