import React, {useEffect, useState} from "react";
import CustomModal from "../CustomModal";
import {func} from "../../../util/Helpers";
import {Body, CloseHolder, CustomRow, Footer, Half, Title, TitleHolder} from "../../styles/FileModal";


const UploadPDFModal = ({ title = "Bestand uploaden", isOpen = false, setIsOpen = func, upload = func, accept = "" }) => {
    const [ file, setFile ] = useState(null);
    const [ amount, setAmount ] = useState(1);

    // Reset after close
    useEffect(() => {
        if (!isOpen) {
            setFile(null);
            setAmount(1);
        }
    }, [isOpen]);

    const closeModal = () => {
        setIsOpen(false);
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleAmountChange = (event) => {
        setAmount(event.target.value);
    }

    const handleSubmit = () => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('amount', amount);
        upload(formData, closeModal);
    };

    return (
        <CustomModal isOpen={isOpen} setIsOpen={setIsOpen}>
            <TitleHolder>
                <Title><b>{title}</b></Title>
                <CloseHolder onClick={() => setIsOpen(false)}>X</CloseHolder>
            </TitleHolder>
            <Body>
                <CustomRow>
                    <Half>Upload</Half>
                    <Half><input type={'file'} onChange={handleFileChange} accept={accept} /></Half>
                </CustomRow>
                <CustomRow>
                    <Half>Hoeveelheid</Half>
                    <Half><input type={'number'} min={1} max={50} onChange={handleAmountChange} value={amount} /></Half>
                </CustomRow>
            </Body>
            <Footer>
                <button type={'button'} onClick={handleSubmit}>Save</button>
            </Footer>
        </CustomModal>
    );
};

export default UploadPDFModal;
