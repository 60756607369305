import React from "react";
import styled from 'styled-components';
import loginImage from '../../images/login.png';
import {PanelBody, PanelBottom, PanelTop} from "../../styles/Panel";
import {Title} from "../../styles/Layout";
import useLoginForm from "../../../shared/hooks/LoginFormHook";

const FormHolder = styled.div`
    margin: 10px 5px 0px 10px;
`;

const Input = styled.input`
    margin-bottom: 10px;
    width: 60%;
    border: 1px black solid;
`;

const LoginImage = styled.img`
    cursor: pointer;
    float: right;
`;

const LoginPanel = () => {
    const { setUsername, setPassword, handleSubmit } = useLoginForm();

    return (
        <React.Fragment>
            <PanelTop>
                <Title>Inloggen</Title>
            </PanelTop>
            <PanelBody>
                <FormHolder>
                    <Input
                        id={'username'}
                        type={'text'}
                        name={'username'}
                        placeholder={'Username'}
                        onKeyPress={event => {
                            if (event.key === 'Enter') {
                                handleSubmit()
                            }
                        }}
                        onChange={event => setUsername(event.target.value)}
                    />
                    <Input
                        id={'password'}
                        type={'password'}
                        name={'password'}
                        placeholder={'Password'}
                        onKeyPress={event => {
                            if (event.key === 'Enter') {
                                handleSubmit()
                            }
                        }}
                        onChange={event => setPassword(event.target.value)}
                    />
                    <LoginImage
                        src={loginImage}
                        alt={'Login'}
                        onClick={handleSubmit}
                        onKeyPress={event => {
                            if (event.key === 'Enter') {
                                handleSubmit()
                            }
                        }}
                        tabIndex={0}
                    />
                </FormHolder>
            </PanelBody>
            <PanelBottom />
        </React.Fragment>
    );
};

export default LoginPanel;
