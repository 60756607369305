import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import {getImageRequest} from "../../../../shared/network/ImageRequests";
import BeerInputModal from "./BeerInputModal";
import {getTypeText} from "../../../../shared/util/BeerTypeUtil";

const getAmountForUser = (beerInput, user) => {
    const enrollment = beerInput.enrollments.find(x => x.user.id === user.id);
    return enrollment ? enrollment.amount : 0
}

const ViewBeerInputModal = ({
    isOpen = false,
    setIsOpen = (value) => {},
    setIsEdit = () => {},
    users= [], beerInput,
    canUpload = false,
    setShowUploadModal = () => {},
}) => {
    const [ image, setImage ] = useState(null);

    useEffect(() => {
        if (beerInput?.image != null) {
            getImageRequest(beerInput.image).then(x => {
                const fileReaderInstance = new FileReader();
                fileReaderInstance.readAsDataURL(x);
                fileReaderInstance.onload = () => {
                    const imageBase64 = fileReaderInstance.result;
                    setImage(imageBase64);
                };
            });
        }
    }, [beerInput?.image])

    const handleUploadClick = () => {
        setIsOpen(false);
        setShowUploadModal(true);
    }

    const edit = () => {
        setIsOpen(false);
        setIsEdit(true);
    }

    return beerInput ? (
        <BeerInputModal
            title={dayjs(beerInput.date).format('DD/MM') + ' ' + getTypeText(beerInput.type)}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isEdit={false}
            setIsEdit={edit}
            inputTypes={getTypeText(beerInput.type)}
            beerTypes={beerInput.beerType.name}
            description={'Aantal'}
            users={users}
            userEntry={(user) => getAmountForUser(beerInput, user)}
            note={beerInput.note}
            image={beerInput.image && image}
            canUpload={canUpload}
            handleSubmit={handleUploadClick}
        />
    ) : <></>;
};

export default ViewBeerInputModal;
